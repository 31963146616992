import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { motion } from 'framer-motion';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SpeedIcon from '@mui/icons-material/Speed';
import SecurityIcon from '@mui/icons-material/Security';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CartiProduct: React.FC = () => {
    const navigate = useNavigate();

    const features = [
        {
            icon: <AutoAwesomeIcon fontSize="large" />,
            title: 'Slatt Translation',
            description: 'Turn your boring text into authentic vamp speak that Carti himself would use. We captured his whole vibe to make your words sound like they came straight from King Vamp 🧛🏿‍♂️'
        },
        {
            icon: <SpeedIcon fontSize="large" />,
            title: 'Lightning Fast',
            description: 'Get your vamp text in seconds. No cap! Just instant Carti vibes whenever you need to talk your talk. It\'s lit every time 🦋'
        },
        {
            icon: <SecurityIcon fontSize="large" />,
            title: 'Free for the Homies',
            description: 'Start translating right now for free. Real vamps can level up anytime for unlimited access when you ready to go all the way *+!®'
        }
    ];

    const examples = [
        {
            original: "I'm at the top of the buildin",
            translated: `hey . I'm- < 3 at! 🖤 the! top 🦋 ^ 🦋* !+  of++ the 🧛🏿‍♂️ ** !++buildin 🧛🏿‍♂️ ^ 🦋* !+ !!!!`
        },
        {
            original: `I'd rather get caught with it than without it`,
            translated: `🖤🖤🖤 ^ 🦋*  !+ I\'d 🧛🏿‍♂️ rather^ :) get :) caught 💔 with it! 🦋 ++ than 😦 without 💕 ^ 🦋*  !+  it - kING vAMP`
        },
        {
            original: "she singin' my songs, she wanna die lit",
            translated: `she 🧛🏿‍♂️ & * singin' 😦 :) my! & * songs, *^ ! she* & * wanna <3 ^ 🦋*  !+  die. *+ _ slatt lit. 🧛🏿‍♂️`
        }
    ];

    return (
        <Container maxWidth="md">
            {/* Hero Section */}
            <Box component="section" sx={{ py: { xs: 4, md: 8 }, textAlign: 'center' }}>
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                >
                    <Typography
                        variant="h1"
                        gutterBottom
                        sx={{
                            fontWeight: 'bold',
                            mb: 4,
                            fontSize: { xs: '2.5rem', md: '3.75rem' }
                        }}
                    >
                        Playboi Carti Translator
                    </Typography>
                    <Typography
                        variant="h2"
                        color="text.secondary"
                        sx={{
                            mb: 6,
                            px: { xs: 2, md: 8 },
                            fontSize: { xs: '1.2rem', md: '1.5rem' }
                        }}
                    >
                        Turn your basic text into real vamp talk 🧛🏿‍♂️ Type normal, get Carti vibes. It's that simple, slatt! *+!®
                    </Typography>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={() => {
                            // Set redirect flag in localStorage as fallback for OAuth flows
                            localStorage.setItem('redirect_after_login', '/cartinese');
                            navigate('/login', { state: { returnTo: '/cartinese' } });
                        }}
                        sx={{
                            py: 2,
                            px: 6,
                            fontSize: { xs: '1rem', md: '1.2rem' },
                            borderRadius: '30px',
                            textTransform: 'none',
                            boxShadow: '0 4px 14px 0 rgba(0,0,0,0.25)',
                            '&:hover': {
                                transform: 'translateY(-2px)',
                                boxShadow: '0 6px 20px 0 rgba(0,0,0,0.3)',
                            },
                            transition: 'all 0.2s ease-in-out'
                        }}
                    >
                        Start Vampin' Now
                    </Button>
                </motion.div>
            </Box>

            {/* Features Section */}
            <Box component="section" sx={{ py: { xs: 4, md: 8 } }}>
                <Typography
                    variant="h3"
                    align="center"
                    sx={{
                        mb: { xs: 4, md: 6 },
                        fontSize: { xs: '2rem', md: '2.5rem' },
                        fontWeight: 'bold'
                    }}
                >
                    Why This Translator Is So Lit 🦋
                </Typography>
                <Grid container spacing={4}>
                    {features.map((feature, index) => (
                        <Grid item xs={12} md={4} key={index}>
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.6, delay: index * 0.2 }}
                                style={{ height: '100%' }}
                            >
                                <Card sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    boxShadow: 3
                                }}>
                                    <CardContent sx={{
                                        p: 4,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: { xs: 'auto', md: '340px' }
                                    }}>
                                        <Box sx={{
                                            mb: 3,
                                            color: 'primary.main',
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}>
                                            {feature.icon}
                                        </Box>
                                        <Typography
                                            variant="h6"
                                            component="h3"
                                            sx={{
                                                mb: 2,
                                                fontWeight: 500,
                                                height: '48px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            {feature.title}
                                        </Typography>
                                        <Typography
                                            color="text.secondary"
                                            sx={{
                                                flex: 1,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {feature.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </motion.div>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* Social Proof Section */}
            <Box component="section" sx={{ py: { xs: 4, md: 8 }, textAlign: 'center' }}>
                <Typography
                    variant="h3"
                    sx={{
                        mb: 4,
                        fontSize: { xs: '2rem', md: '2.5rem' },
                        fontWeight: 'bold'
                    }}
                >
                    Join the Vamp Nation 🧛🏿‍♂️
                </Typography>
                <Typography variant="h6" color="text.secondary" sx={{ mb: 4 }}>
                    All the real ones already talkin' Cartinese. Don't be the last one to the party, slatt! *+
                </Typography>
            </Box>

            {/* Examples Section */}
            <Box component="section" sx={{ py: { xs: 4, md: 8 } }}>
                <Typography
                    variant="h3"
                    align="center"
                    sx={{
                        mb: { xs: 4, md: 6 },
                        fontSize: { xs: '2rem', md: '2.5rem' },
                        fontWeight: 'bold'
                    }}
                >
                    Peep How It Transforms Your Text 🦋
                </Typography>
                {examples.map((example, index) => (
                    <motion.div
                        key={index}
                        initial={{ opacity: 0, x: index % 2 === 0 ? -20 : 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.6, delay: index * 0.3 }}
                    >
                        <Card sx={{
                            mb: 4,
                            boxShadow: 3,
                            '&:hover': {
                                boxShadow: 6,
                                transform: 'translateY(-4px)',
                            },
                            transition: 'all 0.2s ease-in-out'
                        }}>
                            <CardContent sx={{ p: { xs: 2, md: 4 } }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            color="text.secondary"
                                            sx={{ fontWeight: 500 }}
                                        >
                                            Basic Text
                                        </Typography>
                                        <Typography variant="body1">
                                            {example.original}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            color="primary"
                                            sx={{ fontWeight: 500 }}
                                        >
                                            Vamp Mode 🧛🏿‍♂️
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                fontStyle: 'italic',
                                                fontFamily: 'monospace'
                                            }}
                                        >
                                            {example.translated}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </motion.div>
                ))}
            </Box>

            {/* FAQ Section for SEO */}
            <Box component="section" sx={{ py: { xs: 4, md: 8 } }}>
                <Typography
                    variant="h3"
                    align="center"
                    sx={{
                        mb: { xs: 4, md: 6 },
                        fontSize: { xs: '2rem', md: '2.5rem' },
                        fontWeight: 'bold'
                    }}
                >
                    Vamp FAQ 🦋
                </Typography>
                <Typography variant="body1" paragraph>
                    Everything you need to know about turning your boring text into Carti-style vamp talk. Our translator helps you sound just like King Vamp himself! 🧛🏿‍♂️
                </Typography>

                {[
                    {
                        question: 'What is a Cartinese translator?',
                        answer: 'It\'s a vibe machine that takes your regular text and makes it sound like Carti typed it himself. We add all the right emojis 🧛🏿‍♂️🦋💕, special characters, and Carti\'s unique style to make your messages look authentic and slatt!'
                    },
                    {
                        question: 'How does the Playboi Carti text generator work?',
                        answer: 'Our translator studies how Carti types and applies his whole aesthetic to your text. We add vampire emojis 🧛🏿‍♂️, butterflies 🦋, hearts 💕, and his signature punctuation to make your text look like it came straight from King Vamp himself.'
                    },
                    {
                        question: 'What\'s the difference between Cartinese and regular text?',
                        answer: 'Regular text is boring! Cartinese adds all the vamp vibes with special characters, emojis, and Carti\'s unique style. It\'s got the random capitalization, extra punctuation (!++), and all those iconic symbols that make Carti\'s style so recognizable. It\'s not just text, it\'s a whole aesthetic *+!®'
                    },
                    {
                        question: 'Is this the best Carti language translator?',
                        answer: 'No cap, this is the most authentic Carti translator out there! We\'ve studied how King Vamp types and created something that perfectly captures his unique style. Other translators just don\'t get the vibe right like we do 🧛🏿‍♂️'
                    },
                    {
                        question: 'How can I generate Carti text?',
                        answer: 'It\'s super easy! Just type whatever you want to say in the box, hit "Translate" and watch your boring text transform into pure vamp speak. You can adjust the settings to make it more or less intense depending on how you\'re feeling. Then copy and paste it anywhere to show everyone you\'re a real vamp! 🦋'
                    }
                ].map((faq, index) => (
                    <Accordion
                        key={index}
                        sx={{
                            mb: 1,
                            backgroundColor: 'background.paper',
                            boxShadow: 'none',
                            '&:before': {
                                display: 'none',
                            },
                            '&.Mui-expanded': {
                                margin: '0 0 8px 0',
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{
                                '&.Mui-expanded': {
                                    minHeight: '48px',
                                }
                            }}
                        >
                            <Typography
                                component="h3"
                                variant="h6"
                                sx={{
                                    fontSize: '1.1rem',
                                    fontWeight: 'bold',
                                    color: 'text.primary'
                                }}
                            >
                                {faq.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: 'text.secondary',
                                    lineHeight: 1.6
                                }}
                            >
                                {faq.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>

            {/* CTA Section */}
            <Box component="section" sx={{ py: { xs: 4, md: 8 }, textAlign: 'center' }}>
                <Typography
                    variant="h3"
                    sx={{
                        mb: 4,
                        fontSize: { xs: '2rem', md: '2.5rem' },
                        fontWeight: 'bold'
                    }}
                >
                    Ready to Talk Like King Vamp? 🧛🏿‍♂️
                </Typography>
                <Button
                    variant="contained"
                    size="large"
                    onClick={() => {
                        // Set redirect flag in localStorage as fallback for OAuth flows
                        localStorage.setItem('redirect_after_login', '/cartinese');
                        navigate('/login', { state: { returnTo: '/cartinese' } });
                    }}
                    sx={{
                        py: 2,
                        px: 6,
                        fontSize: { xs: '1rem', md: '1.2rem' },
                        borderRadius: '30px',
                        textTransform: 'none',
                        boxShadow: '0 4px 14px 0 rgba(0,0,0,0.25)',
                        '&:hover': {
                            transform: 'translateY(-2px)',
                            boxShadow: '0 6px 20px 0 rgba(0,0,0,0.3)',
                        },
                        transition: 'all 0.2s ease-in-out'
                    }}
                >
                    Get Slatt! *+!®
                </Button>
            </Box>
        </Container>
    );
};

export default CartiProduct;