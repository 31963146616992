import AddBoxIcon from '@mui/icons-material/AddBox';
import CloudUpload from '@mui/icons-material/CloudUpload';
import { default as Delete, default as DeleteIcon } from '@mui/icons-material/Delete';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import 'chart.js/auto';
import { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import Client_ from '../../api/Client';
import { useNotification } from '../../stores/actions/StoreActions';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Checkbox from '@mui/material/Checkbox';
import styled from '@emotion/styled';
import { Theme as EmotionTheme } from '@emotion/react';
import { Theme as MuiTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputLabel from '@mui/material/InputLabel';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import FormLabel from '@mui/material/FormLabel';
import ListItemText from '@mui/material/ListItemText';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PreviewIcon from '@mui/icons-material/Preview';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import ShareIcon from '@mui/icons-material/Share';
import TableChartIcon from '@mui/icons-material/TableChart';
import EditIcon from '@mui/icons-material/Edit';

declare module '@emotion/react' {
    export interface Theme extends MuiTheme { }
}

const UserAvatar = styled(Avatar)<{ selected: boolean }>(({ theme, selected }) => ({
    cursor: 'pointer',
    backgroundColor: selected ? theme.palette.primary.main : theme.palette.grey[300],
    color: selected ? theme.palette.primary.contrastText : theme.palette.text.primary,
    '&:hover': {
        backgroundColor: selected ? theme.palette.primary.dark : theme.palette.grey[400],
    },
}));

const sampleData = {
    "items": [{ "id": 0, "item": "Pint Jerryburst", "price": 7.95 }, { "id": 1, "item": "Pint Jerryburst", "price": 7.95 }, { "id": 2, "item": "Loaded Nachos", "price": 20.95 }, { "id": 3, "item": "Pint Jerryburst", "price": 7.95 }, { "id": 4, "item": "Pint Jerryburst", "price": 7.95 }, { "id": 5, "item": "Pint Jerryburst", "price": 7.95 }, { "id": 6, "item": "Subtotal", "price": 60.7 }, { "id": 7, "item": "Tax", "price": 5.31 }, { "id": 9, "item": "15% Tip TYPE IN TIP", "price": 9 }, { "id": 10, "item": "PAYMENT AMOUNT", "price": 75.01 }],
    "users": {
        "0": { "saarim": 1 },
        "1": { "saarim": 1 },
        "2": { "saarim": 1, "victor": 1, "riley": 1 },
        "3": { "riley": 1 },
        "4": { "riley": 1 },
        "5": { "riley": 1 }
    },
    "ids": { "6": "Subtotal", "7": "Tax", "9": "Tip", "10": "Total" },
    "people": ['saarim', 'victor', 'riley'],
    "venmo_username": "saarimr"
}

const AVATAR_COLORS = [
    '#00b4d8', // bright blue
    '#ff595e', // coral red
    '#8ac926', // lime green
    '#9b5de5', // purple
    '#f15bb5', // pink
    '#fee440', // yellow
    '#00f5d4', // turquoise
    '#ff9e00', // orange
    '#4cc9f0', // light blue
    '#80ed99', // mint green
];

const getUserColor = (username: string, allUsers: string[]) => {
    // Find the index of this username in the full users array
    const userIndex = allUsers.indexOf(username);
    // Use modulo to wrap around if we have more users than colors
    return AVATAR_COLORS[userIndex % AVATAR_COLORS.length];
};

interface UserWeight {
    [user: string]: number;
}

interface UserWeights {
    [itemId: number]: UserWeight;
}

interface ItemTypes {
    [itemId: number]: string;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        padding: theme.spacing(2),
        minWidth: '300px',
        maxWidth: '500px',
        width: '100%',
        margin: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
        boxShadow: theme.shadows[24],
        border: `1px solid ${theme.palette.divider}`,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1.5),
            margin: theme.spacing(1),
            maxHeight: '90vh',
            overflowY: 'auto',
        }
    },
    '& .MuiDialogContent-root': {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
            paddingTop: `${theme.spacing(1)} !important`,
        }
    }
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 0',
    color: theme.palette.primary.main,
    fontWeight: 600
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    width: '100%',
    marginBottom: theme.spacing(2),
    '& .MuiInputLabel-root': {
        color: theme.palette.text.secondary
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.divider
        },
        '&:hover fieldset': {
            borderColor: theme.palette.primary.main
        }
    }
}));

const WeightContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginTop: '4px',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        justifyContent: 'center',
        backgroundColor: theme.palette.action.hover,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
    }
}));

const UserWeightRow = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 0',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    '&:last-child': {
        borderBottom: 'none'
    },
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: theme.spacing(1),
        padding: theme.spacing(1.5, 0),
        '& .MuiTypography-root': {
            fontSize: '0.9rem',
        }
    }
}));

// First, let's add proper interfaces for our item
interface EditingItem {
    id: number;
    item: string;
    price: number | string;
    purchased_by: string[];
    itemType?: string;
}

interface BillItem {
    id: number;
    item: string;
    price: number;
}

interface BillSplitResult {
    costs: { [key: string]: number };
    user_totals: { [key: string]: number };
    proportions: { [key: string]: number };
    items: any[];
    subtotal: number;
    total: number;
    tax: number;
    tip: number;
    users: any;
    ids: any;
    all_users: string[];
    conversion_ratio?: number;
    warnings?: string[];
    summary: string;
    csv: {
        string: string;
        filename: string;
    };
    receipt_id?: string;
    venmo_username?: string;
}

const BillSplitter: React.FC = () => {
    const [file, setFile] = useState<any>(null);
    const [items, setItems] = useState<any>([]);
    const [activeStep, setActiveStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState<UserWeights>({});
    const [selectedItemTypes, setSelectedItemTypes] = useState<ItemTypes>({});
    const [imageSrc, setImageSrc] = useState<any>(null);
    const { addNotificationError, addNotificationSuccess } = useNotification();
    const [inputValue, setInputValue] = useState('');
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const [users, setUsers] = useState<string[]>([]);
    const [billSplit, setBillSplit] = useState<BillSplitResult | null>(null);
    const [selectedItemId, setSelectedItemId] = useState<any>(null);
    const [numPeriods, setNumPeriods] = useState(1);
    const [selectedUser, setSelectedUser] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingItem, setEditingItem] = useState<EditingItem | null>(null);
    const billSummaryRef = useRef<HTMLDivElement>(null);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [customTotal, setCustomTotal] = useState<string>('');
    const [copySuccess, setCopySuccess] = useState(false);
    const [isClearDialogOpen, setIsClearDialogOpen] = useState(false);
    const [newUserInput, setNewUserInput] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [receiptId, setReceiptId] = useState<string | null>(null);
    const [shareSuccess, setShareSuccess] = useState(false);
    const [showDetailedTable, setShowDetailedTable] = useState(false);
    const [venmoUsername, setVenmoUsername] = useState<string>('');
    const detailedTableRef = useRef<HTMLDivElement>(null);
    const detailedBreakdownRef = useRef<HTMLDivElement>(null);

    // Router hooks
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams<{ id?: string }>();

    // Load receipt from URL parameter on mount
    useEffect(() => {
        const loadReceiptFromUrl = async () => {
            const id = params.id;
            if (id) {
                setIsLoading(true);
                try {
                    const { err, res } = await Client_.client_().getBillSplit(id);
                    if (err || !res?.success) {
                        addNotificationError(err || 'Failed to load receipt');
                    } else {
                        // Set all the state from the loaded receipt
                        setReceiptId(id);
                        setBillSplit(res.result);
                        setItems(res.result.items);
                        setSelectedUsers(res.result.users);
                        setSelectedItemTypes(res.result.ids);
                        setUsers(res.result.all_users);

                        // Set venmo username if it exists
                        if (res.result.venmo_username) {
                            setVenmoUsername(res.result.venmo_username);
                        }

                        // Set custom total if it was used
                        if (res.result.conversion_ratio && res.result.conversion_ratio !== 1) {
                            const calculatedTotal = res.result.total / res.result.conversion_ratio;
                            setCustomTotal(res.result.total.toString());
                        }

                        // Skip to the results
                        setActiveStep(4);
                    }
                } catch (error) {
                    console.error('Error loading receipt:', error);
                    addNotificationError('Failed to load receipt');
                } finally {
                    setIsLoading(false);
                }
            }
        };

        loadReceiptFromUrl();
    }, [params.id]);

    // Share receipt link
    const handleShareReceipt = async () => {
        if (!receiptId) return;

        const shareUrl = `${window.location.origin}/split/${receiptId}`;

        try {
            await navigator.clipboard.writeText(shareUrl);
            setShareSuccess(true);
            addNotificationSuccess('Share link copied to clipboard!');

            // Reset success state after 2 seconds
            setTimeout(() => {
                setShareSuccess(false);
            }, 2000);
        } catch (err) {
            addNotificationError('Failed to copy share link');
        }
    };

    const steps = [
        {
            label: 'Add People',
            description: 'Enter the names of everyone splitting the bill',
            isComplete: () => users.length > 0
        },
        {
            label: 'Upload Receipt (Optional)',
            description: 'Upload receipt for auto-extraction or skip to add items manually',
            isComplete: () => true // Always complete since it's optional
        },
        {
            label: 'Add Special Items',
            description: 'Add and identify tax, tip, subtotal, and total',
            isComplete: () => items.length > 0 // Only require that there are items
        },
        {
            label: 'Add Regular Items',
            description: 'Add the remaining items and assign them to people',
            isComplete: () => items.length > 0 // Only require that there are items
        }
    ];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        handleClear();
    };

    const itemTypes = ['Tax', 'Tip', 'Subtotal', 'Total', 'Item']

    const renderImageModal = () => (
        <Dialog
            open={isImageModalOpen}
            onClose={() => setIsImageModalOpen(false)}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>Uploaded Receipt</DialogTitle>
            <DialogContent>
                <img
                    src={imageSrc}
                    alt="uploaded receipt"
                    style={{
                        width: '100%',
                        height: 'auto',
                        objectFit: 'contain'
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsImageModalOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    );

    useEffect(() => {
        document.title = 'Split Bills';
    }, []);

    const useSampleData = () => {
        handleClear();
        setItems(sampleData.items)
        setSelectedUsers(sampleData.users)
        setSelectedItemTypes(sampleData.ids)
        setUsers(sampleData.people)
        setVenmoUsername(sampleData.venmo_username);
    }

    const handleAddUser = () => {
        const trimmedUser = newUserInput.trim();
        if (trimmedUser !== '' && !users.includes(trimmedUser)) {
            setUsers([...users, trimmedUser]);
            setNewUserInput(''); // Clear input after adding
        } else if (users.includes(trimmedUser)) {
            addNotificationError('This person has already been added');
            setNewUserInput(''); // Clear input if duplicate
        }
    };

    const handleDeleteUser = (userToDelete: string) => {
        // Also need to clean up billSplit if it exists
        if (billSplit) {
            setBillSplit(null);  // Reset bill split when users change
        }
        // Remove user from users array
        const updatedUsers = users.filter((user) => user !== userToDelete);
        setUsers(updatedUsers);

        // Remove user from all items' selected users
        const updatedSelectedUsers = { ...selectedUsers };
        Object.keys(updatedSelectedUsers).forEach((itemId) => {
            const newWeights: UserWeight = { ...updatedSelectedUsers[Number(itemId)] };
            delete newWeights[userToDelete];
            updatedSelectedUsers[Number(itemId)] = newWeights;
        });
        setSelectedUsers(updatedSelectedUsers);

        // If the user is in the editing modal, remove them there too
        if (editingItem && editingItem.purchased_by) {
            setEditingItem({
                ...editingItem,
                purchased_by: editingItem.purchased_by.filter((user: string) => user !== userToDelete)
            });
        }
    };

    const handleFileChange = (event: any) => {
        // Clear existing items and related state
        setItems([]);
        setBillSplit(null);
        setSelectedUsers({});
        setSelectedItemTypes({});

        // Set the new file
        setFile(event.target.files[0]);
        setImageSrc(URL.createObjectURL(event.target.files[0]));
    };

    const handleFileUpload = async () => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('img', file);
        Client_.client_().getItemsFromReceipt(formData).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                // Only clear receipt-related state
                setBillSplit(null);
                setSelectedUsers({});
                setSelectedItemTypes({});
                // Set new items from receipt
                setItems(res?.result?.items)
                setSelectedItemTypes(res?.result?.item_types)
                // Add success notification
                addNotificationSuccess(`Successfully extracted ${res?.result?.items.length} items from receipt!`);
                // Automatically advance to the next step
                handleNext();
            }
        });
    };

    // Replace the current useEffect for scrolling
    useEffect(() => {
        if (billSplit) {
            // First, set the active step to 4 (results step)
            setActiveStep(4);

            // Then wait for UI to stabilize before scrolling
            setTimeout(() => {
                if (billSummaryRef.current) {
                    // Calculate position to scroll to
                    const yOffset = -20; // Small offset to give some breathing room
                    const element = billSummaryRef.current;
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                    // Scroll to the calculated position
                    window.scrollTo({
                        top: y,
                        behavior: 'smooth'
                    });
                }
            }, 500); // Longer delay to ensure UI has stabilized
        }
    }, [billSplit]);

    const splitBill = async () => {
        setIsLoading(true);
        Client_.client_().splitBill(
            items,
            selectedUsers,
            selectedItemTypes,
            customTotal ? Number(customTotal) : null,
            venmoUsername || null
        ).then(async ({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                // Set the bill split result
                setBillSplit(res?.result);

                // Update receipt ID and URL if provided
                if (res?.result?.receipt_id) {
                    setReceiptId(res.result.receipt_id);

                    // Update URL without reloading the page
                    const newPath = `/split/${res.result.receipt_id}`;
                    if (location.pathname !== newPath) {
                        navigate(newPath, { replace: true });
                    }
                }
            }
        });
    };

    const handleClear = () => {
        setFile(null);
        setItems([]);
        setBillSplit(null);
        setSelectedUsers({});
        setSelectedItemTypes({});
        setImageSrc(null);
        setUsers([]);
        setEditingItem(null);
        setIsModalOpen(false);
        setSelectedItemId(null);
        setActiveStep(0); // Reset stepper to first step
        setVenmoUsername('');
        setCustomTotal('');

        // Also clear receipt ID and navigate back to base URL
        setReceiptId(null);
        if (location.pathname !== '/split') {
            navigate('/split', { replace: true });
        }
    };

    const handleOpenModal = (item?: any, presetType?: string) => {
        if (item) {
            setEditingItem({
                id: item.id,
                item: item.item,
                price: item.price,
                purchased_by: Object.keys(selectedUsers[item.id] || {}),
                itemType: selectedItemTypes[item.id]
            });
        } else {
            const newId = items.reduce((maxId: number, item: any) => Math.max(maxId, item.id), -1) + 1;
            setEditingItem({
                id: newId,
                item: '',
                price: '',
                purchased_by: [],
                itemType: presetType || 'Item'
            });
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setEditingItem(null);
    };

    const handleSaveItem = () => {
        if (!editingItem) {
            addNotificationError('No item is being edited');
            return;
        }

        if (isNaN(Number(editingItem.price)) || Number(editingItem.price) <= 0) {
            addNotificationError('Please enter a valid price greater than 0');
            return;
        }

        // Format price to always have 2 decimal places
        const formattedPrice = Number(Number(editingItem.price).toFixed(2));
        const updatedItem = {
            id: editingItem.id,
            item: editingItem.item,
            price: formattedPrice
        };

        // Create weights object for selected users
        const userWeights: UserWeight = {};
        editingItem.purchased_by.forEach((user: string) => {
            const currentWeight = selectedUsers[editingItem.id]?.[user] || 1;
            userWeights[user] = currentWeight;
        });

        // Reset bill split since items are changing
        setBillSplit(null);

        // Update items array
        const itemExists = items.some((item: any) => item.id === editingItem.id);
        if (itemExists) {
            setItems(items.map((item: any) =>
                item.id === editingItem.id ? updatedItem : item
            ));
        } else {
            setItems([...items, updatedItem]);
        }

        // Update selected users
        const updatedSelectedUsers: UserWeights = {
            ...selectedUsers,
            [editingItem.id]: userWeights
        };
        setSelectedUsers(updatedSelectedUsers);

        // Update selectedItemTypes if this is a special item
        if (editingItem.itemType && editingItem.itemType !== 'Item') {
            const updatedItemTypes: ItemTypes = { ...selectedItemTypes };
            // Remove any existing assignments for this type
            Object.entries(selectedItemTypes).forEach(([key, value]) => {
                if (value === editingItem.itemType) {
                    delete updatedItemTypes[Number(key)];
                }
            });
            // Add the new assignment
            updatedItemTypes[editingItem.id] = editingItem.itemType;
            setSelectedItemTypes(updatedItemTypes);
        }

        handleCloseModal();
    };

    const handleAddRow = () => {
        handleOpenModal();
    };

    const handleDeleteRow = (itemId: any) => {
        // Reset bill split since items are changing
        setBillSplit(null);

        setItems((prevItems: any) => prevItems.filter((prevItem: any) => prevItem.id !== itemId));
        setSelectedItemTypes((prev) => {
            const updated = { ...prev };
            delete updated[itemId];
            return updated;
        });
        setSelectedUsers((prev) => {
            const updated = { ...prev };
            delete updated[itemId];
            return updated;
        });
    };

    const getInitials = (name: string) => {
        return name.split(' ')
            .map(word => word[0])
            .join('')
            .toUpperCase()
            .slice(0, 2);
    };

    const renderSpecialItemsSelector = () => (
        <Box sx={{ mt: 0, }}>
            {/* <Typography variant="h6" gutterBottom>
                Identify Special Items
            </Typography> */}
            <Grid container spacing={2}>
                {['Subtotal', 'Tax', 'Tip', 'Total'].map((type) => {
                    const selectedItemId = Object.entries(selectedItemTypes)
                        .find(([_, value]) => value === type)?.[0];
                    const selectedItem = items.find((item: any) => item.id.toString() === selectedItemId);

                    return (
                        <Grid item xs={12} sm={6} md={3} key={type}>
                            <Card
                                variant="outlined"
                                sx={{
                                    bgcolor: 'background.paper',
                                    transition: 'all 0.2s ease-in-out',
                                    '&:hover': {
                                        borderColor: 'primary.main',
                                        boxShadow: 1
                                    }
                                }}
                            >
                                <CardContent>
                                    <Typography
                                        color="primary"
                                        gutterBottom
                                        sx={{
                                            fontWeight: 500,
                                            mb: 1.5
                                        }}
                                    >
                                        {type}
                                    </Typography>
                                    <FormControl fullWidth size="small">
                                        <Select
                                            value={selectedItemId || ''}
                                            onChange={(e: any) => {
                                                if (e.target.value === 'add_new') {
                                                    handleOpenModal(undefined, type);
                                                    return;
                                                }

                                                const newItemTypes: ItemTypes = { ...selectedItemTypes };

                                                // First, remove the current type's assignment if it exists
                                                Object.entries(selectedItemTypes).forEach(([key, value]) => {
                                                    if (value === type) {
                                                        delete newItemTypes[Number(key)];
                                                    }
                                                });

                                                // Then, if we're selecting a new item
                                                if (e.target.value) {
                                                    // Remove this item from any other type it might be assigned to
                                                    Object.entries(selectedItemTypes).forEach(([key, value]) => {
                                                        if (Number(key) === Number(e.target.value)) {
                                                            delete newItemTypes[Number(key)];
                                                        }
                                                    });
                                                    // Assign the new item to this type
                                                    newItemTypes[e.target.value] = type;
                                                }

                                                setSelectedItemTypes(newItemTypes);
                                            }}
                                            displayEmpty
                                            sx={{
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'divider'
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'primary.main'
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'primary.main'
                                                }
                                            }}
                                            renderValue={(selected) => {
                                                if (!selected) {
                                                    return <em>Select {type} Item</em>;
                                                }
                                                const item = items.find((item: any) => item.id === Number(selected));
                                                return (
                                                    <span>{item?.item} (${Number(item?.price).toFixed(2)})</span>
                                                );
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>Select {type} Item</em>
                                            </MenuItem>
                                            <MenuItem value="add_new" sx={{ color: 'primary.main' }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <AddIcon fontSize="small" />
                                                    Add New {type} Item
                                                </Box>
                                            </MenuItem>
                                            <Divider />
                                            {items.map((item: any) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                        <IconButton
                                                            size="small"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleOpenModal(item);
                                                            }}
                                                            sx={{ mr: 1, p: 0.5 }}
                                                        >
                                                            <EditIcon fontSize="small" />
                                                        </IconButton>
                                                        <span>{item.item} (${Number(item.price).toFixed(2)})</span>
                                                    </Box>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {type === 'Total' &&
                                    <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 0.5 }}>
                                        Total calculated automatically if not specified.
                                    </Typography>}
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );

    const sortedItems = () => {
        return items.filter((item: any) => !selectedItemTypes[item.id]);
    };

    const renderItemsList = () => (
        <Box sx={{ mt: 1 }}>
            {/* <Typography variant="h6" gutterBottom>
                Assign People to Items
            </Typography> */}
            <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto'}}>
                <Table size="small">
                    <TableHead>
                        <TableRow sx={{
                            bgcolor: '#03A9F4',
                            borderBottom: '1px solid rgba(255,255,255,0.3)'
                        }}>
                            <TableCell sx={{ color: '#FFFFFF', fontWeight: 'bold' }}>Description</TableCell>
                            <TableCell align="right" sx={{ color: '#FFFFFF', fontWeight: 'bold' }}>Price</TableCell>
                            <TableCell align="center" sx={{ color: '#FFFFFF', fontWeight: 'bold' }}>People</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedItems().map((item: any) => (
                            <TableRow
                                key={item.id}
                                sx={{
                                    bgcolor: '#2D2D2D',
                                    color: '#FFFFFF',
                                    '&:hover': { backgroundColor: 'rgba(3, 169, 244, 0.2)' }
                                }}
                            >
                                <TableCell
                                    onClick={() => handleOpenModal(item)}
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                                        maxWidth: { xs: '80px', sm: '150px' },
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {item.item}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    onClick={() => handleOpenModal(item)}
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    ${Number(item.price).toFixed(2)}
                                </TableCell>
                                <TableCell align="center" sx={{ position: 'relative', padding: '8px 4px' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Select
                                            multiple
                                            size="small"
                                            value={selectedUsers[item.id] ? Object.keys(selectedUsers[item.id]) : []}
                                            onChange={(e) => {
                                                const value = e.target.value as string[];
                                                // Check if the special "select-all" value is present
                                                if (value.includes('select-all')) {
                                                    // If all users are already selected, deselect all
                                                    // Otherwise, select all
                                                    const currentUsers = selectedUsers[item.id] || {};
                                                    const newWeights: UserWeight = {};

                                                    if (Object.keys(currentUsers).length === users.length) {
                                                        // Deselect all - empty object
                                                    } else {
                                                        // Select all - set weight of 1 for each user
                                                        users.forEach(user => {
                                                            newWeights[user] = 1;
                                                        });
                                                    }

                                                    setSelectedUsers({
                                                        ...selectedUsers,
                                                        [item.id]: newWeights
                                                    });
                                                } else {
                                                    const newWeights: UserWeight = {};
                                                    value.forEach(user => {
                                                        // Preserve existing weights or set to 1 for new users
                                                        newWeights[user] = (selectedUsers[item.id]?.[user] || 1);
                                                    });

                                                    setSelectedUsers({
                                                        ...selectedUsers,
                                                        [item.id]: newWeights
                                                    });
                                                }
                                            }}
                                            renderValue={(selected) => (
                                                <AvatarGroup
                                                    max={3}
                                                    sx={{
                                                        '& .MuiAvatar-root': {
                                                            width: 24,
                                                            height: 24,
                                                            fontSize: '0.75rem'
                                                        }
                                                    }}
                                                >
                                                    {(selected as string[]).filter(Boolean).map((user) => (
                                                        <Avatar
                                                            key={user}
                                                            sx={{
                                                                bgcolor: getUserColor(user, users),
                                                                color: '#ffffff'
                                                            }}
                                                        >
                                                            {user ? getInitials(user) : ''}
                                                        </Avatar>
                                                    ))}
                                                </AvatarGroup>
                                            )}
                                            sx={{
                                                width: '100%',
                                                maxWidth: { xs: 'calc(100% - 32px)', sm: 'calc(100% - 32px)' }
                                            }}
                                        >
                                            <MenuItem value="select-all">
                                                {(selectedUsers[item.id]?.length || 0) === users.length ? 'Deselect All' : 'Select All'}
                                            </MenuItem>
                                            <Divider />
                                            {users.map((user) => (
                                                <MenuItem key={user} value={user}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                        <Avatar
                                                            sx={{
                                                                width: 20,
                                                                height: 20,
                                                                fontSize: '0.65rem',
                                                                bgcolor: getUserColor(user, users),
                                                                color: '#ffffff'
                                                            }}
                                                        >
                                                            {getInitials(user)}
                                                        </Avatar>
                                                        {user}
                                                    </Box>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <IconButton
                                            onClick={() => handleOpenModal(item)}
                                            size="small"
                                            sx={{
                                                ml: 0.5,
                                                p: '4px'
                                            }}
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                <Button
                    variant="outlined"
                    startIcon={<AddBoxIcon />}
                    onClick={handleAddRow}
                >
                    Add Item
                </Button>
            </Box>
        </Box>
    );

    const handleUpdateWeight = (itemId: number, user: string, newWeight: number) => {
        setSelectedUsers({
            ...selectedUsers,
            [itemId]: {
                ...selectedUsers[itemId],
                [user]: newWeight
            }
        });
    };

    const handleUserSelectionInModal = (selectedUsersList: string[]) => {
        if (!editingItem) return;

        setEditingItem({
            ...editingItem,
            purchased_by: selectedUsersList
        });

        // Initialize weights for selected users
        const newWeights: UserWeight = {};
        selectedUsersList.forEach(user => {
            // Get existing weight or default to 1
            const existingWeight = selectedUsers[editingItem.id]?.[user];
            newWeights[user] = existingWeight || 1;
        });

        const updatedSelectedUsers: UserWeights = {
            ...selectedUsers,
            [editingItem.id]: newWeights
        };

        setSelectedUsers(updatedSelectedUsers);
    };

    const renderEditModal = () => (
        <StyledDialog
            open={!!editingItem}
            onClose={handleCloseModal}
            fullWidth
            maxWidth="sm"
        >
            {editingItem && (
                <>
                    <StyledDialogTitle>
                        {!items.some((item: any) => item.id === editingItem.id) ? 'Add Item' : 'Edit Item'}
                        <IconButton
                            onClick={handleCloseModal}
                            size="small"
                            sx={{
                                color: 'text.secondary',
                                '&:hover': {
                                    color: 'primary.main',
                                    backgroundColor: 'rgba(144, 202, 249, 0.08)'
                                }
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </StyledDialogTitle>
                    <DialogContent>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: { xs: 1.5, sm: 2 }
                        }}>
                            <StyledFormControl>
                                <FormLabel>Item Type</FormLabel>
                                <Select
                                    value={editingItem.itemType || 'Item'}
                                    onChange={(e) => {
                                        const newType = e.target.value;
                                        setEditingItem({ ...editingItem, itemType: newType });

                                        // Update selectedItemTypes
                                        const updatedTypes = { ...selectedItemTypes };

                                        // Remove any existing assignments for this type
                                        Object.entries(selectedItemTypes).forEach(([key, value]: any) => {
                                            if (value === newType) {
                                                delete updatedTypes[key];
                                            }
                                        });

                                        // Add new type assignment if it's not a regular item
                                        if (newType !== 'Item') {
                                            updatedTypes[editingItem.id] = newType;
                                        } else {
                                            delete updatedTypes[editingItem.id];
                                        }

                                        setSelectedItemTypes(updatedTypes);
                                    }}
                                >
                                    {itemTypes.map((type) => (
                                        <MenuItem key={type} value={type}>
                                            {type}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </StyledFormControl>

                            <StyledFormControl>
                                <TextField
                                    label="Item Name"
                                    value={editingItem.item}
                                    onChange={(e) => setEditingItem({ ...editingItem, item: e.target.value })}
                                    fullWidth
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'primary.main'
                                            }
                                        }
                                    }}
                                />
                            </StyledFormControl>

                            <StyledFormControl>
                                <TextField
                                    label="Price"
                                    type="number"
                                    value={editingItem.price}
                                    onChange={(e) => setEditingItem({ ...editingItem, price: parseFloat(e.target.value) || 0 })}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'primary.main'
                                            }
                                        }
                                    }}
                                />
                            </StyledFormControl>

                            {(!editingItem.itemType || editingItem.itemType === 'Item') && (
                                <>
                                    <StyledFormControl>
                                        <FormLabel
                                            component="legend"
                                            sx={{
                                                marginBottom: 1,
                                                color: 'primary.main',
                                                '&.Mui-focused': {
                                                    color: 'primary.main'
                                                }
                                            }}
                                        >
                                            Purchased By
                                        </FormLabel>
                                        <Select
                                            multiple
                                            value={editingItem.purchased_by}
                                            onChange={(e) => {
                                                const value = e.target.value as string[];
                                                if (value.includes('select-all')) {
                                                    if (editingItem.purchased_by.length === users.length) {
                                                        handleUserSelectionInModal([]);
                                                    } else {
                                                        handleUserSelectionInModal([...users]);
                                                    }
                                                } else {
                                                    handleUserSelectionInModal(value);
                                                }
                                            }}
                                            fullWidth
                                            sx={{
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'divider'
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'primary.main'
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'primary.main'
                                                }
                                            }}
                                            renderValue={(selected) => (
                                                <AvatarGroup
                                                    max={4}
                                                    sx={{
                                                        '& .MuiAvatar-root': {
                                                            width: 24,
                                                            height: 24,
                                                            fontSize: '0.75rem'
                                                        }
                                                    }}
                                                >
                                                    {(selected as string[]).map((user) => (
                                                        <Avatar
                                                            key={user}
                                                            sx={{
                                                                bgcolor: getUserColor(user, users),
                                                                color: '#ffffff'
                                                            }}
                                                        >
                                                            {getInitials(user)}
                                                        </Avatar>
                                                    ))}
                                                </AvatarGroup>
                                            )}
                                        >
                                            <MenuItem value="select-all">
                                                <ListItemText
                                                    primary={editingItem.purchased_by.length === users.length ? 'Deselect All' : 'Select All'}
                                                />
                                            </MenuItem>
                                            <Divider />
                                            {users.map((user) => (
                                                <MenuItem key={user} value={user}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
                                                        <Checkbox
                                                            checked={editingItem.purchased_by.includes(user)}
                                                        />
                                                        <Avatar
                                                            sx={{
                                                                width: 24,
                                                                height: 24,
                                                                fontSize: '0.75rem',
                                                                bgcolor: getUserColor(user, users),
                                                                color: '#ffffff'
                                                            }}
                                                        >
                                                            {getInitials(user)}
                                                        </Avatar>
                                                        <ListItemText primary={user} />
                                                    </Box>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </StyledFormControl>

                                    <Accordion
                                        sx={{
                                            mt: { xs: 1.5, sm: 2 },
                                            '& .MuiAccordionSummary-root': {
                                                minHeight: { xs: 40, sm: 48 },
                                                px: { xs: 1, sm: 2 },
                                            },
                                            '& .MuiAccordionDetails-root': {
                                                padding: 2,
                                                px: { xs: 1, sm: 2 },
                                                py: { xs: 1, sm: 1.5 },
                                                borderTop: '1px solid',
                                                borderTopColor: 'divider'
                                            },
                                            '&.MuiAccordion-root': {
                                                boxShadow: 'none',
                                                border: '1px solid',
                                                borderColor: 'divider',
                                                borderRadius: 1,
                                                '&:before': {
                                                    display: 'none',
                                                },
                                                '&.Mui-expanded': {
                                                    margin: 0,
                                                    mt: 2
                                                }
                                            },
                                            '& .MuiAccordionSummary-content': {
                                                margin: '12px 0',
                                                '&.Mui-expanded': {
                                                    margin: '12px 0',
                                                }
                                            }
                                        }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{ color: 'primary.main' }} />}
                                            aria-controls="split-between-content"
                                            id="split-between-header"
                                        >
                                            <Typography
                                                variant="subtitle2"
                                                sx={{
                                                    color: 'primary.main',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 1
                                                }}
                                            >
                                                Advanced: Custom Split Ratios
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    display: 'block',
                                                    mb: { xs: 1.5, sm: 2 },
                                                    p: { xs: 1, sm: 1.5 },
                                                    fontSize: { xs: '0.7rem', sm: '0.75rem' },
                                                    backgroundColor: 'rgba(144, 202, 249, 0.08)',
                                                    color: 'text.secondary'
                                                }}
                                            >
                                                Adjust portions for each person. For example, if someone had a double portion, set their multiplier to 2x.
                                            </Typography>
                                            <Box sx={{
                                                maxHeight: { xs: '200px', sm: 'none' },
                                                overflowY: 'auto',
                                                pr: 1
                                            }}>
                                                {editingItem.purchased_by.map((user: string) => (
                                                    <UserWeightRow key={user}>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 1,
                                                            width: { xs: '100%', sm: 'auto' }
                                                        }}>
                                                            <Avatar
                                                                sx={{
                                                                    width: { xs: 28, sm: 24 },
                                                                    height: { xs: 28, sm: 24 },
                                                                    fontSize: { xs: '0.8rem', sm: '0.75rem' },
                                                                    bgcolor: getUserColor(user, users),
                                                                }}
                                                            >
                                                                {getInitials(user)}
                                                            </Avatar>
                                                            <Typography>{user}</Typography>
                                                        </Box>
                                                        <WeightContainer>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => {
                                                                    const currentWeight = selectedUsers[editingItem.id]?.[user] || 1;
                                                                    handleUpdateWeight(editingItem.id, user, Math.max(1, currentWeight - 1));
                                                                }}
                                                                sx={{ color: 'primary.main' }}
                                                            >
                                                                <RemoveIcon />
                                                            </IconButton>
                                                            <Typography
                                                                sx={{
                                                                    minWidth: '40px',
                                                                    textAlign: 'center',
                                                                    fontWeight: 'medium'
                                                                }}
                                                            >
                                                                {selectedUsers[editingItem.id]?.[user] || 1}x
                                                            </Typography>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => {
                                                                    const currentWeight = selectedUsers[editingItem.id]?.[user] || 1;
                                                                    handleUpdateWeight(editingItem.id, user, currentWeight + 1);
                                                                }}
                                                                sx={{ color: 'primary.main' }}
                                                            >
                                                                <AddIcon />
                                                            </IconButton>
                                                        </WeightContainer>
                                                    </UserWeightRow>
                                                ))}
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                </>
                            )}
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{
                        px: { xs: 0, sm: 0 },
                        pb: { xs: 0, sm: 0 },
                        mt: { xs: 1, sm: 2 },
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <Box>
                            {items.some((item: any) => item.id === editingItem.id) && (
                                <Button
                                    onClick={() => {
                                        handleDeleteRow(editingItem.id);
                                        handleCloseModal();
                                    }}
                                    color="error"
                                    startIcon={<DeleteIcon />}
                                >
                                    Delete
                                </Button>
                            )}
                        </Box>
                        <Box>
                            <Button
                                onClick={handleCloseModal}
                                sx={{
                                    color: 'text.secondary',
                                    '&:hover': {
                                        backgroundColor: 'rgba(144, 202, 249, 0.08)'
                                    }
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleSaveItem}
                                variant="contained"
                                sx={{
                                    ml: 1,
                                    boxShadow: 'none',
                                    '&:hover': {
                                        boxShadow: 'none'
                                    }
                                }}
                            >
                                Save
                            </Button>
                        </Box>
                    </DialogActions>
                </>
            )}
        </StyledDialog>
    );

    const renderCustomTotalInput = () => (
        <Accordion
            sx={{
                mt: 2,
                '& .MuiAccordionSummary-root': {
                    minHeight: { xs: 40, sm: 48 },
                    px: { xs: 1, sm: 2 },
                },
                '& .MuiAccordionDetails-root': {
                    padding: 2,
                    px: { xs: 1, sm: 2 },
                    py: { xs: 1, sm: 1.5 },
                    borderTop: '1px solid',
                    borderTopColor: 'divider'
                },
                '&.MuiAccordion-root': {
                    boxShadow: 'none',
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 1,
                    '&:before': {
                        display: 'none',
                    },
                    '&.Mui-expanded': {
                        margin: 0,
                        mt: 2
                    }
                },
                '& .MuiAccordionSummary-content': {
                    margin: '12px 0',
                    '&.Mui-expanded': {
                        margin: '12px 0',
                    }
                }
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: 'primary.main' }} />}
                aria-controls="currency-conversion-content"
                id="currency-conversion-header"
            >
                <Typography
                    variant="subtitle2"
                    sx={{
                        color: 'primary.main',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                    }}
                >
                    Advanced: Currency Conversion
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography
                    variant="caption"
                    sx={{
                        display: 'block',
                        mb: { xs: 1.5, sm: 2 },
                        p: { xs: 1, sm: 1.5 },
                        fontSize: { xs: '0.7rem', sm: '0.75rem' },
                        backgroundColor: 'rgba(144, 202, 249, 0.08)',
                        color: 'text.secondary',
                        borderRadius: 1
                    }}
                >
                    When splitting a foreign purchase (e.g., €100 on receipt, $108 on bank statement), enter the dollar amount ($108) for accurate splitting.
                </Typography>
                <TextField
                    label="Custom Total Amount"
                    value={customTotal}
                    onChange={(e) => setCustomTotal(e.target.value)}
                    type="number"
                    fullWidth
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    sx={{
                        minWidth: { xs: '100%', sm: '300px' },
                        '& .MuiOutlinedInput-root': {
                            padding: '3px 14px',
                            '& fieldset': {
                                borderColor: 'divider'
                            },
                            '&:hover fieldset': {
                                borderColor: 'primary.main'
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'primary.main'
                            }
                        }
                    }}
                />
            </AccordionDetails>
        </Accordion>
    );

    const renderWarnings = () => {
        if (!billSplit?.warnings?.length) return null;

        return (
            <Accordion defaultExpanded sx={{ mb: 3 }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography sx={{ color: 'warning.main', fontWeight: 500 }}>
                            Warnings ({billSplit.warnings.length})
                        </Typography>
                        <Typography sx={{ color: 'warning.main', fontSize: '0.875rem' }}>
                            Fix these to ensure accurate splitting.
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    {billSplit.warnings.map((warning, index) => (
                        <Alert
                            key={index}
                            severity="warning"
                            sx={{
                                mb: index < (billSplit?.warnings?.length || 0) - 1 ? 1 : 0,
                                '& .MuiAlert-message': {
                                    width: '100%'
                                }
                            }}
                        >
                            {warning}
                        </Alert>
                    ))}
                </AccordionDetails>
            </Accordion>
        );
    };

    const handleCopyToClipboard = async () => {
        if (!billSplit) return;

        try {
            await navigator.clipboard.writeText(billSplit?.summary);
            setCopySuccess(true);
            addNotificationSuccess('Summary copied to clipboard!');

            // Reset success state after 2 seconds
            setTimeout(() => {
                setCopySuccess(false);
            }, 2000);
        } catch (err) {
            addNotificationError('Failed to copy to clipboard');
        }
    };

    const handleDownloadCSV = () => {
        if (!billSplit?.csv) {
            addNotificationError('CSV data not available');
            return;
        }

        // Create a blob from the CSV string
        const blob = new Blob([billSplit.csv.string], { type: 'text/csv;charset=utf-8;' });

        // Create a download link and trigger the download
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', billSplit.csv.filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        addNotificationSuccess('CSV downloaded successfully');
    };

    const renderDetailedTable = () => {
        if (!billSplit?.csv?.string) {
            return (
                <Box sx={{ mt: 4, mb: 4, textAlign: 'center', p: 3 }}>
                    <Typography variant="body1" color="text.secondary">
                        Detailed breakdown is not available. Try clicking "Split Bill" again.
                    </Typography>
                </Box>
            );
        }

        // Parse the CSV data
        const rows = billSplit.csv.string.split('\n').map(row => row.split(','));
        const headers = rows[0];

        // Process headers to combine share and percentage columns
        const processedHeaders = ['Item', 'Price'];
        const users = billSplit.all_users || [];
        users.forEach(user => {
            processedHeaders.push(`${user}`);
        });

        // Process rows to combine share and percentage
        const processedRows = [];
        const dataRows = rows.slice(1).filter(row => row.length > 1); // Filter out empty rows

        for (const row of dataRows) {
            if (row[0] === '------------------') {
                // Add separator row
                const separatorRow = ['------------------', '------'];
                users.forEach(() => separatorRow.push('------'));
                processedRows.push(separatorRow);
                continue;
            }

            const newRow = [row[0], row[1]]; // Item name and price

            // Process each user's data (combining share and percentage)
            for (let i = 0; i < users.length; i++) {
                const shareIndex = 2 + (i * 2); // Index of share column
                const percentIndex = shareIndex + 1; // Index of percentage column

                if (row[shareIndex] && row[percentIndex]) {
                    // Combine share and percentage
                    newRow.push(`${row[shareIndex]} (${row[percentIndex]})`);
                } else {
                    newRow.push('');
                }
            }

            processedRows.push(newRow);
        }

        return (
            <Box ref={detailedTableRef} sx={{ p: { xs: 2, sm: 3 } }}>

                {/* Mobile view - simplified cards for each item */}
                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                    {processedRows.map((row, rowIndex) => {
                        // Skip separator rows in mobile view
                        if (row[0] === '------------------') return null;

                        const isSpecialRow = ['TOTAL', 'Subtotal', 'Tax', 'Tip'].includes(row[0]);
                        const isTotal = row[0] === 'TOTAL';

                        const cardBackground = isTotal ? 'rgba(76, 175, 80, 0.08)' :
                                              row[0] === 'Subtotal' ? 'rgba(33, 150, 243, 0.08)' :
                                              row[0] === 'Tax' ? 'rgba(156, 39, 176, 0.08)' :
                                              row[0] === 'Tip' ? 'rgba(255, 152, 0, 0.08)' : 'background.paper';

                        // Fix for NaN - ensure we have a valid number or display the original value
                        const priceDisplay = isNaN(parseFloat(row[1])) ? row[1] : `$${parseFloat(row[1]).toFixed(2)}`;

                        return (
                            <Card
                                key={rowIndex}
                                sx={{
                                    mb: 2,
                                    backgroundColor: cardBackground,
                                    border: '1px solid',
                                    borderColor: 'divider'
                                }}
                            >
                                <CardContent sx={{ p: 2 }}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        mb: 1.5,
                                        borderBottom: isSpecialRow ? '1px solid' : 'none',
                                        borderColor: 'divider',
                                        pb: isSpecialRow ? 1 : 0
                                    }}>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                fontWeight: isSpecialRow ? 600 : 500,
                                                color: isSpecialRow ? 'text.primary' : 'text.secondary'
                                            }}
                                        >
                                            {row[0]}
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                fontWeight: isSpecialRow ? 600 : 500,
                                                color: isSpecialRow ? 'text.primary' : 'text.secondary'
                                            }}
                                        >
                                            {priceDisplay}
                                        </Typography>
                                    </Box>

                                    {/* Only show user breakdown for non-special rows or TOTAL */}
                                    <Box sx={{ mt: 1 }}>
                                        {users.map((user, userIndex) => {
                                            const userShare = row[userIndex + 2];
                                            if (!userShare) return null;

                                            // Handle both formats - with or without parentheses
                                            let amount, percentage;
                                            if (userShare.includes('(')) {
                                                [amount, percentage] = userShare.split(' ');
                                                // Remove parentheses from percentage
                                                percentage = percentage.replace('(', '').replace(')', '');
                                            } else {
                                                // If already in simple format
                                                const parts = userShare.split(' ');
                                                amount = parts[0];
                                                percentage = parts.length > 1 ? parts[1] : '';
                                            }

                                            return (
                                                <Box
                                                    key={user}
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        py: 0.75,
                                                        borderBottom: '1px dashed rgba(0,0,0,0.08)',
                                                        '&:last-child': {
                                                            borderBottom: 'none'
                                                        }
                                                    }}
                                                >
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Avatar
                                                            sx={{
                                                                width: 24,
                                                                height: 24,
                                                                fontSize: '0.75rem',
                                                                mr: 1,
                                                                bgcolor: getUserColor(user, users)
                                                            }}
                                                        >
                                                            {getInitials(user)}
                                                        </Avatar>
                                                        <Typography variant="body2">{user}</Typography>
                                                    </Box>
                                                    <Box sx={{ textAlign: 'right', display: 'flex', alignItems: 'baseline', gap: 0.5 }}>
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                fontWeight: isTotal ? 600 : 500,
                                                                color: 'text.primary'
                                                            }}
                                                        >
                                                            {amount}
                                                        </Typography>
                                                        {percentage && (
                                                            <Typography
                                                                variant="caption"
                                                                sx={{
                                                                    color: 'text.secondary',
                                                                    whiteSpace: 'nowrap'
                                                                }}
                                                            >
                                                                ({percentage})
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                </Box>
                                            );
                                        })}
                                    </Box>

                                    {/* Only show explanatory messages for separator rows or other special cases if needed */}
                                    {row[0] === '------------------' && (
                                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1, fontStyle: 'italic' }}>
                                            Section divider
                                        </Typography>
                                    )}
                                </CardContent>
                            </Card>
                        );
                    })}
                </Box>

                {/* Desktop view - table format */}
                <Paper
                    elevation={0}
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        borderRadius: 2,
                        overflow: 'hidden',
                        border: '1px solid',
                        borderColor: 'divider',
                        backgroundColor: 'background.paper',
                        mx: 'auto', // Center the table
                        width: 'calc(100% - 16px)', // Add some margin on the sides
                        mb: 2, // Add bottom margin
                        mt: 1, // Add top margin
                        boxShadow: '0 2px 8px rgba(0,0,0,0.05)' // Add subtle shadow
                    }}
                >
                    <TableContainer
                        sx={{
                            maxHeight: { xs: 440, md: 'none' }, // Remove max height on desktop
                            overflowX: 'auto',
                            overflowY: { xs: 'auto', md: 'visible' }, // Allow full expansion on desktop
                            '&::-webkit-scrollbar': {
                                height: 8,
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: 'rgba(0,0,0,0.05)',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'rgba(0,0,0,0.15)',
                                borderRadius: 4,
                                '&:hover': {
                                    backgroundColor: 'rgba(0,0,0,0.25)',
                                },
                            },
                        }}
                    >
                        <Table stickyHeader={false} size="small">
                            <TableHead>
                                <TableRow>
                                    {processedHeaders.map((header, index) => {
                                        const isUserColumn = index > 1;
                                        return (
                                            <TableCell
                                                key={index}
                                                sx={{
                                                    fontWeight: 600,
                                                    backgroundColor: 'primary.main',
                                                    color: '#ffffff',
                                                    position: 'sticky',
                                                    left: index === 0 ? 0 : 'auto',
                                                    top: 0,
                                                    zIndex: index === 0 ? 3 : 2,
                                                    minWidth: index === 0 ? 140 : index === 1 ? 80 : 120,
                                                    fontSize: '0.8125rem',
                                                    padding: '12px 16px',
                                                    textAlign: index > 0 ? 'right' : 'left',
                                                    whiteSpace: 'nowrap',
                                                    boxShadow: index === 0 ? '2px 0 4px rgba(0,0,0,0.1)' : 'none',
                                                    borderBottom: 'none',
                                                    letterSpacing: '0.01em',
                                                }}
                                            >
                                                {isUserColumn ? (
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-end'
                                                    }}>
                                                        <Avatar
                                                            sx={{
                                                                width: 24,
                                                                height: 24,
                                                                fontSize: '0.75rem',
                                                                mr: 1,
                                                                bgcolor: getUserColor(header, users),
                                                                border: '1px solid white'
                                                            }}
                                                        >
                                                            {getInitials(header)}
                                                        </Avatar>
                                                        <Typography
                                                            variant="inherit"
                                                            noWrap
                                                            sx={{
                                                                maxWidth: 100,
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis'
                                                            }}
                                                        >
                                                            {header}
                                                        </Typography>
                                                    </Box>
                                                ) : header}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {processedRows.map((row, rowIndex) => {
                                    const isSpecialRow = ['TOTAL', 'Subtotal', 'Tax', 'Tip', '------------------'].includes(row[0]);
                                    const isTotal = row[0] === 'TOTAL';
                                    const isSeparator = row[0] === '------------------';
                                    const rowBackground = isTotal ? 'rgba(76, 175, 80, 0.08)' :
                                                        row[0] === 'Subtotal' ? 'rgba(33, 150, 243, 0.08)' :
                                                        row[0] === 'Tax' ? 'rgba(156, 39, 176, 0.08)' :
                                                        row[0] === 'Tip' ? 'rgba(255, 152, 0, 0.08)' :
                                                        rowIndex % 2 === 0 ? 'rgba(0, 0, 0, 0.02)' : 'inherit';

                                    if (isSeparator) {
                                        return (
                                            <TableRow key={rowIndex}>
                                                <TableCell
                                                    colSpan={processedHeaders.length}
                                                    sx={{
                                                        padding: '4px 0',
                                                        borderBottom: '1px dashed rgba(0,0,0,0.12)'
                                                    }}
                                                />
                                            </TableRow>
                                        );
                                    }

                                    return (
                                        <TableRow
                                            key={rowIndex}
                                            sx={{
                                                '&:last-child td, &:last-child th': { border: 0 },
                                                backgroundColor: rowBackground,
                                                '&:hover': {
                                                    backgroundColor: isSpecialRow ? undefined : 'rgba(0, 0, 0, 0.04)'
                                                }
                                            }}
                                        >
                                            {row.map((cell, cellIndex) => (
                                                <TableCell
                                                    key={cellIndex}
                                                    sx={{
                                                        position: cellIndex === 0 ? 'sticky' : 'static',
                                                        left: cellIndex === 0 ? 0 : 'auto',
                                                        backgroundColor: rowBackground,
                                                        zIndex: cellIndex === 0 ? 1 : 0,
                                                        fontWeight: isTotal ? 600 : 500, // Bold for total row, semi-bold for others
                                                        fontSize: '0.875rem',
                                                        padding: '10px 16px',
                                                        textAlign: cellIndex > 0 ? 'right' : 'left',
                                                        boxShadow: cellIndex === 0 ? '2px 0 4px rgba(0,0,0,0.05)' : 'none',
                                                        borderBottom: isTotal ? 'none' : '1px solid rgba(0,0,0,0.08)',
                                                        color: 'text.primary', // Consistent text color for all cells
                                                        letterSpacing: '0.01em',
                                                    }}
                                                >
                                                    {cellIndex > 1 && cell ? (
                                                        <Box sx={{
                                                            display: 'inline-flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'flex-end'
                                                        }}>
                                                            <Typography
                                                                component="span"
                                                                sx={{
                                                                    fontWeight: 600,
                                                                    color: 'text.primary',
                                                                    fontSize: '0.875rem'
                                                                }}
                                                            >
                                                                {cell.split(' ')[0]}
                                                            </Typography>
                                                            {cell.includes(' ') && (
                                                                <Typography
                                                                    component="span"
                                                                    sx={{
                                                                        fontSize: '0.75rem', // Slightly larger for better readability
                                                                        fontWeight: 400, // Regular weight for percentage
                                                                        color: 'text.secondary',
                                                                        opacity: 0.8, // Slightly more visible
                                                                        mt: '2px'
                                                                    }}
                                                                >
                                                                    {cell.includes('(')
                                                                        ? cell.split(' ')[1].replace('(', '').replace(')', '')
                                                                        : cell.split(' ')[1]}
                                                                </Typography>
                                                            )}
                                                        </Box>
                                                    ) : (
                                                        <Typography
                                                            component="span"
                                                            sx={{
                                                                fontWeight: isSpecialRow ? 600 : 500,
                                                                fontSize: '0.875rem',
                                                                color: 'text.primary', // Consistent color
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                display: 'block',
                                                                maxWidth: cellIndex === 0 ? '200px' : 'none'
                                                            }}
                                                        >
                                                            {cell}
                                                        </Typography>
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
        );
    };

    const renderBillSummary = () => (
        <>
            <Box ref={billSummaryRef} sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mb: 3
            }}>
                <Typography variant="h5" sx={{ mb: 2 }}>
                    Bill Summary
                </Typography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    gap: { xs: 0.5, sm: 1 },
                    '& .MuiButton-root': {
                        minWidth: { xs: '80px', sm: 'auto' },
                        fontSize: { xs: '0.65rem', sm: '0.875rem' },
                        padding: { xs: '2px 4px', sm: '6px 16px' },
                        height: { xs: '32px', sm: 'auto' },
                        whiteSpace: 'nowrap'
                    }
                }}>
                    <Tooltip title="Copy summary to clipboard">
                        <Button
                            variant="outlined"
                            size="small"
                            startIcon={<ContentCopyIcon />}
                            onClick={handleCopyToClipboard}
                            color={copySuccess ? 'success' : 'primary'}
                        >
                            {copySuccess ? 'Copied!' : 'Copy'}
                        </Button>
                    </Tooltip>

                    {/* Add CSV download button */}
                    <Tooltip title="Download as CSV">
                        <Button
                            variant="outlined"
                            size="small"
                            startIcon={<DownloadIcon />}
                            onClick={handleDownloadCSV}
                            color="primary"
                        >
                            CSV
                        </Button>
                    </Tooltip>

                    {/* Add Share button */}
                    <Tooltip title="Share this bill split">
                        <Button
                            variant="outlined"
                            size="small"
                            startIcon={<ShareIcon />}
                            onClick={handleShareReceipt}
                            color={shareSuccess ? 'success' : 'primary'}
                            disabled={!receiptId}
                        >
                            {shareSuccess ? 'Copied!' : 'Share'}
                        </Button>
                    </Tooltip>
                </Box>
            </Box>

            {renderWarnings()}


            <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={6} sm={3}>
                    <Card sx={{ bgcolor: 'primary.light', color: 'primary.contrastText' }}>
                        <CardContent sx={{ py: 1 }}>
                            <Typography variant="body2">Subtotal</Typography>
                            <Typography variant="h6">${billSplit?.subtotal.toFixed(2)}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Card sx={{ bgcolor: 'secondary.light', color: 'secondary.contrastText' }}>
                        <CardContent sx={{ py: 1 }}>
                            <Typography variant="body2">Tax</Typography>
                            <Typography variant="h6">${billSplit?.tax.toFixed(2)}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Card sx={{ bgcolor: 'info.light', color: 'info.contrastText' }}>
                        <CardContent sx={{ py: 1 }}>
                            <Typography variant="body2">Tip</Typography>
                            <Typography variant="h6">${billSplit?.tip.toFixed(2)}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Card sx={{ bgcolor: 'success.light', color: 'success.contrastText' }}>
                        <CardContent sx={{ py: 1 }}>
                            <Typography variant="body2">Total</Typography>
                            <Typography variant="h6">${billSplit?.total.toFixed(2)}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                {billSplit?.conversion_ratio && (
                    <Grid item xs={6} sm={3}>
                        <Card sx={{ bgcolor: 'warning.light', color: 'warning.contrastText' }}>
                            <CardContent sx={{ py: 1 }}>
                                <Typography variant="body2">Conversion Ratio</Typography>
                                <Typography variant="h6">{billSplit?.conversion_ratio.toFixed(4)}x</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </>
    );

    const hasData = users.length > 0 || items.length > 0 || file || imageSrc;

    const renderClearConfirmationDialog = () => (
        <Dialog
            open={isClearDialogOpen}
            onClose={() => setIsClearDialogOpen(false)}
            aria-labelledby="clear-dialog-title"
            aria-describedby="clear-dialog-description"
        >
            <DialogTitle id="clear-dialog-title">
                Clear All Data?
            </DialogTitle>
            <DialogContent>
                <Typography id="clear-dialog-description">
                    Are you sure you want to reset the bill splitter? This action cannot be undone.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsClearDialogOpen(false)}>
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        handleClear();
                        setIsClearDialogOpen(false);
                    }}
                    color="error"
                    variant="contained"
                >
                    Reset
                </Button>
            </DialogActions>
        </Dialog>
    );

    const renderPeopleInput = () => (
        <Box sx={{ mt: 0 }}>
            <Box sx={{
                display: 'flex',
                gap: 1,
                flexDirection: { xs: 'column', sm: 'row' },
                mb: 2
            }}>
                <TextField
                    label='Name'
                    value={newUserInput}
                    onChange={(e) => setNewUserInput(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter' && newUserInput.trim()) {
                            handleAddUser();
                        }
                    }}
                    placeholder="Enter name"
                    fullWidth
                    size="small"
                    sx={{
                        flexGrow: 1,
                        maxWidth: { sm: '300px' }
                    }}
                />
                <Button
                    variant="contained"
                    onClick={handleAddUser}
                    disabled={!newUserInput.trim()}
                    startIcon={<PersonAddIcon />}
                    sx={{
                        whiteSpace: 'nowrap',
                        minWidth: { xs: '100%', sm: 'auto' }
                    }}
                >
                    Add Person
                </Button>
            </Box>

            {users.length > 0 && (
                <Paper
                    variant="outlined"
                    sx={{
                        p: 2,
                        bgcolor: 'background.default',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1
                    }}
                >
                    <Typography variant="subtitle2" color="text.secondary">
                        People splitting the bill ({users.length}):
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1.5,
                        '& > *': { // Add margin between chips
                            margin: '4px !important', // Override Material-UI's default margins
                        }
                    }}>
                        {users.map((user) => (
                            <Chip
                                key={user}
                                label={user}
                                onDelete={() => handleDeleteUser(user)}
                                avatar={
                                    <Avatar
                                        sx={{
                                            bgcolor: getUserColor(user, users),
                                            color: '#ffffff',
                                            width: 32,
                                            height: 32,
                                            fontSize: '1.1rem'
                                        }}
                                    >
                                        {getInitials(user)}
                                    </Avatar>
                                }
                                sx={{
                                    bgcolor: 'background.paper',
                                    boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
                                    border: '1px solid',
                                    borderColor: 'divider',
                                    transition: 'all 0.2s ease-in-out',
                                    height: 40,
                                    '&:hover': {
                                        boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
                                        borderColor: 'primary.main',
                                    },
                                    '& .MuiChip-deleteIcon': {
                                        color: 'error.dark',
                                        fontSize: '1.3rem',
                                        '&:hover': {
                                            color: 'error.main'
                                        }
                                    },
                                    '& .MuiChip-label': {
                                        fontWeight: 500,
                                        fontSize: '1rem',
                                        px: 2
                                    }
                                }}
                            />
                        ))}
                    </Box>
                </Paper>
            )}

            {/* Add Venmo username input */}
            <Box sx={{ mt: 3, mb: 2 }}>
                <Accordion
                    sx={{
                        '& .MuiAccordionSummary-root': {
                            minHeight: { xs: 40, sm: 48 },
                            px: { xs: 1, sm: 2 },
                        },
                        '& .MuiAccordionDetails-root': {
                            padding: 2,
                            px: { xs: 1, sm: 2 },
                            py: { xs: 1, sm: 1.5 },
                            borderTop: '1px solid',
                            borderTopColor: 'divider'
                        },
                        '&.MuiAccordion-root': {
                            boxShadow: 'none',
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: 1,
                            '&:before': {
                                display: 'none',
                            },
                            '&.Mui-expanded': {
                                margin: 0,
                                mt: 2
                            }
                        },
                        '& .MuiAccordionSummary-content': {
                            margin: '12px 0',
                            '&.Mui-expanded': {
                                margin: '12px 0',
                            }
                        }
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: 'primary.main' }} />}
                        aria-controls="venmo-content"
                        id="venmo-header"
                    >
                        <Typography
                            variant="subtitle2"
                            sx={{
                                color: 'primary.main',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1
                            }}
                        >
                            Add Venmo Username (Optional)
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography
                            variant="caption"
                            sx={{
                                display: 'block',
                                mb: { xs: 1.5, sm: 2 },
                                p: { xs: 1, sm: 1.5 },
                                fontSize: { xs: '0.7rem', sm: '0.75rem' },
                                backgroundColor: 'rgba(144, 202, 249, 0.08)',
                                color: 'text.secondary',
                                borderRadius: 1
                            }}
                        >
                            Add the Venmo username of the person who paid the bill. This will be used to create Venmo payment links in the results.
                        </Typography>
                        <TextField
                            label="Venmo Username"
                            value={venmoUsername}
                            onChange={(e) => setVenmoUsername(e.target.value)}
                            placeholder="bill-splitter"
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">@</InputAdornment>,
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'divider'
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'primary.main'
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'primary.main'
                                    }
                                }
                            }}
                        />
                    </AccordionDetails>
                </Accordion>
            </Box>

            <Box sx={{ mb: 2, mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="contained"
                    onClick={handleNext}
                    disabled={!steps[0].isComplete()}
                >
                    Continue
                </Button>
            </Box>
        </Box>
    );

    const renderVenmoPaymentButton = (userId: string, amount: number) => {
        if (!venmoUsername || !billSplit?.venmo_username) return null;

        // Format the amount to 2 decimal places
        const formattedAmount = amount.toFixed(2);

        // Create Venmo payment URL
        // Note: Venmo URLs are in the format: https://venmo.com/username?txn=pay&amount=XX.XX&note=Note
        const emojis = ['🍕', '🍔', '🍣', '🍻', '🥂', '🍷', '🍝', '🥗', '🍱', '🧾'];
        const randomEmoji = emojis[Math.floor(Math.random() * emojis.length)];
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        const venmoUrl = isMobile
            ? `venmo://paycharge?txn=pay&recipients=${venmoUsername.replace('@', '')}&amount=${formattedAmount}&note=[from saarim.app/split ${randomEmoji}]`
            : `https://venmo.com/${venmoUsername.replace('@', '')}?txn=pay&amount=${formattedAmount}&note=[from saarim.app/split ${randomEmoji}]`;

        return (
            <Button
                variant="contained"
                color="primary"
                fullWidth
                href={venmoUrl}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                    mt: 2,
                    textTransform: 'none',
                    bgcolor: '#3D95CE', // Venmo blue
                    '&:hover': {
                        bgcolor: '#2D85BE'
                    }
                }}
            >
                Venmo @{venmoUsername}
            </Button>
        );
    };

    const renderIndividualBreakdowns = () => (
        <>
            <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
                Individual Breakdowns
            </Typography>
            <Grid container spacing={2}>
                {billSplit?.all_users.map((userId: string) => (
                    <Grid item xs={12} sm={6} md={4} key={userId}>
                        <Card
                            elevation={3}
                            sx={{
                                height: '100%',
                                transition: 'transform 0.2s',
                                '&:hover': {
                                    transform: 'translateY(-4px)',
                                }
                            }}
                        >
                            <CardContent>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    <Avatar
                                        sx={{
                                            bgcolor: getUserColor(userId, billSplit?.all_users || []),
                                            width: 48,
                                            height: 48,
                                            mr: 2,
                                            color: '#ffffff'
                                        }}
                                    >
                                        {userId.charAt(0).toUpperCase()}
                                    </Avatar>
                                    <Typography variant="h6">{userId}</Typography>
                                </Box>

                                <Stack spacing={2}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Box>
                                            <Typography variant="caption" color="text.secondary">
                                                Pre Tax & Tip
                                            </Typography>
                                            <Typography variant="h6">
                                                ${billSplit?.costs[userId].toFixed(2)}
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Typography variant="caption" color="text.secondary">
                                                Share of Total
                                            </Typography>
                                            <Typography variant="h6">
                                                {(billSplit?.proportions[userId] * 100).toFixed(2)}%
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box sx={{
                                        bgcolor: 'primary.dark',
                                        p: 2,
                                        borderRadius: 1,
                                        textAlign: 'center',
                                        color: 'white',
                                        boxShadow: 2
                                    }}>
                                        <Typography variant="subtitle2" sx={{ opacity: 0.9 }}>
                                            Amount Owed
                                        </Typography>
                                        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                            ${billSplit?.user_totals[userId].toFixed(2)}
                                        </Typography>
                                    </Box>

                                    {/* Add Venmo payment button */}
                                    {venmoUsername && renderVenmoPaymentButton(userId, billSplit?.user_totals[userId])}
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    );

    // Add a function to handle accordion expansion and scrolling
    const handleDetailedTableToggle = () => {
        // Toggle the state
        setShowDetailedTable(!showDetailedTable);

        // Only handle scrolling when opening
        if (!showDetailedTable) {
            // Use a series of delayed scrolls to ensure we catch the content after it renders
            const scrollSequence = [200, 400, 600, 800];

            scrollSequence.forEach(delay => {
                setTimeout(() => {
                    if (detailedBreakdownRef.current) {
                        // Get the position of the accordion
                        const rect = detailedBreakdownRef.current.getBoundingClientRect();
                        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

                        // Calculate position to scroll to (top of element minus some padding)
                        const targetPosition = rect.top + scrollTop - 80;

                        // Scroll to the calculated position
                        window.scrollTo({
                            top: targetPosition,
                            behavior: 'smooth'
                        });
                    }
                }, delay);
            });
        }
    };

    return (
        <Paper sx={{ p: { xs: 1, sm: 2 } }}>
            <Typography variant="h4" align="center" sx={{ mb: 1 }}>Bill Splitter</Typography>
            <Typography variant="body1" align="center" sx={{ mb: 2 }}>
                Upload a receipt to extract items and quickly split the bill with your friends!
            </Typography>

            <Box sx={{ mb: 3, display: 'flex', gap: 2, justifyContent: 'center' }}>
                <Button
                    variant='outlined'
                    onClick={useSampleData}
                    startIcon={<LibraryBooksIcon />}
                >
                    Use Sample Data
                </Button>

                <Button
                    variant="outlined"
                    color="error"
                    startIcon={<Delete />}
                    onClick={() => hasData ? setIsClearDialogOpen(true) : handleClear()}
                    disabled={!hasData}
                >
                    Clear All
                </Button>
            </Box>

            <Stepper activeStep={activeStep} orientation="vertical">
                {/* Step 1: Add People */}
                <Step>
                    <StepLabel
                        onClick={() => setActiveStep(0)}
                        sx={{
                            cursor: 'pointer',
                            '&:hover': {
                                '& .MuiStepLabel-label': {
                                    color: 'primary.main'
                                }
                            }
                        }}
                    >
                        <Typography variant="subtitle1">{steps[0].label}</Typography>
                        <Typography variant="caption" color="text.secondary">
                            {steps[0].description}
                        </Typography>
                    </StepLabel>
                    <StepContent>
                        {renderPeopleInput()}
                    </StepContent>
                </Step>

                {/* Step 2: Upload Receipt */}
                <Step>
                    <StepLabel
                        onClick={() => setActiveStep(1)}
                        sx={{
                            cursor: 'pointer',
                            '&:hover': {
                                '& .MuiStepLabel-label': {
                                    color: 'primary.main'
                                }
                            }
                        }}
                    >
                        <Typography variant="subtitle1">{steps[1].label}</Typography>
                        <Typography variant="caption" color="text.secondary">
                            {steps[1].description}
                        </Typography>
                    </StepLabel>
                    <StepContent>
                        <Box sx={{ mt: 2 }}>
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                spacing={2}
                                alignItems="flex-start"
                            >
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    gap: 2,
                                    alignItems: { xs: 'center', sm: 'flex-start' }
                                }}>
                                    <Box>
                                        <label htmlFor="file-upload-button">
                                            <input
                                                id="file-upload-button"
                                                type="file"
                                                accept="image/*"
                                                onChange={handleFileChange}
                                                style={{ display: 'none' }}
                                            />
                                            <Tooltip title="Upload a receipt">
                                                <Button
                                                    component="span"
                                                    variant="contained"
                                                    startIcon={<CloudUpload />}
                                                >
                                                    Upload Receipt
                                                </Button>
                                            </Tooltip>
                                        </label>
                                    </Box>

                                    {file && !items.length && (
                                        <Box sx={{
                                            animation: !isLoading ? 'pulse 1.5s infinite' : 'none',
                                            '@keyframes pulse': {
                                                '0%': {
                                                    transform: 'scale(1)',
                                                    boxShadow: '0 0 0 0 rgba(25, 118, 210, 0.4)',
                                                },
                                                '70%': {
                                                    transform: 'scale(1.05)',
                                                    boxShadow: '0 0 0 10px rgba(25, 118, 210, 0)',
                                                },
                                                '100%': {
                                                    transform: 'scale(1)',
                                                    boxShadow: '0 0 0 0 rgba(25, 118, 210, 0)',
                                                },
                                            }
                                        }}>
                                            <Button
                                                variant="contained"
                                                onClick={handleFileUpload}
                                                disabled={!file || isLoading}
                                                startIcon={isLoading ? <CircularProgress size={20} /> : <ReceiptLongIcon />}
                                                sx={{
                                                    minWidth: '160px' // Set a fixed minimum width
                                                }}
                                            >
                                                {isLoading ? 'Extracting...' : 'Extract Items'}
                                            </Button>
                                        </Box>
                                    )}
                                </Box>

                                {imageSrc && (
                                    <Button
                                        variant="outlined"
                                        onClick={() => setIsImageModalOpen(true)}
                                        startIcon={<PreviewIcon />}
                                    >
                                        View Receipt
                                    </Button>
                                )}
                            </Stack>

                            {items.length > 0 && file && (
                                <Alert
                                    severity="success"
                                    sx={{ mt: 2 }}
                                >
                                    Items extracted successfully! Continue to next step...
                                </Alert>
                            )}

                            <Divider sx={{ my: 3 }} />

                            <Box sx={{ mb: 2, mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                                <Button onClick={handleBack}>
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleNext}
                                >
                                    {items.length > 0 ? 'Continue' : file ? 'Skip Receipt Upload' : 'Continue Without Receipt'}
                                </Button>
                            </Box>
                        </Box>
                    </StepContent>
                </Step>

                {/* Step 3: Special Items */}
                <Step>
                    <StepLabel
                        onClick={() => setActiveStep(2)}
                        sx={{
                            cursor: 'pointer',
                            '&:hover': {
                                '& .MuiStepLabel-label': {
                                    color: 'primary.main'
                                }
                            }
                        }}
                    >
                        <Typography variant="subtitle1">{steps[2].label}</Typography>
                        <Typography variant="caption" color="text.secondary">
                            {steps[2].description}
                        </Typography>
                    </StepLabel>
                    <StepContent>
                        <Box sx={{ mt: 1 }}>
                            {file && items.length > 0 && (
                                <Alert
                                    severity="warning"
                                >
                                    Please verify all extracted items and prices, as AI can make mistakes.
                                </Alert>
                            )}

                            {renderSpecialItemsSelector()}

                            <Box sx={{mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                                <Button onClick={handleBack}>
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleNext}
                                >
                                    Continue
                                </Button>
                            </Box>
                        </Box>
                    </StepContent>
                </Step>

                {/* Step 4: Regular Items */}
                <Step>
                    <StepLabel
                        onClick={() => setActiveStep(3)}
                        sx={{
                            cursor: 'pointer',
                            '&:hover': {
                                '& .MuiStepLabel-label': {
                                    color: 'primary.main'
                                }
                            }
                        }}
                    >
                        <Typography variant="subtitle1">{steps[3].label}</Typography>
                        <Typography variant="caption" color="text.secondary">
                            {steps[3].description}
                        </Typography>
                    </StepLabel>
                    <StepContent>
                        <Box sx={{ mt: 1 }}>
                            {file && items.length > 0 && (
                                <Alert
                                    severity="warning"
                                    // sx={{ mb: 2 }}
                                >
                                    Please verify all extracted items and prices, as AI can make mistakes.
                                </Alert>
                            )}


                            {items.length > 0 && renderItemsList()}

                            {/* Currency conversion at the bottom */}
                            {items.length > 0 && (
                                <Box sx={{ mt: 4 }}>
                                    {renderCustomTotalInput()}
                                </Box>
                            )}

                            <Box sx={{ mb: 2, mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                                <Button onClick={handleBack}>
                                    Back
                                </Button>
                                <Button
                                    disabled={!steps[3].isComplete()}
                                    onClick={splitBill}
                                    variant="contained"
                                    startIcon={<PriceChangeIcon />}
                                >
                                    Split Bill
                                </Button>
                            </Box>
                        </Box>
                    </StepContent>
                </Step>
            </Stepper>

            {billSplit && (
                <Box sx={{ mt: 3 }}>
                    <Divider sx={{ my: 3 }} />

                    {renderBillSummary()}
                    {renderIndividualBreakdowns()}

                    <Accordion
                        expanded={showDetailedTable}
                        onChange={handleDetailedTableToggle}
                        ref={detailedBreakdownRef}
                        sx={{
                            mt: 3,
                            mb: 3,
                            boxShadow: 'none',
                            '&:before': { display: 'none' },
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: '8px !important',
                            overflow: 'hidden',
                            '& .MuiAccordionSummary-root': {
                                backgroundColor: 'background.paper',
                                borderBottom: showDetailedTable ? '1px solid' : 'none',
                                borderBottomColor: 'divider'
                            },
                            '& .MuiAccordionDetails-root': {
                                backgroundColor: 'rgba(0,0,0,0.01)', // Subtle background color
                                padding: 0 // Remove default padding
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="detailed-breakdown-content"
                            id="detailed-breakdown-header"
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <TableChartIcon color="primary" fontSize="small" />
                                <Typography variant="subtitle1" color="primary.main">
                                    Detailed Breakdown
                                </Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            {renderDetailedTable()}
                        </AccordionDetails>
                    </Accordion>


                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                        <Button onClick={handleReset} variant="outlined">
                            Split Another Bill
                        </Button>
                    </Box>
                </Box>
            )}
            {renderEditModal()}
            {renderImageModal()}
            {renderClearConfirmationDialog()}
        </Paper>
    );
};

export default BillSplitter;