import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from '../../stores/actions/StoreActions';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';

import Client_ from '../../api/Client';
import Link from '../Link';
import './Login.css';
import { useNotification } from '../../stores/actions/StoreActions';
import { supabase } from '../../config/supabase'; // Import from config file

interface LoginProps { }



const Login: React.FC<LoginProps> = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const user = useSelector((state: any) => state.user);
    const { loginUser, loginWithToken } = useUser();
    const { addNotificationSuccess, addNotificationError } = useNotification();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [verificationDialogOpen, setVerificationDialogOpen] = useState(false);
    const [verificationEmail, setVerificationEmail] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Extract redirect path from location state if it exists
    const redirectPath = location.state?.returnTo || null;

    // Store returnTo path in localStorage for OAuth flows
    useEffect(() => {
        if (redirectPath) {
            localStorage.setItem('redirect_after_login', redirectPath);
        }
    }, [redirectPath]);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = () => {
        loginUser(email, password);
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    const handleVerificationDialogOpen = () => {
        setVerificationDialogOpen(true);
    };

    const handleVerificationDialogClose = () => {
        setVerificationDialogOpen(false);
        setVerificationEmail("");
    };

    const handleResendVerification = async () => {
        if (!verificationEmail) return;

        setIsSubmitting(true);
        try {
            const client = new Client_();
            const { err } = await client.resendEmailVerification(verificationEmail);

            if (err) {
                addNotificationError(err);
            } else {
                addNotificationSuccess('If this email was used to sign up for an account, a verification email has been sent.');
                handleVerificationDialogClose();
            }
        } catch (error) {
            addNotificationError('An error occurred. Please try again later.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            // Store the flow type in localStorage
            localStorage.setItem('oauth_flow_type', 'login');

            // Get the current URL base dynamically
            const baseUrl = window.location.origin;
            const redirectUrl = `${baseUrl}/login`;

            // Use Supabase's signInWithOAuth method
            const { data, error } = await supabase.auth.signInWithOAuth({
                provider: 'google',
                options: {
                    redirectTo: redirectUrl,
                }
            });

            if (error) {
                addNotificationError(error.message);
            }
        } catch (error) {
            addNotificationError('Failed to initialize Google sign-in');
        }
    };

    useEffect(() => {
        if (user?.username) {
            const storedRedirect = localStorage.getItem('redirect_after_login');
            if (storedRedirect) {
                localStorage.removeItem('redirect_after_login');
                navigate(storedRedirect);
            } else if (!redirectPath) {
                navigate('/profile');
            }
        }
    }, [navigate, user, redirectPath]);

    useEffect(() => {
        // Check for Supabase OAuth response in URL
        const checkForSupabaseAuth = async () => {
            // Check URL hash (fragment) for access token
            const hash = window.location.hash.substring(1);
            if (hash) {
                const params = new URLSearchParams(hash);
                const accessToken = params.get('access_token');
                const refreshToken = params.get('refresh_token');

                if (accessToken) {
                    // Clear the URL hash for security
                    window.history.replaceState(null, '', window.location.pathname);

                    try {
                        // Use Supabase setSession method to set auth session
                        const { data, error } = await supabase.auth.setSession({
                            access_token: accessToken,
                            refresh_token: refreshToken || ''
                        });

                        if (error) {
                            addNotificationError('Error setting session: ' + error.message);
                        } else if (data.user) {
                            // Now use your app's loginWithToken
                            loginWithToken(accessToken, refreshToken || '');
                        }
                    } catch (err) {
                        console.error('Error setting Supabase session:', err);
                        addNotificationError('Failed to authenticate with token');
                    }
                    return;
                }
            }

            // Check URL search params (query string) for access token
            const query = new URLSearchParams(window.location.search);
            const accessToken = query.get('access_token');
            const refreshToken = query.get('refresh_token');

            if (accessToken) {
                // Clear the URL query params for security
                window.history.replaceState(null, '', window.location.pathname);

                try {
                    // Use Supabase setSession method to set auth session
                    const { data, error } = await supabase.auth.setSession({
                        access_token: accessToken,
                        refresh_token: refreshToken || ''
                    });

                    if (error) {
                        addNotificationError('Error setting session: ' + error.message);
                    } else if (data.user) {
                        // Now use your app's loginWithToken
                        loginWithToken(accessToken, refreshToken || '');
                    }
                } catch (err) {
                    console.error('Error setting Supabase session:', err);
                    addNotificationError('Failed to authenticate with token');
                }
            }
        };

        checkForSupabaseAuth();
    }, [loginWithToken, addNotificationError]);

    return (
        <Box
            className='login'
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                maxWidth: '400px',
                margin: 'auto',
                padding: { xs: 2, sm: 3 },
            }}
        >
            <Typography variant="h4" component="h1" gutterBottom>
                Log in to continue
            </Typography>

            {/* Google Sign-in Button - Moved to top */}
            <Button
                variant="outlined"
                fullWidth
                startIcon={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                            <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"/>
                            <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"/>
                            <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"/>
                            <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"/>
                        </svg>
                    </Box>
                }
                onClick={handleGoogleSignIn}
                sx={{
                    my: 2,
                    // textTransform: 'none',
                    fontWeight: 500
                }}
            >
                Continue with Google
            </Button>

            <Divider sx={{ width: '100%', my: 1 }}>
            </Divider>

            {/* Regular Login Form */}
            <TextField
                fullWidth
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                margin="normal"
                onKeyDown={handleKeyDown}
            />

            <TextField
                fullWidth
                id="outlined-basic"
                label="Password"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                margin="normal"
                onKeyDown={handleKeyDown}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
                sx={{ mt: 2 }}
                startIcon={user.isLoading ? <CircularProgress size={24} color="inherit" /> : null}
            >
                {user.isLoading ? 'Logging in...' : 'Log in'}
            </Button>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Typography variant="body2">
                    <Link to='/password-reset' sx={{ fontSize: '0.875rem' }}>
                        Forgot password?
                    </Link>
                    <Box component="span" sx={{ mx: 2, color: 'text.secondary' }}>•</Box>
                    <Link
                        component="button"
                        onClick={handleVerificationDialogOpen}
                        sx={{ fontSize: '0.875rem' }}
                    >
                        Can't verify email?
                    </Link>
                </Typography>
            </Box>
            <Divider sx={{ width: '100%', my: 3 }} />
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2 }}>
                Don't have an account? <Link to="/register">Sign up</Link>
            </Typography>

            {/* Email Verification Dialog */}
            <Dialog
                open={verificationDialogOpen}
                onClose={handleVerificationDialogClose}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Resend Verification Email</DialogTitle>
                <DialogContent>
                    <Box sx={{ p: 2, mb: 2 }}>
                        <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 'medium', color: 'text.primary' }}>
                            Please check your email for a verification email. If you don't see it, please:
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box component="span" sx={{ mr: 1, color: 'warning.main' }}>•</Box>
                                <Typography variant="body2">Check your <strong>spam folder</strong></Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box component="span" sx={{ mr: 1, color: 'warning.main' }}>•</Box>
                                <Typography variant="body2">Make sure your <strong>inbox is not full</strong></Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box component="span" sx={{ mr: 1, color: 'warning.main' }}>•</Box>
                                <Typography variant="body2">Verify you entered the <strong>correct email address</strong></Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box component="span" sx={{ mr: 1, color: 'warning.main' }}>•</Box>
                                <Typography variant="body2">If you're still having issues, try creating a <strong>new account with a different email</strong> or <strong>contact support</strong></Typography>
                            </Box>
                        </Box>
                    </Box>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="verification-email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="outlined"
                        value={verificationEmail}
                        onChange={(e) => setVerificationEmail(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleVerificationDialogClose}>Cancel</Button>
                    <Button
                        onClick={handleResendVerification}
                        disabled={!verificationEmail || isSubmitting}
                    >
                        {isSubmitting ? <CircularProgress size={24} /> : 'Resend'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Login;