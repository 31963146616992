import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Client_ from '../../api/Client';
import constants from '../../constants/Constants';
import { useNotification, useUser } from '../../stores/actions/StoreActions';
import Link from '../Link';

interface SpotifyProps { }


const Spotify: React.FC<SpotifyProps> = () => {
    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { addNotificationError, addNotificationSuccess, addNotification } = useNotification()
    const dispatch = useDispatch();

    const { forgotPassword } = useUser();

    const [playlistUrl, setPlaylistUrl] = useState("");
    const [limit, setLimit] = useState(50);
    const [generatedPlaylistUrl, setGeneratedPlaylistUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [needsSpotifyLogin, setNeedsSpotifyLogin] = useState(false);

    useEffect(() => {
        document.title = 'Find New Music';
        // Define a function to handle the Spotify authentication check
        const checkSpotifyAuth = async () => {
            const { err, res } = await Client_.client_().validateSpotifyAuth();
            if (err || !res?.success) {
                addNotificationError(err);
            } else {
                if (res?.result?.success) {
                    setNeedsSpotifyLogin(false);
                } else {
                    localStorage.setItem('spotify_auth_redirect', '/find-new-music');
                    addNotificationError('Please login to Spotify first');
                    setNeedsSpotifyLogin(true);
                    navigate('/spotify-auth');
                }
            }
        };
        checkSpotifyAuth();
        const intervalId = setInterval(checkSpotifyAuth, 30000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);




    const createNewAndSimilarPlaylist = (playlistUrl: string, limit: number) => {
        setIsLoading(true);
        setGeneratedPlaylistUrl('');
        Client_.client_().createNewAndSimilarPlaylist(playlistUrl, limit).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setGeneratedPlaylistUrl(res?.result?.playlist_url);
            }
        });
    };


    const setExampleData = () => {
        setLimit(50);
        setPlaylistUrl(constants.exampleSpotifyUrl);
        createNewAndSimilarPlaylist(constants.exampleSpotifyUrl, 50);
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            padding: '20px',
            maxWidth: '1200px',
            margin: '0 auto',
            color: '#fff'
        }}>
            <h1 style={{
                fontSize: '2rem',
                fontWeight: '700',
                marginBottom: '24px',
                background: 'linear-gradient(to right, #1DB954, #1ed760)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
            }}>
                Find New & Undiscovered Songs
            </h1>

            {needsSpotifyLogin && (
                <p style={{
                    color: '#ff4444',
                    fontWeight: '500',
                    fontSize: '1rem',
                    padding: '16px',
                    backgroundColor: 'rgba(255, 68, 68, 0.1)',
                    borderRadius: '8px',
                    width: '100%',
                    maxWidth: '800px',
                    marginBottom: '20px'
                }}>
                    Please login to Spotify first, click <Link to='/spotify-auth' target='_blank' rel='noreferrer' style={{ color: '#1DB954' }}>here</Link> if not redirected automatically.
                </p>
            )}

            <p style={{
                color: '#b3b3b3',
                fontSize: '1rem',
                marginBottom: '12px',
                maxWidth: '600px'
            }}>
                Enter the URL of the Spotify playlist you want to generate new, undiscovered music for
            </p>
            <p style={{
                color: '#b3b3b3',
                fontSize: '0.9rem',
                marginBottom: '24px',
                maxWidth: '600px'
            }}>
                Stuck? Right click your playlist {'>'} Share {'>'} Copy link to playlist
            </p>

            <TextField
                sx={{
                    width: '100%',
                    maxWidth: '800px',
                    mb: 3,
                    '& .MuiOutlinedInput-root': {
                        color: '#fff',
                        backgroundColor: '#282828',
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1DB954',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1DB954',
                        }
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#404040'
                    },
                    '& .MuiInputLabel-root': {
                        color: '#b3b3b3',
                        '&.Mui-focused': {
                            color: '#1DB954'
                        }
                    }
                }}
                label="Playlist URL"
                variant="outlined"
                value={playlistUrl}
                onChange={(event) => setPlaylistUrl(event.target.value)}
            />

            <TextField
                sx={{
                    width: '100%',
                    maxWidth: '800px',
                    mb: 3,
                    '& .MuiOutlinedInput-root': {
                        color: '#fff',
                        backgroundColor: '#282828',
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1DB954',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1DB954',
                        }
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#404040'
                    },
                    '& .MuiInputLabel-root': {
                        color: '#b3b3b3',
                        '&.Mui-focused': {
                            color: '#1DB954'
                        }
                    }
                }}
                label="Number of songs to generate"
                variant="outlined"
                value={limit}
                type="number"
                onChange={(event) => setLimit(parseInt(event.target.value))}
            />

            <div style={{
                marginBottom: '24px',
                display: 'flex',
                flexDirection: 'row',
                gap: '12px',
                flexWrap: 'wrap',
                justifyContent: 'center',
                width: '100%',
                maxWidth: '800px'
            }}>
                {isLoading ? (
                    <CircularProgress sx={{ color: '#1DB954' }} />
                ) : (
                    <>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#1DB954',
                                '&:hover': {
                                    backgroundColor: '#1ed760'
                                },
                                borderRadius: '20px',
                                padding: '10px 24px',
                                textTransform: 'none',
                                fontSize: '1rem'
                            }}
                            onClick={() => createNewAndSimilarPlaylist(playlistUrl, limit)}
                        >
                            Generate Playlist
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                color: '#fff',
                                borderColor: '#fff',
                                '&:hover': {
                                    borderColor: '#1DB954',
                                    color: '#1DB954'
                                },
                                borderRadius: '20px',
                                padding: '10px 24px',
                                textTransform: 'none',
                                fontSize: '1rem'
                            }}
                            onClick={() => setExampleData()}
                        >
                            Use Example
                        </Button>
                    </>
                )}
            </div>

            {generatedPlaylistUrl && (
                <div style={{
                    width: '90%',
                    maxWidth: '800px',
                    margin: '20px auto',
                    padding: '20px',
                    backgroundColor: '#1a1a1a',
                    borderRadius: '8px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.3)',
                    border: '1px solid #333'
                }}>
                    <h2 style={{
                        color: '#1DB954',
                        marginBottom: '20px',
                        fontSize: '1.5rem'
                    }}>
                        Here's Your Generated Playlist
                    </h2>
                    <Link
                        to={generatedPlaylistUrl}
                        target='_blank'
                        rel='noreferrer'
                        style={{
                            display: 'inline-block',
                            padding: '10px 20px',
                            backgroundColor: '#1DB954',
                            color: 'white',
                            borderRadius: '20px',
                            textDecoration: 'none',
                            marginBottom: '20px',
                            fontSize: '0.9rem',
                            transition: 'background-color 0.2s',
                            ':hover': {
                                backgroundColor: '#1ed760'
                            }
                        }}
                    >
                        Open in Spotify
                    </Link>
                </div>
            )}
        </div>
    );
};

export default Spotify;