import { useRouteError } from 'react-router-dom';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ErrorPage: React.FC = () => {
    const error: any = useRouteError();
    const navigate = useNavigate();

    console.error(error);

    const handleGoHome = () => {
        navigate('/');
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            padding: '20px',
            textAlign: 'center'
        }}>
            {/* Simple ASCII art face */}
            <div style={{
                fontFamily: 'monospace',
                fontSize: '60px',
                lineHeight: '1',
                marginBottom: '20px'
            }}>
                {'>_<'}
            </div>

            <h1 style={{
                fontSize: '36px',
                fontWeight: 'bold',
                marginBottom: '10px',
                color: '#FF6B6B'
            }}>
                Whoops!
            </h1>

            <p style={{
                fontSize: '20px',
                lineHeight: '1.5',
                marginBottom: '20px'
            }}>
                Looks like we've hit a little bump in the road.
            </p>

            <p style={{
                fontSize: '18px',
                marginBottom: '30px',
                maxWidth: '500px'
            }}>
                {error?.statusText || error?.message || 'Something unexpected happened. But don\'t worry, it happens to the best of us!'}
            </p>

            <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleGoHome}
                sx={{
                    borderRadius: '25px',
                    padding: '10px 24px',
                    fontWeight: 'bold',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                }}
            >
                Take Me Home
            </Button>
        </div>
    );
};

export default ErrorPage;
