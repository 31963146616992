import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Link from '../../components/Link';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { styled } from '@mui/material/styles';

interface Product {
    primary: string;
    secondary: string;
    to: string;
    twitterLink?: string;
    priorty?: number;
}

const StyledCardActions = styled(CardActions)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '16px 20px 20px',
    gap: '12px',
    borderTop: '1px solid rgba(255, 255, 255, 0.05)',
}));

const ExploreButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#4A90E2',
    color: '#FFFFFF',
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize: '1rem',
    padding: '10px 0',
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(74, 144, 226, 0.3)',
    transition: 'all 0.3s',
    '&:hover': {
        backgroundColor: '#3A7BC8',
        boxShadow: '0 6px 16px rgba(74, 144, 226, 0.5)',
        transform: 'translateY(-2px)'
    },
    '&:active': {
        transform: 'translateY(1px)',
        boxShadow: '0 2px 8px rgba(74, 144, 226, 0.4)',
    },
    [theme.breakpoints.up('sm')]: {
        flex: '0 0 70%',
    },
}));

const DemoText = styled(Typography)(({ theme }) => ({
    color: '#1DA1F2',
    fontWeight: 'bold',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.3s',
    padding: '6px 10px',
    borderRadius: '6px',
    minWidth: '80px',
    marginRight: '4px',
    '&:hover': {
        color: '#ffffff',
        backgroundColor: 'rgba(29, 161, 242, 0.2)',
    },
    [theme.breakpoints.down('xs')]: {
        flex: '1',
    },
}));

function ProductGrid({ products }: { products: Product[] }) {
    return (
        <Grid container spacing={3} sx={{
            opacity: 1,
            animation: 'fadeIn 0.8s ease-in-out',
            '@keyframes fadeIn': {
                '0%': { opacity: 0 },
                '100%': { opacity: 1 }
            }
        }}>
            {products.map((product, index) => (
                <Grid item xs={12} sm={6} md={4} key={index} sx={{
                    animation: `fadeInUp ${0.3 + index * 0.1}s ease-out`,
                    '@keyframes fadeInUp': {
                        '0%': { opacity: 0, transform: 'translateY(20px)' },
                        '100%': { opacity: 1, transform: 'translateY(0)' }
                    }
                }}>
                    <Card
                        sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: 'rgba(17, 17, 17, 0.8)',
                            borderRadius: '16px',
                            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                            transition: 'all 0.4s ease-in-out',
                            border: '1px solid rgba(255, 255, 255, 0.05)',
                            overflow: 'hidden',
                            position: 'relative',
                            '&:before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '4px',
                                background: 'linear-gradient(90deg, #4A90E2, #9B59B6)',
                                opacity: 0,
                                transition: 'opacity 0.3s ease-in-out'
                            },
                            '&:hover': {
                                transform: 'translateY(-8px)',
                                boxShadow: '0 16px 32px rgba(0, 0, 0, 0.3)',
                                '&:before': {
                                    opacity: 1
                                }
                            },
                        }}
                    >
                        <CardContent sx={{ flexGrow: 1, padding: '28px 24px' }}>
                            <Typography variant='h5' component='div' gutterBottom sx={{
                                color: '#ffffff',
                                fontWeight: 'bold',
                                fontSize: '1.4rem',
                                mb: 2
                            }}>
                                {product.primary}
                            </Typography>
                            <Typography variant='body2' sx={{
                                color: '#cccccc',
                                marginBottom: '16px',
                                fontSize: '0.95rem',
                                lineHeight: 1.6
                            }}>
                                {product.secondary}
                            </Typography>
                        </CardContent>
                        <StyledCardActions>
                            <Link to={product.to} style={{
                                textDecoration: 'none',
                                flex: product.twitterLink ? '0 0 70%' : '1'
                            }}>
                                <ExploreButton variant='contained' fullWidth>
                                    Explore
                                </ExploreButton>
                            </Link>
                            {product.twitterLink && (
                                <DemoText
                                    onClick={() => window.open(product.twitterLink, '_blank')}
                                    variant='body2'
                                >
                                    <PlayCircleOutlineIcon sx={{ mr: 0.5 }} />
                                    Demo
                                </DemoText>
                            )}
                        </StyledCardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}

export default ProductGrid;