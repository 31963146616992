import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import FeedbackIcon from '@mui/icons-material/Feedback';
import EmailIcon from '@mui/icons-material/Email';
import CommentIcon from '@mui/icons-material/Comment';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import HomeIcon from '@mui/icons-material/Home';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Link from '../Link';
import { useUser, useNotification } from '../../stores/actions/StoreActions';
import Client_ from '../../api/Client';

const pages = [
    // { 'name': 'SaarimGPT', 'url': '/sr-ai' },
    // { 'name': 'Product', 'url': '/product' },
    // { 'name': 'Pricing', 'url': '/pricing' },
    { 'name': 'About', 'url': 'https://saarim.me' },
    { 'name': 'Contact', 'url': 'mailto:saarim@berkeley.edu' },
    { 'name': 'Support Me', 'url': 'https://ko-fi.com/saarim'}
    // { 'name': 'Report a Bug', 'url': 'https://forms.gle/779h2ZVnT6KSf6ao9' }
]

const loggedOutSettings = [
    { 'name': 'Sign Up', 'url': '/register' },
    { 'name': 'Log In', 'url': '/login' },
]

function Header() {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [feedbackOpen, setFeedbackOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [feedback, setFeedback] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { addNotificationError, addNotificationSuccess } = useNotification();
    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const theme = useTheme();

    // Update email if user changes
    useEffect(() => {
        if (user?.email) {
            setEmail(user.email);
        }
    }, [user]);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleOpenUserMenu = () => {
        if (user?.username) {
            navigate('/profile');
        } else {
            navigate('/login');
        }
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const navigateUrl = (url: string | undefined) => {
        if (typeof url === 'string') {
            if (url.startsWith('/')) {
                navigate(url);
            } else {
                window.open(url);
            }
        } else {
            console.error('Invalid URL:', url);
        }
    }

    const handleOpenFeedback = () => {
        setFeedbackOpen(true);
    };

    const handleCloseFeedback = () => {
        setFeedbackOpen(false);
    };

    const handleSubmitFeedback = () => {
        if (!feedback) {
            addNotificationError('Feedback is required');
            return;
        }
        let url = window.location.href;
        let feedbackWithUrl = feedback + '\n\nURL: ' + url;

        setIsSubmitting(true);
        Client_.client_().leaveFeedback(feedbackWithUrl, email).then(({ err, res }) => {
            setIsSubmitting(false);
            if (err || !res?.success) {
                addNotificationError(err || 'There was an error submitting your feedback. Please try again.');
                return;
            } else {
                addNotificationSuccess('Thank you for your feedback!');
                handleCloseFeedback();
                setFeedback('');
            }
        });
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
                        <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <IconButton
                            color="inherit"
                            aria-label="home"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                            }}
                        >
                            <HomeIcon />
                        </IconButton>
                        </Link>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <IconButton
                                onClick={() => navigate('/')}
                                color="inherit"
                                aria-label="home"
                                sx={{ ml: 1 }}
                            >
                                <HomeIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page.name} onClick={() => navigateUrl(page.url)}>
                                        <Typography textAlign="center">{page.name}</Typography>
                                    </MenuItem>
                                ))}
                                <MenuItem onClick={handleOpenFeedback}>
                                    <Typography textAlign="center">Report an Issue</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                        <Box sx={{
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            display: { xs: 'none', md: 'none' }
                        }}>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page) => (
                                <Button
                                    key={page.name}
                                    onClick={() => navigateUrl(page.url)}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    {page.name}
                                </Button>
                            ))}
                            <Button
                                onClick={handleOpenFeedback}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Report an Issue
                            </Button>
                        </Box>

                        <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
                            {/* Feedback icon for mobile */}
                            <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 2 }}>
                                <Tooltip title="Report an Issue">
                                    <IconButton onClick={handleOpenFeedback} color="inherit" size="small">
                                        <FeedbackIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Box>

                            <Tooltip title={user?.username ? 'Profile' : 'Login'}>
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <AccountCircle />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>

            {/* Feedback Dialog */}
            <Dialog
                open={feedbackOpen}
                onClose={handleCloseFeedback}
                fullScreen={false}
                maxWidth="xs"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: 3,
                        boxShadow: '0 8px 32px rgba(0,0,0,0.2)',
                        m: { xs: 2, sm: 4 },
                        maxWidth: '400px',
                        overflow: 'hidden',
                        bgcolor: '#1e1e1e',
                    }
                }}
            >
                <Box sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <Typography variant="h6" component="div" sx={{
                            fontSize: '1rem',
                            fontWeight: 'medium',
                            color: '#fff'
                        }}>
                            Report an issue
                        </Typography>
                        <IconButton
                            edge="end"
                            onClick={handleCloseFeedback}
                            aria-label="close"
                            size="small"
                            sx={{ color: 'rgba(255,255,255,0.7)' }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Box>

                    <Paper
                        elevation={0}
                        sx={{
                            p: 2,
                            backgroundColor: '#252525',
                            borderRadius: 2,
                            mb: 1
                        }}
                    >
                        <TextField
                            fullWidth
                            multiline
                            placeholder="I noticed that..."
                            variant="standard"
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            sx={{
                                '& .MuiInputBase-input': {
                                    color: '#fff',
                                    fontSize: '0.9rem',
                                },
                                '& .MuiInputBase-input::placeholder': {
                                    color: 'rgba(255,255,255,0.5)',
                                    opacity: 1,
                                }
                            }}
                        />
                    </Paper>

                    <TextField
                        fullWidth
                        placeholder="Your email (optional)"
                        variant="outlined"
                        size="small"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                            mb: 1,
                            '& .MuiOutlinedInput-root': {
                                borderRadius: 1.5,
                                fontSize: '0.9rem',
                                borderColor: 'rgba(255,255,255,0.2)',
                                '& fieldset': {
                                    borderColor: 'rgba(255,255,255,0.2)',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'rgba(255,255,255,0.3)',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#4dabf5',
                                },
                            },
                            '& .MuiInputBase-input': {
                                color: '#fff',
                            },
                            '& .MuiInputBase-input::placeholder': {
                                color: 'rgba(255,255,255,0.5)',
                                opacity: 1,
                            }
                        }}
                    />

                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleSubmitFeedback}
                        disabled={isSubmitting || !feedback}
                        sx={{
                            textTransform: 'none',
                            borderRadius: 1.5,
                            py: 1,
                            boxShadow: 'none',
                            '&:hover': {
                                boxShadow: 'none',
                            },
                            '&.Mui-disabled': {
                                backgroundColor: '#333',
                                color: 'rgba(255,255,255,0.4)',
                            }
                        }}
                        startIcon={isSubmitting ? <CircularProgress size={16} color="inherit" /> : null}
                    >
                        {isSubmitting ? 'Sending...' : 'Send feedback'}
                    </Button>
                </Box>
            </Dialog>
        </Box>
    );
}
export default Header;