import React from "react";
import ProductGrid from "../ProductGrid/ProductGrid";
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import ClearIcon from '@mui/icons-material/Clear';
import FilterChips from '../FilterChips/FilterChips';
import constants from '../../constants/Constants';
import { Typography, Box } from "@mui/material";

interface Product {
    primary: string;
    secondary: string;
    to: string;
    twitterLink?: string;
    priority?: number;
    category: string;
}

export const getSpotifyProducts = () => {
    return [
        {
            primary: '🎵 Find Concerts 🎉',
            secondary: 'Find local concerts & events based on who you have saved in your Spotify playlist.',
            to: '/find-events',
            twitterLink: 'https://x.com/1saarim/status/1711553636759957696',
            priority: 350,
            category: constants.TABS.SPOTIFY
        },
        {
            primary: '🎧 Discover New Music 🔍',
            secondary: 'Discover new, undiscovered music that is not already in your Spotify playlist.',
            to: '/find-new-music',
            twitterLink: 'https://x.com/1saarim/status/1713651311017885949',
            category: constants.TABS.SPOTIFY
        },
        {
            primary: '🎶 Create Set Playlist 📋',
            secondary: 'Paste a plain text tracklist and automatically create a Spotify playlist of the set.',
            to: '/sets',
            twitterLink: 'https://x.com/1saarim/status/1722769873288462502',
            category: constants.TABS.SPOTIFY
        },
        {
            primary: '🔄 Find Duplicates 🚫',
            secondary: 'Detect duplicate songs from your playlist.',
            to: '/find-duplicates',
            category: constants.TABS.SPOTIFY
        },
        {
            primary: '🎸 Count Artist Tracks 📊',
            secondary: 'See how many songs per artist you have added to your playlist.',
            to: '/count-artists',
            twitterLink: 'https://x.com/1saarim/status/1765476145179611401',
            category: constants.TABS.SPOTIFY
        },
        {
            primary: '🎪 Festival Playlist Filter 🎵',
            secondary: 'Filter your master playlist by festival artists to discover songs you already know.',
            to: '/filter-playlist',
            category: constants.TABS.SPOTIFY
        },
        {
            primary: '🎧 Create DJ Sets Playlist 🎵',
            secondary: 'Create a playlist of recent tracks played live by your favorite artists.',
            to: '/djsets',
            category: constants.TABS.SPOTIFY,
            priority: 200
        }
    ];
};

export const getProducts = () => {
    let products: Product[] = [
        {
            primary: '🤖 SaarimGPT 💬',
            secondary: 'Use a ChatGPT-like interface to chat with me and my resume.',
            to: '/sr-ai',
            category: constants.TABS.AI,
            priority: 100
        },
        {
            primary: '📝 AI Resume Builder ✍️',
            secondary: 'Create and edit your LaTeX resume in real time with AI.',
            to: '/resume',
            twitterLink: 'https://x.com/1saarim/status/1832922543000580404',
            category: constants.TABS.AI,
            priority: 200
        },
        {
            primary: '🐦 AI Twitter User Chat 💬',
            secondary: "Use a ChatGPT-like interface to chat with someone's Twitter persona.",
            to: '/twitter-chat',
            twitterLink: 'https://x.com/1saarim/status/1645154330289541121',
            category: constants.TABS.AI,
        },
        {
            primary: '💡 LLM Prompt Helper 🖋️',
            secondary: 'Generate and improve prompts to give an LLM for a specific task.',
            to: '/prompt-helper',
            twitterLink: 'https://x.com/1saarim/status/1641911677711990785',
            category: constants.TABS.AI
        },
        {
            primary: '📊 Tweet Ranker 🐦',
            secondary: 'Find and sort tweets based on popularity for any number of Twitter handles.',
            to: '/twitter',
            twitterLink: 'https://x.com/1saarim/status/1639124725367709701',
            category: constants.TABS.UTILITIES
        },
        {
            primary: '🗺️ SF Crime Map 🚨',
            secondary: 'Visualize crime data in SF. See how likely a car breakin is for where you parked.',
            to: '/crime-map',
            twitterLink: 'https://x.com/1saarim/status/1653646128888561664',
            category: constants.TABS.UTILITIES,
            priority: 101
        },
        {
            primary: '💰 Bill Splitter 🧮',
            secondary: 'AI-powered receipt parsing for easy bill splitting and expense sharing.',
            to: '/split-bills',
            twitterLink: 'https://x.com/1saarim/status/1647326385936175104',
            category: constants.TABS.UTILITIES,
            priority: 102
        },
        {
            primary: '🎤 Carti Translator 🔄',
            secondary: 'Convert anything to Cartinese.',
            to: '/cartinese',
            twitterLink: 'https://x.com/1saarim/status/1762366818612867571',
            category: constants.TABS.UTILITIES
        }
    ];
    let spotifyProducts = getSpotifyProducts() as Product[];
    products = products.concat(spotifyProducts);

    // Separate products with and without priority
    const productsWithPriority = products.filter(p => p.priority !== undefined);
    const productsWithoutPriority = products.filter(p => p.priority === undefined);

    // Sort products with priority
    productsWithPriority.sort((a, b) => (a.priority || 0) - (b.priority || 0));

    // Combine the arrays, with undefined priority products first
    products = [...productsWithPriority, ...productsWithoutPriority];

    return products;
};

const ProductPage: React.FC = () => {
    const [activeTabs, setActiveTabs] = useState<string[]>([]);
    const products = getProducts();

    const filteredProducts = products.filter(product =>
        activeTabs.length === 0 || activeTabs.includes(product.category)
    );

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 16px',
        }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    mb: 2,
                    p: 1.5,
                    borderRadius: '12px',
                    background: 'rgba(18, 18, 18, 0.5)',
                    backdropFilter: 'blur(8px)',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    width: 'fit-content',
                }}
            >
                <FilterChips
                    activeTabs={activeTabs}
                    setActiveTabs={setActiveTabs}
                    tabs={constants.TABS}
                    tabLabels={constants.TAB_LABELS}
                    variant="default"
                />
            </Box>

            <div style={{ width: '100%', maxWidth: '1200px', textAlign: 'center' }}>
                {filteredProducts.length > 0 ? (
                    <ProductGrid products={filteredProducts} />
                ) : (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '40vh',
                        opacity: 0.7
                    }}>
                        <Typography variant="h5" sx={{ color: '#ffffff', mb: 2 }}>
                            No projects match your filter
                        </Typography>
                        <Typography variant="body1" sx={{ color: '#cccccc' }}>
                            Try selecting different categories or clear your filters
                        </Typography>
                    </Box>
                )}
            </div>
        </div>
    );
};

export default ProductPage;