import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Client_ from '../../api/Client';
import { useNotification } from '../../stores/actions/StoreActions';
import { useLocation } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Chip from '@mui/material/Chip';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';

interface CartinesePurchaseProps { }

const PREMIUM_FEATURES = [
    'Instant translations',
    'Vamp carti and other eras of cartinese',
    'Unlimited translations of any length',
    'Customizable translation parameters',
    'Early access to new features',
];

const PREMIUM_NEGATIVE_FEATURES = [
    'Access to Cartinese translation API',
];


const API_FEATURES = [
    'Access to Cartinese translation API',
    'Instant translations',
    'Unlimited requests',
    'Scalable for high-volume needs',
    'Detailed API documentation',
    'Developer support',
];

const ORIGINAL_PRICE = 9.99;
const MONTHLY_PRICE = 1.99;
const DISCOUNT_PERCENTAGE = 80;
const COUPON_CODE = 'EARLYBIH';
const OFFER_DURATION = 60 * 60 * 24 * 1; // 1 days

const CartinesePurchase: React.FC<CartinesePurchaseProps> = () => {
    const [originalPrice] = useState(ORIGINAL_PRICE);
    const [monthlyPrice] = useState(MONTHLY_PRICE);
    const [discountPercentage] = useState(DISCOUNT_PERCENTAGE);
    const [priceInfos, setPriceInfos] = useState<any>({});
    const [couponCode] = useState(COUPON_CODE);
    const [discountedPrice] = useState(originalPrice * (1 - discountPercentage / 100));
    const [timeLeft, setTimeLeft] = useState(OFFER_DURATION);
    const [isTrialMode, setIsTrialMode] = useState(false);

    let navigate = useNavigate();

    const [promoCode, setPromoCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [purchaseStatus, setPurchaseStatus] = useState<'initial' | 'success' | 'failure'>('initial');
    const user = useSelector((state: any) => state.user);

    const { addNotificationError, addNotificationSuccess } = useNotification();
    const location = useLocation();

    const [activating, setActivating] = useState(false);
    const [activationAttempted, setActivationAttempted] = useState(false);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const success = searchParams.get('success');
        const sessionId = searchParams.get('session_id');
        const trial = searchParams.get('trial');

        if (true ||trial === 'true') {
            setIsTrialMode(true);
        }

        if (success === 'true' && sessionId) {
            localStorage.setItem('cartinese_session_id', sessionId);
            ReactGA.event('purchase', {
                currency: 'USD',
                value: discountedPrice,
                items: [{
                    id: 'cartinese_premium',
                    name: 'Cartinese Premium',
                    category: 'Premium',
                    quantity: 1,
                    price: discountedPrice
                }]
            });
            setPurchaseStatus('success');
        } else if (success === 'false') {
            setPurchaseStatus('failure');
        }
    }, [location, addNotificationSuccess, discountedPrice]);

    useEffect(() => {
        const storedEndTime = localStorage.getItem('offerEndTime');
        const currentTime = Math.floor(Date.now() / 1000);

        if (!storedEndTime) {
            const endTime = currentTime + OFFER_DURATION;
            localStorage.setItem('offerEndTime', endTime.toString());
            setTimeLeft(OFFER_DURATION);
        } else {
            const remainingTime = parseInt(storedEndTime) - currentTime;
            setTimeLeft(remainingTime > 0 ? remainingTime : 0);
        }

        const timer = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(timer);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const formatTime = (time: number) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        Client_.client_().getCartiPriceInfo().then(({ err, res }) => {
            if (err || !res?.success) {
                addNotificationError(err);
                return;
            }
            setPriceInfos(res?.result);
        });
    }, []);

    const handlePurchase = (session_type: string) => {
        ReactGA.event('begin_checkout', {
            currency: 'USD',
            value: timeLeft > 0 ? discountedPrice : originalPrice,
            items: [{
                id: 'cartinese_premium',
                name: 'Cartinese Premium',
                category: 'Premium',
                quantity: 1,
                price: timeLeft > 0 ? discountedPrice : originalPrice
            }]
        });
        setIsLoading(true);

        Client_.client_().createCartineseCheckoutSession(session_type, isTrialMode).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err || 'Failed to create checkout session');
                ReactGA.event('exception', {
                    description: 'Failed to create checkout session' + (err ? ': ' + err : ''),
                    fatal: true
                });
                return;
            }
            window.location.href = res.result.url;
        });
    };

    useEffect(() => {
        if (purchaseStatus === 'success' && !activationAttempted) {
            const activateSubscription = async () => {
                setActivating(true);
                setActivationAttempted(true);
                const sessionId = localStorage.getItem('cartinese_session_id');
                if (!sessionId) {
                    addNotificationError('Could not activate subscription. Please contact support.');
                    return;
                }

                const { err } = await Client_.client_().activateCartiSubscription(sessionId);
                if (err) {
                    addNotificationError(err);
                    if (err.toLowerCase().includes('canceled')) {
                        localStorage.removeItem('cartinese_session_id');
                    }
                } else {
                    localStorage.removeItem('cartinese_session_id');
                    addNotificationSuccess(isTrialMode
                        ? 'Your free trial has been activated! Enjoy unlimited translations for 7 days.'
                        : 'Successfully activated your subscription!');
                    navigate('/cartinese');
                }
                setActivating(false);
            };

            activateSubscription();
        }
    }, [purchaseStatus, addNotificationError, addNotificationSuccess, navigate, isTrialMode, activationAttempted]);

    if (purchaseStatus === 'success') {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '60vh',
                    p: 3,
                    maxWidth: 600,
                    margin: 'auto',
                    textAlign: 'center'
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        p: 4,
                        borderRadius: 3,
                        width: '100%',
                        background: 'linear-gradient(to bottom, #1a1a1a, #121212)',
                        border: '1px solid rgba(255, 255, 255, 0.1)'
                    }}
                >
                    <Typography
                        variant='h4'
                        gutterBottom
                        sx={{
                            fontWeight: 'bold',
                            mb: 3,
                            background: 'linear-gradient(45deg, #9c27b0 30%, #673ab7 90%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        {activating ? '🚀 Setting Up Your Account' : isTrialMode ? '✨ Your Free Trial Has Started!' : '✨ Welcome to Vamp Nation!'}
                    </Typography>

                    <Typography
                        variant='body1'
                        sx={{
                            color: 'rgba(255, 255, 255, 0.7)',
                            mb: 4
                        }}
                    >
                        {activating
                            ? 'Please wait while we prepare your premium experience...'
                            : isTrialMode
                              ? 'Your 7-day free trial has begun! Enjoy unlimited translations with no restrictions. You won\'t be charged until after your trial ends.'
                              : 'Thank you for joining! We\'re getting everything ready for you.'
                        }
                    </Typography>

                    <CircularProgress
                        sx={{
                            color: '#9c27b0',
                            '& .MuiCircularProgress-circle': {
                                strokeLinecap: 'round'
                            }
                        }}
                    />

                    <Typography
                        variant='body2'
                        sx={{
                            color: 'rgba(255, 255, 255, 0.5)',
                            mt: 4,
                            mb: 2
                        }}
                    >
                        {isTrialMode
                         ? 'You can cancel anytime before your trial ends to avoid being charged.'
                         : 'If you are not automatically redirected, please contact support'}
                    </Typography>

                    <Button
                        variant='outlined'
                        href='mailto:saarimmm@gmail.com'
                        sx={{
                            mt: 1,
                            color: '#9c27b0',
                            borderColor: '#9c27b0',
                            '&:hover': {
                                borderColor: '#673ab7',
                                backgroundColor: 'rgba(156, 39, 176, 0.08)'
                            }
                        }}
                    >
                        Contact Support
                    </Button>
                </Paper>
            </Box>
        );
    }

    if (purchaseStatus === 'failure') {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3, maxWidth: 600, margin: 'auto' }}>
                <Typography variant='h3' gutterBottom sx={{ fontWeight: 'bold', color: 'error.main' }}>
                    Purchase Not Completed
                </Typography>
                <Typography variant='body1' sx={{ textAlign: 'center', mt: 2 }}>
                    We're sorry, but your purchase was not completed. Please try again or contact support if you continue to experience issues.
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4, width: '100%', maxWidth: '300px' }}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            setPurchaseStatus('initial');
                            navigate('/cartinese/purchase');
                        }}
                        sx={{ mb: 2, width: '100%' }}
                    >
                        Try Again
                    </Button>
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => {
                            navigate('/cartinese');
                        }}
                        sx={{ width: '100%' }}
                    >
                        Back to Carti Translator
                    </Button>
                </Box>
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: { xs: 2, md: 3 }, maxWidth: '100%', margin: 'auto' }}>
            {isTrialMode && (
                <Box sx={{
                    width: '100%',
                    maxWidth: '800px',
                    mb: 4,
                    p: 3,
                    borderRadius: 2,
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText',
                    textAlign: 'center'
                }}>
                    <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
                        7-Day Free Trial
                    </Typography>
                    <Typography variant="body1">
                        Try Vamp Nation Premium with no risk! You won't be charged until after your 7-day trial ends.
                        Cancel anytime during the trial period.
                    </Typography>
                </Box>
            )}
            <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={4}>
                    <Paper elevation={6} sx={{ p: { xs: 3, md: 4 }, height: '100%', borderRadius: 3, boxShadow: '0 5px 15px rgba(0,0,0,0.1)', position: 'relative', overflow: 'hidden' }}>
                        <Box>
                            <Typography variant='h5' gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center', mb: 2, color: 'primary.main' }}>
                                Vamp Nation 🦇
                            </Typography>

                            <Box sx={{ mb: 3 }}>
                                <Typography variant='body1' sx={{ fontWeight: 'medium', mb: 1 }}>Premium Features</Typography>
                                {PREMIUM_FEATURES.map((feature, index) => (
                                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <CheckCircleIcon sx={{ color: '#1DB954', mr: 1, fontSize: '1rem' }} />
                                        <Typography variant='body2'>{feature}</Typography>
                                    </Box>
                                ))}
                                {false && PREMIUM_NEGATIVE_FEATURES.map((feature, index) => (
                                    <Box key={`negative-${index}`} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <CloseIcon sx={{ color: '#FF0000', mr: 1, fontSize: '1rem' }} />
                                        <Typography variant='body2'>{feature}</Typography>
                                    </Box>
                                ))}
                            </Box>

                            <Box sx={{ textAlign: 'center', mb: 3 }}>
                                <Typography variant='h5' color='primary' sx={{ fontWeight: 'bold', mb: 1 }}>
                                    ${priceInfos?.SUBSCRIPTION?.amount}/month
                                </Typography>
                                {isTrialMode && (
                                    <Typography variant='subtitle1' sx={{ color: 'success.main', fontWeight: 'bold' }}>
                                        First 7 days FREE
                                    </Typography>
                                )}
                            </Box>

                            <Button
                                variant='contained'
                                color='secondary'
                                fullWidth
                                onClick={() => handlePurchase('SUBSCRIPTION')}
                                disabled={isLoading}
                                sx={{
                                    py: 1.5,
                                    fontSize: '1.1rem',
                                    fontWeight: 'bold',
                                    borderRadius: 2,
                                    mb: 2,
                                    background: 'linear-gradient(45deg, #FF1744 30%, #FF4081 90%)',
                                    '&:hover': {
                                        background: 'linear-gradient(45deg, #D50000 30%, #FF1744 90%)',
                                    }
                                }}
                            >
                                {isLoading ? <CircularProgress size={24} color='inherit' /> :
                                 isTrialMode ? 'START FREE TRIAL 🔓' : 'JOIN THE VAMPS 🧛‍♂️'}
                            </Button>

                            <Typography variant='caption' color='text.secondary' sx={{ display: 'block', textAlign: 'center', mb: 1 }}>
                                {isTrialMode ? 'No charge until after 7 days • Cancel anytime' : '30-Day Money-Back Guarantee • Secure Payment'}
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>
                {/* <Grid item xs={12} md={4}>
                    <Paper elevation={6} sx={{ p: { xs: 3, md: 4 }, height: '100%', borderRadius: 3, boxShadow: '0 5px 15px rgba(0,0,0,0.1)', position: 'relative', overflow: 'hidden' }}>
                        <Box>
                            <Typography variant='h5' gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center', mb: 2, color: 'primary.main' }}>
                                King Vamp API 👑
                            </Typography>

                            <Box sx={{ mb: 3 }}>
                                <Typography variant='body1' sx={{ fontWeight: 'medium', mb: 1 }}>
                                    Get API access
                                </Typography>
                                {API_FEATURES.map((feature, index) => (
                                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <CheckCircleIcon sx={{ color: '#1DB954', mr: 1, fontSize: '1rem' }} />
                                        <Typography variant='body2'>{feature}</Typography>
                                    </Box>
                                ))}
                            </Box>

                            <Box sx={{ textAlign: 'center', mb: 3 }}>
                                <Typography variant='h5' color='primary' sx={{ fontWeight: 'bold', mb: 1 }}>
                                    ${priceInfos?.API_SUBSCRIPTION?.amount}/month
                                </Typography>
                            </Box>

                            <Button
                                variant='contained'
                                color='secondary'
                                fullWidth
                                onClick={() => handlePurchase('API_SUBSCRIPTION')}
                                sx={{
                                    py: 1.5,
                                    fontSize: '1.1rem',
                                    fontWeight: 'bold',
                                    borderRadius: 2,
                                    mb: 2,
                                    background: 'linear-gradient(45deg, #6200EA 30%, #651FFF 90%)',
                                    '&:hover': {
                                        background: 'linear-gradient(45deg, #5502D0 30%, #5C1BE6 90%)',
                                    }
                                }}
                            >
                                UNLOCK KING VAMP POWERS 👑
                            </Button>

                            <Typography variant='caption' color='text.secondary' sx={{ display: 'block', textAlign: 'center', mb: 1 }}>
                                30-Day Money-Back Guarantee • Secure Payment
                            </Typography>
                        </Box>
                    </Paper>
                </Grid> */}
                <Grid item xs={12} md={4}>
                    <Paper elevation={6} sx={{ p: { xs: 3, md: 4 }, height: '100%', borderRadius: 3, boxShadow: '0 5px 15px rgba(0,0,0,0.1)', position: 'relative', overflow: 'hidden' }}>
                        <Box>
                            <Typography variant='h5' gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center', mb: 2, color: 'primary.main' }}>
                                Broke Boi Bliss 😦
                            </Typography>

                            <Box sx={{ mb: 3 }}>
                                <Typography variant='body1' sx={{ fontWeight: 'medium', mb: 1 }}>Limited Features & Rate Limits</Typography>
                                {[...PREMIUM_FEATURES, ...PREMIUM_NEGATIVE_FEATURES].map((feature, index) => (
                                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <CloseIcon sx={{ color: '#FF0000', mr: 1, fontSize: '1rem' }} />
                                        <Typography variant='body2'>{feature}</Typography>
                                    </Box>
                                ))}
                            </Box>

                            <Box sx={{ textAlign: 'center', mb: 3 }}>
                                <Typography variant='h5' color='primary' sx={{ fontWeight: 'bold', mb: 1 }}>
                                    Free Forever
                                </Typography>
                            </Box>

                            <Button
                                variant='contained'
                                color='secondary'
                                fullWidth
                                onClick={() => navigate('/cartinese')}
                                disabled={isLoading}
                                sx={{
                                    py: 1.5,
                                    fontSize: '1.1rem',
                                    fontWeight: 'bold',
                                    borderRadius: 2,
                                    mb: 2,
                                    background: 'linear-gradient(45deg, #424242 30%, #616161 90%)',
                                    '&:hover': {
                                        background: 'linear-gradient(45deg, #303030 30%, #424242 90%)',
                                    }
                                }}
                            >
                                {isLoading ? <CircularProgress size={24} color='inherit' /> : 'START FREE 😔'}
                            </Button>

                            <Typography variant='caption' color='text.secondary' sx={{ display: 'block', textAlign: 'center', mb: 1 }}>
                                30-Day Money-Back Guarantee • Secure Payment
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CartinesePurchase;