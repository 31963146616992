import Fab from '@mui/material/Fab';
// import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
// import { Link as RouterLink } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useLocation } from "react-router-dom";
import Constants from '../../constants/Constants';
import { useUser } from '../../stores/actions/StoreActions';
import Link from '../Link';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import { useNotification } from '../../stores/actions/StoreActions';
import { supabase } from '../../config/supabase'; // Import Supabase client

import './Register.css';

interface RegisterProps { }

const Register: React.FC<RegisterProps> = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const user = useSelector((state: any) => state.user);
    const { registerUser } = useUser();
    const { addNotificationSuccess, addNotificationError } = useNotification();
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [termsAgreed, setTermsAgreed] = useState(false);
    const [privacyAgreed, setPrivacyAgreed] = useState(false);
    const [showTermsError, setShowTermsError] = useState(false);
    const [showPrivacyError, setShowPrivacyError] = useState(false);

    // Extract redirect path from location state if it exists
    const redirectPath = location.state?.returnTo || null;

    // Store returnTo path in localStorage for OAuth flows
    useEffect(() => {
        if (redirectPath) {
            localStorage.setItem('redirect_after_login', redirectPath);
        }
    }, [redirectPath]);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = () => {
        if (!termsAgreed || !privacyAgreed) {
            setShowTermsError(!termsAgreed);
            setShowPrivacyError(!privacyAgreed);
            return;
        }
        registerUser(username, email, password, firstName);
    };

    const handleGoogleSignUp = async () => {
        if (!termsAgreed || !privacyAgreed) {
            setShowTermsError(!termsAgreed);
            setShowPrivacyError(!privacyAgreed);
            return;
        }

        try {
            // Store the flow type in localStorage
            localStorage.setItem('oauth_flow_type', 'signup');

            // Get the current URL base dynamically
            const baseUrl = window.location.origin;
            const redirectUrl = `${baseUrl}/login`;

            // Use Supabase's signInWithOAuth method for sign up
            const { data, error } = await supabase.auth.signInWithOAuth({
                provider: 'google',
                options: {
                    redirectTo: redirectUrl,
                }
            });

            if (error) {
                addNotificationError(error.message);
            }
        } catch (error) {
            addNotificationError('Failed to initialize Google sign-up');
        }
    };

    useEffect(() => {
        if (user?.username) {
            const storedRedirect = localStorage.getItem('redirect_after_login');
            if (storedRedirect) {
                navigate(storedRedirect);
            } else if (!redirectPath) {
                navigate('/login');
            }
        }
    }, [navigate, user, redirectPath]);


    return (
        <Box
            className='register'
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                maxWidth: '400px',
                margin: 'auto',
                padding: { xs: 2, sm: 3 },
            }}
        >
            <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                Sign up for free
            </Typography>

            <Button
                variant="outlined"
                fullWidth
                startIcon={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                            <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z" />
                            <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z" />
                            <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z" />
                            <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z" />
                        </svg>
                    </Box>
                }
                onClick={handleGoogleSignUp}
                sx={{
                    mb: 2,
                    mt: 2,
                    // textTransform: 'none',
                    fontWeight: 500
                }}
            >
                Continue with Google
            </Button>

            <Divider sx={{ width: '100%', my: 1 }}>
            </Divider>

            <TextField
                fullWidth
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                margin="normal"
            />

            <TextField
                fullWidth
                id="outlined-basic"
                label="Username"
                variant="outlined"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
                margin="normal"
            />

            <TextField
                fullWidth
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                margin="normal"
                helperText="You will need to verify your email to use the platform."
            />
            <TextField
                fullWidth
                id="outlined-basic"
                label="Password"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                helperText={Constants.passwordRequirements}
                margin="normal"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            <Box sx={{ width: '100%', mt: 2 }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={termsAgreed}
                            onChange={(e) => {
                                setTermsAgreed(e.target.checked);
                                setShowTermsError(false);
                            }}
                        />
                    }
                    label={
                        <Typography variant='body2'>
                            I agree to the <Link to='/terms'>Terms and Conditions of Use</Link>
                        </Typography>
                    }
                />
                {showTermsError && (
                    <Alert severity='error' sx={{ mt: 1 }}>
                        Please agree to the Terms and Conditions to continue
                    </Alert>
                )}

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={privacyAgreed}
                            onChange={(e) => {
                                setPrivacyAgreed(e.target.checked);
                                setShowPrivacyError(false);
                            }}
                        />
                    }
                    label={
                        <Typography variant='body2'>
                            I agree to the collection and processing of my personal data as described in the <Link to='/privacy'>Privacy Policy</Link>
                        </Typography>
                    }
                />
                {showPrivacyError && (
                    <Alert severity='error' sx={{ mt: 1 }}>
                        Please agree to the Privacy Policy to continue
                    </Alert>
                )}
            </Box>
            {user?.isLoading ? (
                <CircularProgress sx={{ mt: 2 }} />
            ) : (
                <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    onClick={handleSubmit}
                    sx={{ mt: 2 }}
                >
                    Sign Up
                </Button>
            )}

            <Typography variant="body2" sx={{ mt: 2 }}>
                Have an account? <Link to="/login">Log in</Link>
            </Typography>
        </Box>
    );
};

export default Register;