import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Client_ from '../../api/Client';
import { useNotification } from '../../stores/actions/StoreActions';
import Link from '../Link';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface SpotifyProps { }


const exampleTracklist = `0:09 ID - ID
1:18 Chocolate Puma ft. Kris Kiss - Frenzy
4:34 ID - ID
7:32 Dombresky - Bubblin
9:48  Tchami - Born Again
12:25 DLMT & Paraleven - One Drink
12:30 Belcastro - Pressure
13:51 Öwnboss & Sevek - Move Your Body
14:39 John Summit - La Danza
17:53 DJ Snake & Tchami & Malaa & MERCER - Made In France
18:50 Drake ft. Future & Young Thug - Way 2 Sexy (Valentino Khan Remix)
20:53 Tchami & Frents - ID
23:53 Tchami & AC Slater ft. Kate Wild - Only One
26:30 Tchami - Siaw
28:00 ID - ID
30:15 DMNDS & Fallen Roses & Lujavo ft. Lunis - Calabria (Shift K3Y Edit)
33:30 Tchami ft. Gunna - Praise
36:50 Dog Blood vs. Rob Base & DJ E-Z Rock vs. Tchami & Marshall Jefferson - Turn Off The Light vs. It Takes Two vs. Move Your Body (Tchami Mashup)
38:27 DJ Snake & AlunaGeorge - You Know You Like It (Tchami Remix)
40:15 MARTEN HØRGER & BRANDON - Let's Try It
43:00 Tchami ft. Stacy Barthe - After Life (Habstrakt Remix)
43:31 Michael Sparks - Tell Me
46:30 Curbi & Mike Cervello - Deja Vu
47:28 Wax Motif & longstoryshort - On The Low
49:44 Flume & MAY-A - Say Nothing (Tchami
Remix)
52:00 Sonny Fodera & Biscits - Perfect
55:29 Tchami - Adieu vs. Adieu Pt. II (Tchami Outro Mashup)`

const examplePlaylistName = 'Tchami - Ultra Music Festival Miami 2022'
const example1001tracklistsUrl = 'https://www.1001tracklists.com/tracklist/1jx893b9/tchami-malaa-hard-stage-hard-summer-festival-united-states-2024-08-04.html'

const SetPlaylist: React.FC<SpotifyProps> = () => {
    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { addNotificationError, addNotificationSuccess, addNotification } = useNotification()

    const [playlistName, setPlaylistName] = useState("My Set Tracklist");
    const [tracklist, setTracklist] = useState("");
    const [tracklistUrl, setTracklistUrl] = useState("");
    const [generatedPlaylistUrl, setGeneratedPlaylistUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [needsSpotifyLogin, setNeedsSpotifyLogin] = useState(false);
    const [couldntFindSongs, setCouldntFindSongs] = useState<string[]>([]);
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        document.title = 'Create Set Playlist';
        // Define a function to handle the Spotify authentication check
        const checkSpotifyAuth = async () => {
            const { err, res } = await Client_.client_().validateSpotifyAuth();
            if (err || !res?.success) {
                addNotificationError(err);
            } else {
                if (res?.result?.success) {
                    setNeedsSpotifyLogin(false);
                } else {
                    localStorage.setItem('spotify_auth_redirect', '/set-playlist');
                    addNotificationError('Please login to Spotify first');
                    setNeedsSpotifyLogin(true);
                    navigate('/spotify-auth');
                }
            }
        };
        checkSpotifyAuth();
        const intervalId = setInterval(checkSpotifyAuth, 30000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);


    const createSetPlaylist = (tracklist: string, playlistName: string) => {
        setIsLoading(true);
        setGeneratedPlaylistUrl('');
        Client_.client_().createSetPlaylist(tracklist, playlistName).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setGeneratedPlaylistUrl(res?.result?.playlist_url);
                setCouldntFindSongs(res?.result?.couldnt_find_tracks);
            }
        });
    }

    const createPlaylistFrom1001Tracklists = (url: string) => {
        setIsLoading(true);
        setGeneratedPlaylistUrl('');
        Client_.client_().createPlaylistFrom1001Tracklists(url).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setGeneratedPlaylistUrl(res?.result?.playlist_url);
                setCouldntFindSongs(res?.result?.couldnt_find_tracks);
            }
        });
    }

    const setExampleData = () => {
        setTracklist(exampleTracklist);
        setPlaylistName(examplePlaylistName);
        createSetPlaylist(exampleTracklist, examplePlaylistName);
    }

    const setExample1001TracklistsData = () => {
        setTracklistUrl(example1001tracklistsUrl);
        createPlaylistFrom1001Tracklists(example1001tracklistsUrl);
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
        setCouldntFindSongs([]);
        setGeneratedPlaylistUrl('');
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            padding: '20px',
            maxWidth: '1200px',
            margin: '0 auto',
            color: '#fff'
        }}>
            <h1 style={{
                fontSize: '2rem',
                fontWeight: '700',
                // marginBottom: '24px',
                background: 'linear-gradient(to right, #1DB954, #1ed760)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
            }}>
                Create a Set Playlist
            </h1>

            {needsSpotifyLogin && (
                <p style={{
                    color: '#ff4444',
                    fontWeight: '500',
                    fontSize: '1rem',
                    padding: '16px',
                    backgroundColor: 'rgba(255, 68, 68, 0.1)',
                    borderRadius: '8px',
                    width: '100%',
                    maxWidth: '800px',
                    marginBottom: '20px'
                }}>
                    Please login to Spotify first, click <Link to='/spotify-auth' target='_blank' rel='noreferrer' style={{ color: '#1DB954' }}>here</Link> if not redirected automatically.
                </p>
            )}

            {/* <p style={{
                color: '#b3b3b3',
                fontSize: '1rem',
                marginBottom: '24px',
                maxWidth: '600px'
            }}>
                Create a Spotify playlist from a DJ set
            </p> */}

            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                sx={{
                    marginBottom: '24px',
                    '& .MuiTabs-indicator': {
                        backgroundColor: '#1DB954',
                    },
                    '& .MuiTab-root': {
                        color: '#b3b3b3',
                        '&.Mui-selected': {
                            color: '#1DB954',
                        },
                    },
                }}
            >
                <Tab label="1001Tracklists URL" />
                <Tab label="Paste Tracklist" />
            </Tabs>

            {activeTab === 1 ? (
                <>
                    <TextField
                        sx={{
                            width: '100%',
                            maxWidth: '800px',
                            mb: 3,
                            '& .MuiOutlinedInput-root': {
                                color: '#fff',
                                backgroundColor: '#282828',
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#1DB954',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#1DB954',
                                }
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#404040'
                            },
                            '& .MuiInputLabel-root': {
                                color: '#b3b3b3',
                                '&.Mui-focused': {
                                    color: '#1DB954'
                                }
                            }
                        }}
                        label="Playlist Name"
                        variant="outlined"
                        value={playlistName}
                        onChange={(event) => setPlaylistName(event.target.value)}
                    />

                    <TextField
                        sx={{
                            width: '100%',
                            maxWidth: '800px',
                            mb: 3,
                            '& .MuiOutlinedInput-root': {
                                color: '#fff',
                                backgroundColor: '#282828',
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#1DB954',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#1DB954',
                                }
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#404040'
                            },
                            '& .MuiInputLabel-root': {
                                color: '#b3b3b3',
                                '&.Mui-focused': {
                                    color: '#1DB954'
                                }
                            }
                        }}
                        label="Tracklist"
                        variant="outlined"
                        value={tracklist}
                        multiline
                        rows={10}
                        onChange={(event) => setTracklist(event.target.value)}
                    />

                    <div style={{
                        marginBottom: '24px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '12px',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        width: '100%',
                        maxWidth: '800px'
                    }}>
                        <Button
                            variant="contained"
                            disabled={isLoading}
                            sx={{
                                backgroundColor: isLoading ? '#3b3b3b' : '#1DB954',
                                '&:hover': {
                                    backgroundColor: '#1ed760'
                                },
                                borderRadius: '20px',
                                padding: '10px 24px',
                                textTransform: 'none',
                                fontSize: '1rem',
                                minWidth: '150px'
                            }}
                            onClick={() => createSetPlaylist(tracklist, playlistName)}
                        >
                            {isLoading ? (
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <CircularProgress size={20} sx={{ color: '#fff', mr: 1 }} />
                                    Creating playlist...
                                </Box>
                            ) : (
                                'Create Playlist'
                            )}
                        </Button>
                        <Button
                            variant="outlined"
                            disabled={isLoading}
                            sx={{
                                color: '#fff',
                                borderColor: '#fff',
                                '&:hover': {
                                    borderColor: '#1DB954',
                                    color: '#1DB954'
                                },
                                borderRadius: '20px',
                                padding: '10px 24px',
                                textTransform: 'none',
                                fontSize: '1rem'
                            }}
                            onClick={setExampleData}
                        >
                            Use Example
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <TextField
                        sx={{
                            width: '100%',
                            maxWidth: '800px',
                            mb: 3,
                            '& .MuiOutlinedInput-root': {
                                color: '#fff',
                                backgroundColor: '#282828',
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#1DB954',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#1DB954',
                                }
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#404040'
                            },
                            '& .MuiInputLabel-root': {
                                color: '#b3b3b3',
                                '&.Mui-focused': {
                                    color: '#1DB954'
                                }
                            }
                        }}
                        label="URL"
                        variant="outlined"
                        value={tracklistUrl}
                        placeholder="e.g., https://www.1001tracklists.com/tracklist/1jx893b9 or https://1001.tl/1jx893b9"
                        onChange={(event) => setTracklistUrl(event.target.value.trim())}
                    />

                    <div style={{
                        marginBottom: '24px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '12px',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        width: '100%',
                        maxWidth: '800px'
                    }}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#1DB954',
                                '&:hover': {
                                    backgroundColor: '#1ed760'
                                },
                                borderRadius: '20px',
                                padding: '10px 24px',
                                textTransform: 'none',
                                fontSize: '1rem',
                                minWidth: '230px'
                            }}
                            onClick={() => createPlaylistFrom1001Tracklists(tracklistUrl)}
                            disabled={!tracklistUrl.trim() || isLoading}
                        >
                            {isLoading ? (
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <CircularProgress size={20} sx={{ color: '#fff', mr: 1 }} />
                                    Creating playlist...
                                </Box>
                            ) : (
                                'Create from 1001Tracklists'
                            )}
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                color: '#fff',
                                borderColor: '#fff',
                                '&:hover': {
                                    borderColor: '#1DB954',
                                    color: '#1DB954'
                                },
                                borderRadius: '20px',
                                padding: '10px 24px',
                                textTransform: 'none',
                                fontSize: '1rem'
                            }}
                            onClick={setExample1001TracklistsData}
                            disabled={isLoading}
                        >
                            Use Example
                        </Button>
                    </div>
                </>
            )}

            {generatedPlaylistUrl && (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '90%',
                    maxWidth: '800px',
                    margin: '20px auto',
                    padding: '15px',
                    backgroundColor: '#1a1a1a',
                    borderRadius: '8px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.3)',
                    border: '1px solid #333'
                }}>
                    <Link
                        to={generatedPlaylistUrl}
                        target='_blank'
                        rel='noreferrer'
                        style={{
                            textDecoration: 'none'
                        }}
                    >
                        <Button
                            variant="outlined"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '12px 24px',
                                borderColor: '#1DB954',
                                color: '#1DB954',
                                borderRadius: '20px',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                textTransform: 'none',
                                '&:hover': {
                                    backgroundColor: '#1DB954',
                                    color: '#fff',
                                    borderColor: '#1DB954'
                                }
                            }}
                        >
                            Open Playlist in Spotify
                        </Button>
                    </Link>
                    {activeTab === 1 && (
                        <p style={{ color: '#b3b3b3', fontSize: '0.8rem', margin: '8px 0 0 0' }}>
                            AI can make mistakes. Check the playlist for accuracy.
                        </p>
                    )}
                </div>
            )}

            {couldntFindSongs?.length > 0 && (
                <div style={{
                    width: '90%',
                    maxWidth: '800px',
                    margin: '20px auto',
                    padding: '20px',
                    backgroundColor: '#1a1a1a',
                    borderRadius: '8px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.3)',
                    border: '1px solid #333'
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px',
                        marginBottom: '16px'
                    }}>
                        <span style={{
                            backgroundColor: '#e57373',
                            color: '#fff',
                            borderRadius: '50%',
                            width: '24px',
                            height: '24px',
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '0.9rem',
                            fontWeight: 'bold'
                        }}>
                            {couldntFindSongs.length}
                        </span>
                        <h2 style={{
                            color: '#e57373',
                            fontSize: '1.2rem',
                            margin: 0
                        }}>
                            Tracks Not Found on Spotify
                        </h2>
                    </div>

                    <p style={{
                        color: '#b3b3b3',
                        fontSize: '0.9rem',
                        marginBottom: '16px',
                        fontStyle: 'italic'
                    }}>
                        These tracks could not be found on Spotify and were not included in the playlist:
                    </p>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        maxHeight: '300px',
                        overflowY: 'auto',
                        padding: '4px'
                    }}>
                        {couldntFindSongs.map((track: string, index: number) => (
                            <div key={index} style={{
                                padding: '12px 16px',
                                backgroundColor: '#282828',
                                borderRadius: '4px',
                                color: '#b3b3b3',
                                fontSize: '0.9rem',
                                textAlign: 'left',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '12px',
                                borderLeft: '3px solid #e57373'
                            }}>
                                <span style={{
                                    color: '#e57373',
                                    fontWeight: 'bold',
                                    minWidth: '24px'
                                }}>
                                    {index + 1}.
                                </span>
                                {track}
                            </div>
                        ))}
                    </div>

                    <div style={{
                        marginTop: '16px',
                        textAlign: 'left',
                        color: '#b3b3b3',
                        fontSize: '0.85rem',
                        backgroundColor: 'rgba(229, 115, 115, 0.1)',
                        padding: '12px',
                        borderRadius: '4px'
                    }}>
                        Tracks may be IDs, edits, or listed with different names on Spotify. Try searching manually to add them.
                    </div>
                </div>
            )}
        </div>
    );
};

export default SetPlaylist;
