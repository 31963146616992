import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Client_ from '../../api/Client';
import constants from '../../constants/Constants';
import { useNotification } from '../../stores/actions/StoreActions';
import Link from '../Link';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

interface FindEventsProps { }

const sortOptions = ['date', 'score', 'venue', 'location', ];

const defaultState = 'california';
const defaultCity = 'san francisco';


const FindEvents: React.FC<FindEventsProps> = () => {
    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { addNotificationError, addNotificationSuccess, addNotification } = useNotification()
    const dispatch = useDispatch();

    const [playlistUrl, setPlaylistUrl] = useState('');
    const [state, setState] = useState(defaultState);
    const [city, setCity] = useState(defaultCity);
    const [eventData, setEventData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [needsSpotifyLogin, setNeedsSpotifyLogin] = useState(false);
    const [sortBy, setSortBy] = useState('date');

    useEffect(() => {
        document.title = 'Find Events';
        // Define a function to handle the Spotify authentication check
        const checkSpotifyAuth = async () => {
            const { err, res } = await Client_.client_().validateSpotifyAuth();
            if (err || !res?.success) {
                addNotificationError(err);
            } else {
                if (res?.result?.success) {
                    setNeedsSpotifyLogin(false);
                } else {
                    localStorage.setItem('spotify_auth_redirect', '/find-events');
                    addNotificationError('Please login to Spotify first');
                    setNeedsSpotifyLogin(true);
                    navigate('/spotify-auth');
                }
            }
        };
        checkSpotifyAuth();
        const intervalId = setInterval(checkSpotifyAuth, 30000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);


    const findArtistEvents = (playlistUrl: string, state: string, city: string, sortBy: string) => {
        setIsLoading(true);
        Client_.client_().findArtistEvents(playlistUrl, state, city, sortBy).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
            } else {
                setEventData(res?.result?.events);
            }
        });
    }

    const setExampleData = () => {
        setPlaylistUrl(constants.exampleSpotifyUrl);
        setState(defaultState);
        setCity(defaultCity);
        setSortBy('date');
        findArtistEvents(constants.exampleSpotifyUrl, defaultState, defaultCity, 'date');
    }

    const columns: GridColDef[] = [
        {
            field: 'link',
            headerName: 'Event Link',
            flex: 0.5,
            minWidth: 150,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => (
                <Link to={params.value} target='_blank' rel='noreferrer'>Event Link</Link>
            )
        },
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 200, align: 'left', headerAlign: 'left' },
        { field: 'date', headerName: 'Date', flex: 0.5, minWidth: 150, align: 'left', headerAlign: 'left' },
        { field: 'venue', headerName: 'Venue', flex: 1, minWidth: 200, align: 'left', headerAlign: 'left' },
        { field: 'location', headerName: 'Location', flex: 1, minWidth: 200, align: 'left', headerAlign: 'left' },
        {
            field: 'artists',
            headerName: 'Artists',
            flex: 1.5,
            minWidth: 300,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => (
                <div style={{ width: '100%', textAlign: 'left' }}>
                    {params.value.map((artist: any) => (
                        <div key={artist.id}>
                            <Link to={artist.link} target='_blank' rel='noreferrer'>{artist.name}</Link>
                        </div>
                    ))}
                </div>
            )
        },
        {
            field: 'score',
            headerName: 'Score',
            flex: 0.3,
            minWidth: 100,
            align: 'left',
            headerAlign: 'left',
            renderHeader: (params) => (
                <Tooltip title='Event score is the total count of songs in your playlist from each artist performing at the event'>
                    <span>{params.colDef.headerName}</span>
                </Tooltip>
            )
        },
    ];

    const rows = eventData.map((event: any) => ({
        id: event.id,
        link: event.link,
        name: event.name,
        date: event.date,
        venue: event.venue.name,
        location: event.venue.location,
        artists: event.artistList,
        score: event.score
    }));

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            padding: { xs: '16px', sm: '20px', md: '24px' },
            maxWidth: '2000px',
            margin: '0 auto',
            color: '#fff'
        }}>
            <Box sx={{
                fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' },
                fontWeight: '700',
                mb: { xs: 2, sm: 2.5, md: 3 },
                background: 'linear-gradient(to right, #1DB954, #1ed760)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
            }} component="h1">
                Find Local Concerts and Events
            </Box>

            {needsSpotifyLogin && (
                <Box sx={{
                    color: '#ff4444',
                    fontWeight: '500',
                    fontSize: { xs: '0.875rem', sm: '1rem' },
                    padding: { xs: 1.5, sm: 2 },
                    backgroundColor: 'rgba(255, 68, 68, 0.1)',
                    borderRadius: '8px',
                    width: '100%',
                    maxWidth: '800px',
                    mb: { xs: 2, sm: 2.5 }
                }} component="p">
                    Please login to Spotify first, click <Link to='/spotify-auth' target='_blank' rel='noreferrer' style={{ color: '#1DB954' }}>here</Link> if not redirected automatically.
                </Box>
            )}

            <Box sx={{
                color: '#b3b3b3',
                fontSize: { xs: '0.875rem', sm: '1rem' },
                mb: 1.5,
                maxWidth: '600px',
                px: { xs: 1.5, sm: 0 }
            }} component="p">
                Enter the URL of a Spotify playlist, and see a list of nearby events from the artists in your playlist.
            </Box>

            <Box sx={{
                color: '#b3b3b3',
                fontSize: { xs: '0.875rem', sm: '1rem' },
                mb: { xs: 2.5, sm: 3 },
                maxWidth: '600px',
                px: { xs: 1.5, sm: 0 }
            }} component="p">
                Stuck? Right click your playlist {'>'} Share {'>'} Copy link to playlist
            </Box>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                maxWidth: '800px',
                px: { xs: 1.5, sm: 2.5, md: 0 }
            }}>
                <TextField
                    sx={{
                        width: '100%',
                        mb: 2,
                        '& .MuiOutlinedInput-root': {
                            color: '#fff',
                            backgroundColor: '#282828',
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#1DB954',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#1DB954',
                            }
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#404040'
                        },
                        '& .MuiInputLabel-root': {
                            color: '#b3b3b3',
                            '&.Mui-focused': {
                                color: '#1DB954'
                            }
                        }
                    }}
                    label='Playlist URL'
                    variant='outlined'
                    value={playlistUrl}
                    onChange={(event) => setPlaylistUrl(event.target.value)}
                />

                <TextField
                    sx={{
                        width: '100%',
                        mb: 2,
                        '& .MuiOutlinedInput-root': {
                            color: '#fff',
                            backgroundColor: '#282828',
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#1DB954',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#1DB954',
                            }
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#404040'
                        },
                        '& .MuiInputLabel-root': {
                            color: '#b3b3b3',
                            '&.Mui-focused': {
                                color: '#1DB954'
                            }
                        }
                    }}
                    label='State'
                    variant='outlined'
                    value={state}
                    onChange={(event) => setState(event.target.value)}
                />

                <TextField
                    sx={{
                        width: '100%',
                        mb: 3,
                        '& .MuiOutlinedInput-root': {
                            color: '#fff',
                            backgroundColor: '#282828',
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#1DB954',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#1DB954',
                            }
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#404040'
                        },
                        '& .MuiInputLabel-root': {
                            color: '#b3b3b3',
                            '&.Mui-focused': {
                                color: '#1DB954'
                            }
                        }
                    }}
                    label='City'
                    variant='outlined'
                    value={city}
                    onChange={(event) => setCity(event.target.value)}
                />
            </Box>

            <Box sx={{
                mb: { xs: 2.5, sm: 3 },
                display: 'flex',
                flexDirection: 'row',
                gap: { xs: 1, sm: 1.5 },
                flexWrap: 'wrap',
                justifyContent: 'center',
                width: '100%',
                maxWidth: '800px',
                px: { xs: 1.5, sm: 2.5, md: 0 }
            }}>
                {isLoading ? (
                    <CircularProgress sx={{ color: '#1DB954' }} />
                ) : (
                    <>
                        <Button
                            variant='contained'
                            sx={{
                                backgroundColor: '#1DB954',
                                '&:hover': {
                                    backgroundColor: '#1ed760'
                                },
                                borderRadius: '20px',
                                padding: '10px 24px',
                                textTransform: 'none',
                                fontSize: '1rem'
                            }}
                            onClick={() => findArtistEvents(playlistUrl, state, city, sortBy)}
                        >
                            Find Events
                        </Button>
                        <Button
                            variant='outlined'
                            sx={{
                                color: '#fff',
                                borderColor: '#fff',
                                '&:hover': {
                                    borderColor: '#1DB954',
                                    color: '#1DB954'
                                },
                                borderRadius: '20px',
                                padding: '10px 24px',
                                textTransform: 'none',
                                fontSize: '1rem'
                            }}
                            onClick={() => setExampleData()}
                        >
                            Use Example
                        </Button>
                    </>
                )}
            </Box>

            {eventData.length > 0 && (
                <>
                    <Divider sx={{
                        width: '100%',
                        maxWidth: '800px',
                        mb: { xs: 2, sm: 3 },
                        borderColor: '#404040'
                    }} />
                    <Box sx={{
                        width: '100%',
                        maxWidth: '98vw',
                        margin: '0 auto',
                        px: { xs: 1, sm: 2 },
                        '& .MuiDataGrid-root': {
                            border: 'none',
                            color: '#fff',
                            backgroundColor: '#282828',
                            borderRadius: '8px',
                            '& .MuiDataGrid-cell': {
                                borderColor: '#404040',
                                py: { xs: 1, sm: 1.5 },
                                px: { xs: 1, sm: 2 }
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: '#1a1a1a',
                                borderColor: '#404040',
                                minHeight: { xs: '48px', sm: '56px' }
                            },
                            '& .MuiDataGrid-columnHeader': {
                                color: '#b3b3b3',
                                py: { xs: 1, sm: 1.5 },
                                px: { xs: 1, sm: 2 }
                            },
                            '& .MuiDataGrid-row': {
                                minHeight: { xs: '48px', sm: '52px' },
                                '&:hover': {
                                    backgroundColor: '#333'
                                }
                            },
                            '& .MuiDataGrid-footerContainer': {
                                borderColor: '#404040',
                                color: '#b3b3b3',
                                minHeight: { xs: '48px', sm: '56px' }
                            },
                            '& .MuiTablePagination-root': {
                                color: '#b3b3b3'
                            },
                            '& .MuiIconButton-root': {
                                color: '#b3b3b3'
                            },
                            fontSize: {
                                xs: '0.75rem',
                                sm: '0.875rem',
                                md: '1rem'
                            },
                        }
                    }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[5, 10, 20]}
                            disableRowSelectionOnClick
                            autoHeight
                            getRowHeight={() => 'auto'}
                            sx={{
                                '& .MuiDataGrid-virtualScroller': {
                                    overflowX: 'auto',
                                    '&::-webkit-scrollbar': {
                                        height: '8px'
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: '#404040',
                                        borderRadius: '4px'
                                    }
                                }
                            }}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default FindEvents;