import React, { useState, useEffect, useRef } from 'react';
import { Box, Container, Paper, TextField, Button, CircularProgress, Typography, Divider, Alert, IconButton } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Client_ from '../../api/Client';
import { useNotification } from '../../stores/actions/StoreActions';
import LockIcon from '@mui/icons-material/Lock';
import AssessmentIcon from '@mui/icons-material/Assessment';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate } from 'react-router-dom';

const Dashboard: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { addNotificationError, addNotificationSuccess, addNotification } = useNotification();
  const navigate = useNavigate();
  const signupsFrameRef = useRef<HTMLIFrameElement>(null);
  const cartiFrameRef = useRef<HTMLIFrameElement>(null);
  const cancellationFrameRef = useRef<HTMLIFrameElement>(null);

  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [signupsData, setSignupsData] = useState<string | null>(null);
  const [cartiUsageData, setCartiUsageData] = useState<string | null>(null);
  const [cancellationData, setCancellationData] = useState<any>(null);
  const [cancellationRows, setCancellationRows] = useState<any[]>([]);
  const [signupsLoading, setSignupsLoading] = useState(false);
  const [cartiLoading, setCartiLoading] = useState(false);
  const [cancellationLoading, setCancellationLoading] = useState(false);
  const [signupsError, setSignupsError] = useState<string | null>(null);
  const [cartiError, setCartiError] = useState<string | null>(null);
  const [cancellationError, setCancellationError] = useState<string | null>(null);

  // Load Plotly script if needed
  useEffect(() => {
    if (authenticated) {
      // Check if Plotly is already loaded
      if (!(window as any).Plotly) {
        const script = document.createElement('script');
        // Use a specific version instead of latest
        script.src = 'https://cdn.plot.ly/plotly-2.26.0.min.js';
        script.async = true;
        script.onload = () => {
          fetchDashboardData(); // Refetch data after Plotly is loaded
        };
        document.body.appendChild(script);

        return () => {
          document.body.removeChild(script);
        };
      }
    }
  }, [authenticated]);

  // Add custom CSS for Plotly charts
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      .js-plotly-plot .plotly .main-svg {
        background-color: white !important;
      }
      .js-plotly-plot .plotly .svg-container {
        width: 100% !important;
      }
      .plotly-chart-container {
        background-color: white;
        border-radius: 4px;
        padding: 8px;
      }
      .chart-iframe {
        width: 100%;
        border: none;
        overflow: hidden;
      }
      .signups-iframe {
        height: 450px;
      }
      .carti-iframe {
        height: 900px;
      }
      .cancellation-iframe {
        height: 450px;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  // Function to write HTML content to iframe
  const writeToIframe = (iframe: HTMLIFrameElement | null, content: string) => {
    if (!iframe) return;

    const doc = iframe.contentDocument || iframe.contentWindow?.document;
    if (!doc) return;

    doc.open();
    doc.write(`
      <!DOCTYPE html>
      <html>
        <head>
          <meta charset="utf-8">
          <script src="https://cdn.plot.ly/plotly-2.26.0.min.js"></script>
          <style>
            body {
              margin: 0;
              padding: 0;
              background-color: white;
              overflow-y: hidden;
            }
            .js-plotly-plot {
              width: 100% !important;
            }
            /* Remove extra spacing between charts */
            div[style="margin-bottom: 30px;"] {
              margin-bottom: 10px !important;
            }
          </style>
        </head>
        <body>
          ${content}
          <script>
            // Notify parent when content is fully loaded
            window.onload = function() {
              window.parent.postMessage({ type: 'iframeLoaded', frameId: '${iframe.id}' }, '*');
            };
          </script>
        </body>
      </html>
    `);
    doc.close();
  };

  // Update iframes when data changes
  useEffect(() => {
    if (signupsData) {
      if (!signupsFrameRef.current?.id) {
        if (signupsFrameRef.current) signupsFrameRef.current.id = 'signups-frame';
      }
      writeToIframe(signupsFrameRef.current, signupsData);
    }

    if (cartiUsageData) {
      if (!cartiFrameRef.current?.id) {
        if (cartiFrameRef.current) cartiFrameRef.current.id = 'carti-frame';
      }
      writeToIframe(cartiFrameRef.current, cartiUsageData);
    }

    if (cancellationData) {
      if (!cancellationFrameRef.current?.id) {
        if (cancellationFrameRef.current) cancellationFrameRef.current.id = 'cancellation-frame';
      }
      writeToIframe(cancellationFrameRef.current, cancellationData);
    }
  }, [signupsData, cartiUsageData, cancellationData]);

  // Function to adjust iframe height based on content
  const adjustIframeHeight = (iframe: HTMLIFrameElement | null) => {
    if (!iframe) return;

    try {
      // Add onload event listener to the iframe
      iframe.onload = () => {
        try {
          const doc = iframe.contentDocument || iframe.contentWindow?.document;
          if (!doc || !doc.body) return;

          // Get the height of the content
          const height = doc.body.scrollHeight;
          if (height > 0) {
            iframe.style.height = `${height}px`;
          }
        } catch (e) {
          console.error('Error in iframe onload handler:', e);
        }
      };
    } catch (e) {
      console.error('Error setting up iframe onload:', e);
    }
  };

  // Listen for messages from iframes
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data && event.data.type === 'iframeLoaded') {
        const frameId = event.data.frameId;
        const iframe = document.getElementById(frameId) as HTMLIFrameElement;
        if (iframe) {
          setTimeout(() => {
            try {
              const doc = iframe.contentDocument || iframe.contentWindow?.document;
              if (!doc || !doc.body) return;

              const height = doc.body.scrollHeight;
              if (height > 0) {
                iframe.style.height = `${height}px`;
              }
            } catch (e) {
              console.error('Error adjusting iframe height after load:', e);
            }
          }, 500);
        }
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  // Adjust iframe heights after content is loaded
  useEffect(() => {
    if (signupsData && signupsFrameRef.current) {
      adjustIframeHeight(signupsFrameRef.current);
    }

    if (cartiUsageData && cartiFrameRef.current) {
      adjustIframeHeight(cartiFrameRef.current);
    }

    if (cancellationData && cancellationFrameRef.current) {
      adjustIframeHeight(cancellationFrameRef.current);
    }
  }, [signupsData, cartiUsageData, cancellationData]);

  const validateAccess = async () => {
    if (!password) {
      addNotificationError('Please enter the dashboard password');
      return;
    }

    setLoading(true);

    Client_.client_().validateDashboardAccess(password).then(({ err, res }) => {
      setLoading(false);
      if (err || !res?.success) {
        addNotificationError(err || 'Invalid password');
        setAuthenticated(false);
      } else {
        setAuthenticated(true);
        addNotificationSuccess('Access granted');
        fetchDashboardData();
      }
    }).catch(error => {
      setLoading(false);
      addNotificationError('An error occurred while validating access');
      console.error(error);
    });
  };

  const fetchDashboardData = () => {
    // Reset states
    setSignupsError(null);
    setCartiError(null);
    setCancellationError(null);

    // Fetch signups histogram
    setSignupsLoading(true);
    Client_.client_().getSignupsHistogram().then(({ err, res }) => {
      setSignupsLoading(false);

      if (err || !res?.success) {
        setSignupsError(err || 'No data received from server');

        // Check for auth errors
        if (err?.toLowerCase().includes('log in') || err?.toLowerCase().includes('unauthorized')) {
          addNotification('Please log in to view dashboard data');
          setAuthenticated(false);
          navigate('/login', {
            state: {
              returnTo: '/dashboard',
              message: 'Log in to view dashboard data',
              forceRedirect: true
            }
          });
        } else {
          addNotificationError(err);
        }
      } else if (res?.result?.html) {
        setSignupsData(res.result.html);
      } else {
        setSignupsError('No data received from server');
      }
    }).catch(error => {
      setSignupsLoading(false);
      setSignupsError('Failed to fetch signups data');
      console.error('Signups fetch error:', error);
    });

    // Fetch Carti usage data
    setCartiLoading(true);
    Client_.client_().getCartiUsage().then(({ err, res }) => {
      setCartiLoading(false);

      if (err || !res?.success) {
        setCartiError(err || 'No data received from server');

        // Check for auth errors
        if (err?.toLowerCase().includes('log in') || err?.toLowerCase().includes('unauthorized')) {
          // Already handled in the signups request
        } else {
          addNotificationError(err);
        }
      } else if (res?.result?.html) {
        setCartiUsageData(res.result.html);
      } else {
        setCartiError('No data received from server');
      }
    }).catch(error => {
      setCartiLoading(false);
      setCartiError('Failed to fetch Carti usage data');
      console.error('Carti fetch error:', error);
    });

    // Fetch cancellation data
    // setCancellationLoading(true);
    // Client_.client_().getCancellationData().then(({ err, res }) => {
    //   setCancellationLoading(false);

    //   if (err || !res?.success) {
    //     setCancellationError(err || 'No cancellation data received from server');
    //   } else if (res?.result) {
    //     setCancellationData(res.result.html);
    //     // If we have raw cancellation rows data, set it for the data grid
    //     if (res.result.cancel_rows) {
    //       // Add a unique id to each row for the data grid
    //       const rowsWithIds = res.result.cancel_rows.map((row: any, index: number) => ({
    //         ...row,
    //         id: row.cancel_reason_id || index,
    //         // Format date for display
    //         created_at: row.created_at ? new Date(row.created_at).toLocaleString() : ''
    //       }));
    //       setCancellationRows(rowsWithIds);
    //     }
    //   } else {
    //     setCancellationError('No cancellation data received from server');
    //   }
    // }).catch(error => {
    //   setCancellationLoading(false);
    //   setCancellationError('Failed to fetch cancellation data');
    //   console.error('Cancellation fetch error:', error);
    // });
  };

  const renderAuthForm = () => (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      textAlign: 'center',
      padding: theme.spacing(3)
    }}>
      <LockIcon sx={{ fontSize: 48, color: theme.palette.primary.main, mb: 2 }} />
      <Typography variant="h4" component="h1" gutterBottom>
        Admin Dashboard
      </Typography>
      <Typography variant="body1" color="textSecondary" paragraph>
        Enter the dashboard password to continue
      </Typography>
      <TextField
        sx={{
          width: isMobile ? '100%' : '70%',
          marginBottom: theme.spacing(3)
        }}
        id='dashboard-password'
        label='Password'
        variant='outlined'
        type='password'
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        onKeyPress={(e) => e.key === 'Enter' && validateAccess()}
      />
      {loading ? (
        <CircularProgress sx={{ marginTop: theme.spacing(2) }} />
      ) : (
        <Button
          sx={{
            width: isMobile ? '100%' : '200px',
            marginTop: theme.spacing(2),
            height: '48px'
          }}
          variant='contained'
          color='primary'
          size='large'
          onClick={validateAccess}
        >
          Access Dashboard
        </Button>
      )}
    </Box>
  );

  const renderSignupsSection = () => (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" gutterBottom>User Signups</Typography>
      <Paper elevation={2} sx={{ p: 2 }}>
        {signupsLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
            <CircularProgress />
          </Box>
        ) : signupsError ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            {signupsError}
          </Alert>
        ) : signupsData ? (
          <Box
            className="plotly-chart-container"
            sx={{
              width: '100%',
              minHeight: '450px',
              backgroundColor: 'white',
              borderRadius: 1,
              overflow: 'hidden'
            }}
          >
            <iframe
              ref={signupsFrameRef}
              id="signups-frame"
              className="chart-iframe signups-iframe"
              title="User Signups Chart"
              sandbox="allow-same-origin allow-scripts"
            />
          </Box>
        ) : (
          <Typography variant="body1" color="textSecondary" align="center">
            No signup data available
          </Typography>
        )}
      </Paper>
    </Box>
  );

  const renderCartiSection = () => (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" gutterBottom>API Usage & Throttling</Typography>
      <Paper elevation={2} sx={{ p: 2 }}>
        {cartiLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
            <CircularProgress />
          </Box>
        ) : cartiError ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            {cartiError}
          </Alert>
        ) : cartiUsageData ? (
          <Box
            className="plotly-chart-container"
            sx={{
              width: '100%',
              minHeight: '900px',
              backgroundColor: 'white',
              borderRadius: 1,
              overflow: 'hidden'
            }}
          >
            <iframe
              ref={cartiFrameRef}
              id="carti-frame"
              className="chart-iframe carti-iframe"
              title="API Usage Chart"
              sandbox="allow-same-origin allow-scripts"
            />
          </Box>
        ) : (
          <Typography variant="body1" color="textSecondary" align="center">
            No API usage data available
          </Typography>
        )}
      </Paper>
    </Box>
  );

  const renderCancellationSection = () => {
    // Define columns for the data grid
    const columns: GridColDef[] = [
      { field: 'cancel_reason_id', headerName: 'ID', width: 70 },
      { field: 'profile_id', headerName: 'Profile ID', width: 100 },
      { field: 'service_name', headerName: 'Service', width: 150 },
      { field: 'reason', headerName: 'Reason', width: 400, flex: 1 },
      { field: 'created_at', headerName: 'Date', width: 200 }
    ];

    return (
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>Cancellations</Typography>
        <Paper elevation={2} sx={{ p: 2 }}>
          {cancellationLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
              <CircularProgress />
            </Box>
          ) : cancellationError ? (
            <Alert severity="error" sx={{ mb: 2 }}>
              {cancellationError}
            </Alert>
          ) : (
            <>
              {/* Show the chart using iframe like other sections */}
              {cancellationData && (
                <Box
                  className="plotly-chart-container"
                  sx={{
                    width: '100%',
                    minHeight: '450px',
                    backgroundColor: 'white',
                    borderRadius: 1,
                    overflow: 'hidden',
                    mb: 4
                  }}
                >
                  <iframe
                    ref={cancellationFrameRef}
                    id="cancellation-frame"
                    className="chart-iframe cancellation-iframe"
                    title="Cancellations Chart"
                    sandbox="allow-same-origin allow-scripts"
                  />
                </Box>
              )}

              {/* Then show the data grid with raw cancellation data */}
              {cancellationRows.length > 0 ? (
                <Box sx={{ height: 600, width: '100%' }}>
                  <DataGrid
                    rows={cancellationRows}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                      },
                      sorting: {
                        sortModel: [{ field: 'created_at', sort: 'desc' }],
                      },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                    disableRowSelectionOnClick
                  />
                </Box>
              ) : (
                <Typography variant="body1" color="textSecondary" align="center">
                  No cancellation data available
                </Typography>
              )}
            </>
          )}
        </Paper>
      </Box>
    );
  };

  const renderDashboard = () => (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      padding: theme.spacing(3)
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <AssessmentIcon sx={{ fontSize: 32, color: theme.palette.primary.main, mr: 2 }} />
          <Typography variant="h4" component="h1">
            Analytics Dashboard
          </Typography>
        </Box>
        {!loading && (
          <IconButton
            color="primary"
            onClick={fetchDashboardData}
            aria-label="Refresh dashboard data"
          >
            <RefreshIcon sx={{fontSize: 32, color: theme.palette.primary.main}} />
          </IconButton>
        )}
      </Box>

      <Divider sx={{ mb: 4 }} />

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {renderSignupsSection()}
          {renderCartiSection()}
          {/* {renderCancellationSection()}  */}

          {!signupsData && !cartiUsageData && !cancellationData &&
           !signupsLoading && !cartiLoading && !cancellationLoading &&
           !signupsError && !cartiError && !cancellationError && (
            <Typography variant="body1" color="textSecondary" align="center">
              No data available to display
            </Typography>
          )}
        </>
      )}
    </Box>
  );

  return (
    <Container maxWidth="lg" sx={{
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: theme.spacing(4)
    }}>
      <Paper elevation={3} sx={{
        width: '100%',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        overflow: 'hidden'
      }}>
        {authenticated ? renderDashboard() : renderAuthForm()}
      </Paper>
    </Container>
  );
};

export default Dashboard;
