import React from 'react';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';

interface FilterChipsProps {
  activeTabs: string[];
  setActiveTabs: (tabs: string[]) => void;
  tabs: Record<string, string>;
  tabLabels: Record<string, string>;
  variant?: 'default' | 'compact';
  sx?: SxProps<Theme>;
}

const FilterChips: React.FC<FilterChipsProps> = ({
  activeTabs,
  setActiveTabs,
  tabs,
  tabLabels,
  variant = 'default',
  sx = {}
}) => {
  const handleChipClick = (tab: string) => {
    setActiveTabs(activeTabs.includes(tab)
      ? activeTabs.filter((t: string) => t !== tab)
      : [...activeTabs, tab]
    );
  };

  const clearFilters = () => {
    setActiveTabs([]);
  };

  const styles = {
    default: {
      stack: {
        my: 0.5,
        py: 0.5,
        px: 0.5,
        borderRadius: 2,
        backgroundColor: 'transparent',
        width: 'fit-content'
      },
      chip: {
        borderRadius: '50px',
        px: { xs: 0.75, sm: 1.5 },
        height: { xs: '30px', sm: '36px' },
        fontSize: { xs: '0.8rem', sm: '1rem' },
        fontWeight: 500,
        transition: 'all 0.2s ease-in-out',
      }
    },
    compact: {
      stack: {
        my: 1,
        py: 1,
        px: 1.5,
        borderRadius: 1,
        backgroundColor: 'transparent',
        width: 'fit-content'
      },
      chip: {
        borderRadius: '16px',
        height: '28px',
        fontSize: '0.85rem',
        fontWeight: 400,
        transition: 'all 0.2s ease-in-out',
      }
    }
  };

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', ...sx }}>
      <Stack
        direction="row"
        spacing={{ xs: 0.5, sm: 1 }}
        flexWrap="wrap"
        justifyContent="center"
        sx={{
          ...styles[variant].stack,
          '& .MuiChip-root': {
            margin: { xs: '2px', sm: '4px 6px' },
          },
          width: '100%',
          maxWidth: '100vw',
          display: 'flex',
          justifyContent: 'center',
          px: { xs: 0.5, sm: 1 }
        }}
      >
        {activeTabs.length > 0 && (
          <Chip
            icon={<ClearIcon />}
            onClick={clearFilters}
            sx={{
              ...styles[variant].chip,
              backgroundColor: 'rgba(255, 255, 255, 0.15)',
              color: 'rgba(255, 255, 255, 0.9)',
              padding: 0,
              minWidth: { xs: '28px', sm: '32px' },
              width: { xs: '28px', sm: '32px' },
              height: { xs: '28px', sm: '36px' },
              '& .MuiChip-icon': {
                margin: 'auto',
                padding: 0,
                fontSize: { xs: '16px', sm: '20px' },
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)'
              },
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.25)',
                boxShadow: '0 2px 8px rgba(0,0,0,0.3)',
                transform: 'translateY(-1px)'
              },
            }}
          />
        )}

        {Object.entries(tabs).map(([key, value]) => (
          <Chip
            key={key}
            label={tabLabels[key as keyof typeof tabLabels]}
            onClick={() => handleChipClick(value)}
            sx={{
              ...styles[variant].chip,
              backgroundColor: activeTabs.includes(value)
                ? 'rgba(255, 255, 255, 0.9)'
                : 'rgba(255, 255, 255, 0.08)',
              color: activeTabs.includes(value)
                ? '#000000'
                : 'rgba(255, 255, 255, 0.9)',
              '&:hover': {
                backgroundColor: activeTabs.includes(value)
                  ? 'rgba(255, 255, 255, 0.8)'
                  : 'rgba(255, 255, 255, 0.15)',
                boxShadow: '0 2px 8px rgba(0,0,0,0.3)',
              },
            }}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default FilterChips;
