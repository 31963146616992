import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Client_ from '../../api/Client';
import { useNotification } from '../../stores/actions/StoreActions';
import Link from '../Link';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface CreateArtistsSetsPlaylistProps { }

const exampleArtists = `Tchami
AC Slater`;
const examplePlaylistName = 'Goated DJ Sets';

const CreateArtistsSetsPlaylist: React.FC<CreateArtistsSetsPlaylistProps> = () => {
    let navigate = useNavigate();
    const { addNotificationError } = useNotification();

    const [artists, setArtists] = useState('');
    const [numSets, setNumSets] = useState(2);
    const [playlistName, setPlaylistName] = useState('');
    const [generatedPlaylistUrl, setGeneratedPlaylistUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [needsSpotifyLogin, setNeedsSpotifyLogin] = useState(false);
    const [couldntFindSongs, setCouldntFindSongs] = useState<string[]>([]);
    const [tracklistMetadata, setTracklistMetadata] = useState<{ url: string, name: string, couldnt_find_tracks?: string[], num_missing?: number }[]>([]);
    const [expandedTracklistIds, setExpandedTracklistIds] = useState<number[]>([]);
    const playlistResultRef = useRef<HTMLDivElement>(null);
    const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

    useEffect(() => {
        document.title = 'Create Artist Sets Playlist';

        const checkSpotifyAuth = async () => {
            const { err, res } = await Client_.client_().validateSpotifyAuth();
            if (err || !res?.success) {
                addNotificationError(err);
            } else {
                if (res?.result?.success) {
                    setNeedsSpotifyLogin(false);
                } else {
                    addNotificationError('Please login to Spotify first');
                    setNeedsSpotifyLogin(true);
                    navigate('/spotify-auth');
                }
            }
        };

        checkSpotifyAuth();
        const intervalId = setInterval(checkSpotifyAuth, 30000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        if (generatedPlaylistUrl && playlistResultRef.current) {
            playlistResultRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    }, [generatedPlaylistUrl]);

    const openHelpModal = () => setIsHelpModalOpen(true);
    const closeHelpModal = () => setIsHelpModalOpen(false);

    const createArtistsSetsPlaylist = (artists: string, numSets: number, playlistName: string) => {
        setIsLoading(true);
        setGeneratedPlaylistUrl('');
        setCouldntFindSongs([]);
        setTracklistMetadata([]);
        setExpandedTracklistIds([]);

        Client_.client_().createArtistsRecentSetsPlaylist(artists, numSets, playlistName).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err);
                setIsHelpModalOpen(true);
                return;
            }

            setGeneratedPlaylistUrl(res?.result?.playlist_url);
            setCouldntFindSongs(res?.result?.couldnt_find_tracks || []);
            setTracklistMetadata(res?.result?.tracklist_metadata || []);
        });
    }

    const setExampleData = () => {
        setArtists(exampleArtists);
        setPlaylistName(examplePlaylistName);
        setNumSets(1);
        createArtistsSetsPlaylist(exampleArtists, 1, examplePlaylistName);
    }

    const toggleTracklistExpanded = (index: number) => {
        setExpandedTracklistIds(prev => {
            if (prev.includes(index)) {
                return prev.filter(id => id !== index);
            } else {
                return [...prev, index];
            }
        });
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            padding: '20px',
            maxWidth: '1200px',
            margin: '0 auto',
            color: '#fff'
        }}>
            <h1 style={{
                fontSize: '2rem',
                fontWeight: '700',
                marginBottom: '24px',
                background: 'linear-gradient(to right, #1DB954, #1ed760)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
            }}>
                Create Playlist from Artists' Recent Sets
            </h1>

            {needsSpotifyLogin && (
                <p style={{
                    color: '#ff4444',
                    fontWeight: '500',
                    fontSize: '1rem',
                    padding: '16px',
                    backgroundColor: 'rgba(255, 68, 68, 0.1)',
                    borderRadius: '8px',
                    width: '100%',
                    maxWidth: '800px',
                    marginBottom: '20px'
                }}>
                    Please login to Spotify first, click <Link to='/spotify-auth' target='_blank' rel='noreferrer' style={{ color: '#1DB954' }}>here</Link> if not redirected automatically.
                </p>
            )}

            <p style={{
                color: '#b3b3b3',
                fontSize: '1rem',
                marginBottom: '24px',
                maxWidth: '600px'
            }}>
                Enter artists (one per line) to create a playlist from their recent DJ sets
            </p>

            <div style={{
                display: 'flex',
                width: '100%',
                maxWidth: '800px',
                marginBottom: '24px',
                gap: '16px'
            }}>
                <TextField
                    sx={{
                        width: '75%',
                        '& .MuiOutlinedInput-root': {
                            color: '#fff',
                            backgroundColor: '#282828',
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#1DB954',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#1DB954',
                            }
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#404040'
                        },
                        '& .MuiInputLabel-root': {
                            color: '#b3b3b3',
                            '&.Mui-focused': {
                                color: '#1DB954'
                            }
                        }
                    }}
                    label="Playlist Name"
                    variant="outlined"
                    helperText="Auto-generated if left blank"
                    value={playlistName}
                    onChange={(event) => setPlaylistName(event.target.value)}
                />

                <TextField
                    sx={{
                        width: '25%',
                        '& .MuiOutlinedInput-root': {
                            color: '#fff',
                            backgroundColor: '#282828',
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#1DB954',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#1DB954',
                            }
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#404040'
                        },
                        '& .MuiInputLabel-root': {
                            color: '#b3b3b3',
                            '&.Mui-focused': {
                                color: '#1DB954'
                            }
                        },
                        '& input': {
                            textAlign: 'center'
                        }
                    }}
                    label="Sets per Artist"
                    variant="outlined"
                    type="number"
                    value={numSets}
                    onChange={(event) => setNumSets(parseInt(event.target.value) || 1)}
                    inputProps={{
                        min: 1,
                        max: 10,
                        step: 1
                    }}
                />
            </div>

            <TextField
                sx={{
                    width: '100%',
                    maxWidth: '800px',
                    mb: 3,
                    '& .MuiOutlinedInput-root': {
                        color: '#fff',
                        backgroundColor: '#282828',
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1DB954',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1DB954',
                        }
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#404040'
                    },
                    '& .MuiInputLabel-root': {
                        color: '#b3b3b3',
                        '&.Mui-focused': {
                            color: '#1DB954'
                        }
                    },
                    position: 'relative'
                }}
                label="Artists (one per line)"
                variant="outlined"
                value={artists}
                multiline
                rows={10}
                onChange={(event) => setArtists(event.target.value)}
                InputProps={{
                    endAdornment: (
                        <Button
                            variant="text"
                            size="small"
                            onClick={openHelpModal}
                            sx={{
                                position: 'absolute',
                                bottom: '8px',
                                right: '8px',
                                minWidth: 'auto',
                                padding: '4px 8px',
                                backgroundColor: 'rgba(40, 40, 40, 0.8)',
                                color: '#1DB954',
                                borderRadius: '12px',
                                fontSize: '0.75rem',
                                textTransform: 'none',
                                zIndex: 2,
                                '&:hover': {
                                    backgroundColor: 'rgba(40, 40, 40, 0.9)',
                                    color: '#28ed70'
                                },
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px'
                            }}
                        >
                            <HelpOutlineIcon sx={{ fontSize: '14px' }} />
                            Need help?
                        </Button>
                    )
                }}
            />

            <div style={{
                marginBottom: '24px',
                display: 'flex',
                flexDirection: 'row',
                gap: '12px',
                flexWrap: 'wrap',
                justifyContent: 'center',
                width: '100%',
                maxWidth: '800px'
            }}>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: '#1DB954',
                        '&:hover': {
                            backgroundColor: '#1ed760'
                        },
                        borderRadius: '20px',
                        padding: '10px 24px',
                        textTransform: 'none',
                        fontSize: '1rem',
                        minWidth: '200px',
                        position: 'relative'
                    }}
                    onClick={() => createArtistsSetsPlaylist(artists, numSets, playlistName)}
                    disabled={isLoading}
                >
                    {isLoading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: 'white',
                                position: 'absolute',
                                left: '12px'
                            }}
                        />
                    )}
                    <span style={{ marginLeft: isLoading ? '23px' : '0' }}>
                        {isLoading ? 'Creating playlist...' : 'Create Playlist'}
                    </span>
                </Button>
                <Button
                    variant="outlined"
                    sx={{
                        color: '#fff',
                        borderColor: '#fff',
                        '&:hover': {
                            borderColor: '#1DB954',
                            color: '#1DB954'
                        },
                        borderRadius: '20px',
                        padding: '10px 24px',
                        textTransform: 'none',
                        fontSize: '1rem'
                    }}
                    onClick={setExampleData}
                    disabled={isLoading}
                >
                    Use Example
                </Button>
            </div>

            <Modal
                open={isHelpModalOpen}
                onClose={closeHelpModal}
                aria-labelledby="help-modal-title"
                aria-describedby="help-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90%', sm: '600px' },
                    maxWidth: '600px',
                    bgcolor: '#1a1a1a',
                    border: '1px solid #333',
                    borderRadius: '8px',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.4)',
                    p: 4,
                    outline: 'none',
                    color: '#fff'
                }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography id="help-modal-title" variant="h6" component="h2" sx={{
                            color: '#1DB954',
                            fontWeight: 600,
                            fontSize: '1.2rem'
                        }}>
                            Need help?
                        </Typography>
                        <IconButton
                            onClick={closeHelpModal}
                            sx={{
                                color: '#b3b3b3',
                                '&:hover': { color: '#fff' }
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <Typography id="help-modal-description" sx={{ mt: 2, color: '#b3b3b3', fontSize: '0.9rem' }}>
                        Sometimes 1001tracklists rejects our requests - it happens - if it fails, try again. If issues persist, make sure your artist name is spelled correctly by following these steps:
                    </Typography>

                    <ol style={{
                        margin: '16px 0',
                        paddingLeft: '24px',
                        color: '#b3b3b3',
                        fontSize: '0.9rem',
                        lineHeight: 1.6
                    }}>
                        <li style={{ marginBottom: '8px' }}>Go to <Link to="https://www.1001tracklists.com/" target="_blank" rel="noopener noreferrer" style={{ color: '#1DB954' }}>1001tracklists.com</Link></li>
                        <li style={{ marginBottom: '8px' }}>Search for the DJ/artist you want</li>
                        <li style={{ marginBottom: '8px' }}>Click on their profile page</li>
                        <li style={{ marginBottom: '8px' }}>Look at the URL: 1001tracklists.com/dj/<span style={{ color: '#1DB954', fontWeight: 600 }}>artistname</span>/index.html</li>
                        <li>Use the highlighted part as the artist name in this form</li>
                    </ol>

                    <Box sx={{
                        mt: 3,
                        p: 2,
                        bgcolor: 'rgba(29, 185, 84, 0.1)',
                        borderRadius: '4px',
                        borderLeft: '3px solid #1DB954'
                    }}>
                        <Typography sx={{ fontSize: '0.85rem', color: '#b3b3b3' }}>
                            <strong style={{ color: '#fff' }}>Example:</strong> For "Beltran", his URL is <Link to="https://www.1001tracklists.com/dj/beltranbr/index.html" target="_blank" rel="noopener noreferrer" style={{ color: '#1DB954' }}>1001tracklists.com/dj/<span style={{ color: '#1DB954', fontWeight: 600 }}>beltranbr</span>/index.html</Link> so you should use "<span style={{ color: '#1DB954', fontWeight: 600 }}>beltranbr</span>" as the artist name.
                        </Typography>
                    </Box>

                    <Button
                        variant="contained"
                        onClick={closeHelpModal}
                        sx={{
                            mt: 3,
                            backgroundColor: '#1DB954',
                            '&:hover': { backgroundColor: '#1ed760' },
                            borderRadius: '20px',
                            padding: '8px 16px',
                            textTransform: 'none',
                            fontSize: '0.9rem',
                            display: 'block',
                            margin: '20px auto 0'
                        }}
                    >
                        Got it
                    </Button>
                </Box>
            </Modal>

            {generatedPlaylistUrl && (
                <div
                    ref={playlistResultRef}
                    style={{
                        width: '90%',
                        maxWidth: '800px',
                        margin: '20px auto',
                        padding: '20px',
                        backgroundColor: '#1a1a1a',
                        borderRadius: '8px',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.3)',
                        border: '1px solid #333'
                    }}
                >
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '20px'
                    }}>
                        <Link
                            to={generatedPlaylistUrl}
                            target='_blank'
                            rel='noreferrer'
                            style={{
                                display: 'inline-block',
                                padding: '14px 32px',
                                backgroundColor: '#1DB954',
                                color: 'white',
                                borderRadius: '24px',
                                textDecoration: 'none',
                                fontSize: '1.1rem',
                                fontWeight: 'bold',
                                transition: 'background-color 0.2s, transform 0.1s',
                                boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
                                ':hover': {
                                    backgroundColor: '#1ed760',
                                    transform: 'translateY(-2px)'
                                }
                            }}
                        >
                            Open Playlist in Spotify
                        </Link>
                    </div>

                    {tracklistMetadata.length > 0 && (
                        <>
                            <hr style={{
                                border: 'none',
                                borderTop: '1px solid #333',
                                margin: '20px 0'
                            }} />

                            <h3 style={{
                                color: '#1DB954',
                                fontSize: '1.2rem',
                                marginBottom: '16px',
                                textAlign: 'left'
                            }}>
                                Sets Used in Playlist
                            </h3>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '12px',
                                overflowY: 'auto',
                                // maxHeight: '600px',
                                padding: '4px'
                            }}>
                                {tracklistMetadata.map((tracklist, index) => {
                                    const missingTracksCount = tracklist.num_missing || 0;
                                    const isExpanded = expandedTracklistIds.includes(index);

                                    return (
                                        <div key={index} style={{
                                            backgroundColor: '#282828',
                                            borderRadius: '8px',
                                            border: '1px solid #333',
                                            boxShadow: '0 1px 3px rgba(0,0,0,0.2)'
                                        }}>
                                            <div
                                                style={{
                                                    padding: '16px 20px',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    cursor: missingTracksCount > 0 ? 'pointer' : 'default',
                                                    borderBottom: isExpanded && missingTracksCount > 0 ? '1px solid #333' : 'none'
                                                }}
                                                onClick={() => missingTracksCount > 0 && toggleTracklistExpanded(index)}
                                            >
                                                <div style={{
                                                    textAlign: 'left',
                                                    flex: '1',
                                                    paddingRight: '12px'
                                                }}>
                                                    <div style={{
                                                        fontSize: '1.1rem',
                                                        fontWeight: '500',
                                                        color: '#fff',
                                                        marginBottom: '6px',
                                                        wordBreak: 'break-word'
                                                    }}>
                                                        {tracklist.name}
                                                    </div>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        marginTop: '8px'
                                                    }}>
                                                        <a
                                                            href={tracklist.url}
                                                            target='_blank'
                                                            rel='noreferrer'
                                                            style={{
                                                                color: '#1DB954',
                                                                fontSize: '0.85rem',
                                                                textDecoration: 'none',
                                                                display: 'inline-block',
                                                                padding: '4px 0'
                                                            }}
                                                            onClick={(e) => e.stopPropagation()}
                                                        >
                                                            View on 1001Tracklists
                                                        </a>
                                                        {missingTracksCount > 0 && (
                                                            <span style={{
                                                                backgroundColor: '#e57373',
                                                                color: '#fff',
                                                                borderRadius: '4px',
                                                                padding: '6px 10px',
                                                                fontSize: '0.85rem',
                                                                whiteSpace: 'nowrap'
                                                            }}>
                                                                {missingTracksCount} missing
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                {missingTracksCount > 0 && (
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flexShrink: 0,
                                                        marginLeft: '10px'
                                                    }}>
                                                        <span style={{
                                                            transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                                                            transition: 'transform 0.2s',
                                                            fontSize: '1.2rem',
                                                            color: '#b3b3b3'
                                                        }}>
                                                            ▼
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                            {isExpanded && missingTracksCount > 0 && (
                                                <div style={{
                                                    padding: '16px 20px',
                                                    backgroundColor: '#222',
                                                    overflowY: 'auto',
                                                    position: 'relative'
                                                }}>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        marginBottom: '12px',
                                                        position: 'sticky',
                                                        top: 0,
                                                        backgroundColor: '#222',
                                                        padding: '8px 0',
                                                        zIndex: 1
                                                    }}>
                                                        <div style={{
                                                            color: '#e57373',
                                                            fontSize: '0.95rem',
                                                            fontWeight: '500',
                                                            textAlign: 'left'
                                                        }}>
                                                            Tracks not found on Spotify:
                                                        </div>
                                                    </div>
                                                    <ul style={{
                                                        listStyle: 'none',
                                                        padding: '0',
                                                        margin: '0',
                                                        textAlign: 'left'
                                                    }}>
                                                        {tracklist.couldnt_find_tracks?.map((track, trackIdx) => (
                                                            <li key={trackIdx} style={{
                                                                padding: '12px 16px',
                                                                margin: '8px 0',
                                                                backgroundColor: '#333',
                                                                borderRadius: '4px',
                                                                fontSize: '0.9rem',
                                                                color: '#b3b3b3',
                                                                borderLeft: '3px solid #e57373',
                                                                wordBreak: 'break-word',
                                                                lineHeight: '1.4'
                                                            }}>
                                                                {track}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        position: 'sticky',
                                                        bottom: 0,
                                                        backgroundColor: '#222',
                                                        padding: '12px 0',
                                                        marginTop: '16px',
                                                        zIndex: 1
                                                    }}>
                                                        <Button
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                toggleTracklistExpanded(index);
                                                            }}
                                                            size="small"
                                                            style={{
                                                                color: '#b3b3b3',
                                                                minWidth: 'auto',
                                                                padding: '6px 16px',
                                                                backgroundColor: '#333',
                                                                borderRadius: '20px'
                                                            }}
                                                        >
                                                            Collapse
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )}
                </div>
            )}

            <div style={{
                margin: '30px 0',
                padding: '15px',
                backgroundColor: 'rgba(29, 185, 84, 0.1)',
                borderRadius: '8px',
                maxWidth: '800px'
            }}>
                <p style={{
                    color: '#b3b3b3',
                    fontSize: '0.9rem',
                    marginBottom: '0'
                }}>
                    This tool uses <a href="https://www.1001tracklists.com" target="_blank" rel="noreferrer" style={{ color: '#1DB954' }}>1001Tracklists</a> to find recent DJ sets from your favorite artists and creates a Spotify playlist with tracks from those sets.
                </p>
            </div>
        </div>
    );
};

export default CreateArtistsSetsPlaylist;
