import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Client_ from '../../api/Client';
import { useNotification } from '../../stores/actions/StoreActions';
import ReactMarkdown from 'react-markdown';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

interface PromptHelperProps { }

const PromptHelper: React.FC<PromptHelperProps> = () => {

    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { addNotificationError, addNotificationSuccess, addNotification } = useNotification()
    const dispatch = useDispatch();


    const [task, setTask] = useState("");
    const [generatedPrompts, setGeneratedPrompts] = useState("");
    const [promptCritique, setPromptCritique] = useState("");
    const [improvedPrompts, setImprovedPrompts] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isImproving, setIsImproving] = useState(false);

    useEffect(() => {
        document.title = 'Prompt Helper';
    }, []);

    const generatePrompt = () => {
        setIsLoading(true);
        setGeneratedPrompts("");
        setPromptCritique("");
        setImprovedPrompts("");
        Client_.client_().generatePrompt(task).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setGeneratedPrompts(res?.result?.prompt);
            }
        });
    };

    const improvePrompt = () => {
        setIsImproving(true);
        addNotification('Improving prompt... this may take a few seconds.');
        Client_.client_().improvePrompt(generatedPrompts, task).then(({ err, res }) => {
            setIsImproving(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setImprovedPrompts(res?.result?.improved_prompt);
                setPromptCritique(res?.result?.critique);
            }
        });
    };


    const handleCopyText = () => {
        navigator.clipboard.writeText(generatedPrompts);
        addNotificationSuccess("Copied to clipboard");
    };

    const handleCopyImprovedText = () => {
        navigator.clipboard.writeText(improvedPrompts);
        addNotificationSuccess("Copied to clipboard");
    };


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h1>LLM Prompt Helper</h1>
            <p>Enter a task to generate a few prompts that can be fed to an LLM to achieve that task.</p>
            <TextField
                style={{ width: '50%' }}
                id="outlined-basic"
                label="Task"
                variant="outlined"
                value={task}
                multiline
                onChange={(event) => setTask(event.target.value)}
                helperText="Enter a task you want to do, e.g. 'design a product'"
            />

            <Button
                variant="contained"
                color="primary"
                onClick={generatePrompt}
                disabled={isLoading}
                startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : <EmojiObjectsIcon />}
                sx={{mt: 2}}
            >
                {isLoading ? 'Generating...' : 'Generate Prompt'}
            </Button>

            {generatedPrompts &&
                <>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: improvedPrompts ? 'row' : 'column',
                        width: '90%',
                        justifyContent: 'center',
                        alignItems: improvedPrompts ? 'flex-start' : 'center',
                        marginTop: 5,
                        gap: 2,
                        '@media (max-width: 768px)': {
                            flexDirection: 'column',
                        }
                    }}>
                        <Box sx={{
                            width: improvedPrompts ? '50%' : '75%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: improvedPrompts ? 'flex-start' : 'center',
                            '@media (max-width: 768px)': {
                                width: '100%',
                                alignItems: 'center'
                            }
                        }}>
                            <Typography variant="h6" align="center" sx={{ width: '100%' }}>
                                {improvedPrompts ? 'Original Prompt' : 'Generated Prompt'}
                            </Typography>
                            <Paper
                                elevation={3}
                                sx={{
                                    p: 2,
                                    width: '100%',
                                    height: 'auto',
                                    minHeight: improvedPrompts ? '300px' : 'auto',
                                    flexGrow: 1
                                }}
                            >
                                <ReactMarkdown components={{
                                    p: ({node, ...props}) => <p style={{margin: '0.25em 0'}} {...props} />
                                }}>
                                    {generatedPrompts}
                                </ReactMarkdown>
                            </Paper>
                            <Button
                                sx={{ mt: 1, alignSelf: 'center' }}
                                variant="outlined"
                                startIcon={<FileCopyIcon />}
                                onClick={handleCopyText}
                                size="small"
                            >
                                Copy
                            </Button>
                        </Box>

                        {improvedPrompts &&
                            <Box sx={{
                                width: '50%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                '@media (max-width: 768px)': {
                                    width: '100%',
                                    alignItems: 'center'
                                }
                            }}>
                                <Typography variant="h6" align="center" sx={{ width: '100%' }}>
                                    Improved Prompt
                                </Typography>
                                <Paper
                                    elevation={3}
                                    sx={{
                                        p: 2,
                                        width: '100%',
                                        height: 'auto',
                                        minHeight: '300px',
                                        flexGrow: 1
                                    }}
                                >
                                    <ReactMarkdown components={{
                                        p: ({node, ...props}) => <p style={{margin: '0.25em 0'}} {...props} />
                                    }}>
                                        {improvedPrompts}
                                    </ReactMarkdown>
                                </Paper>
                                <Button
                                    sx={{ mt: 1, alignSelf: 'center' }}
                                    variant="outlined"
                                    startIcon={<FileCopyIcon />}
                                    onClick={handleCopyImprovedText}
                                    size="small"
                                >
                                    Copy
                                </Button>
                            </Box>
                        }
                    </Box>

                    {!improvedPrompts &&
                        <Button
                            sx={{ marginTop: 2 }}
                            variant="contained"
                            startIcon={isImproving ? <CircularProgress size={20} color="inherit" /> : <AutoFixHighIcon />}
                            onClick={improvePrompt}
                            disabled={isImproving}
                        >
                            {isImproving ? 'Improving...' : 'Improve Prompt'}
                        </Button>
                    }
                </>
            }

            {promptCritique &&
                <>
                    <h2 style={{ marginTop: 30 }}>Evaluation of Prompts</h2>
                    <Paper
                        elevation={3}
                        sx={{
                            width: '75%',
                            p: 2,
                            mt: 2,
                            '@media (max-width: 768px)': { width: '100%' }
                        }}
                    >
                        <ReactMarkdown components={{
                            p: ({node, ...props}) => <p style={{margin: '0.25em 0'}} {...props} />
                        }}>
                            {promptCritique}
                        </ReactMarkdown>
                    </Paper>
                </>
            }

            {improvedPrompts &&
                <Button
                    sx={{ marginTop: 3 }}
                    variant="contained"
                    startIcon={isImproving ? <CircularProgress size={20} color="inherit" /> : <AutoFixHighIcon />}
                    onClick={improvePrompt}
                    disabled={isImproving}
                >
                    {isImproving ? 'Improving...' : 'Improve Again'}
                </Button>
            }
        </div>
    );
};

export default PromptHelper;