import TextField from '@mui/material/TextField';
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Client_ from '../../api/Client';
import { useNotification, useUser } from '../../stores/actions/StoreActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Typography from '@mui/material/Typography';
import LaunchIcon from '@mui/icons-material/Launch';
import CircularProgress from '@mui/material/CircularProgress';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import EmailIcon from '@mui/icons-material/Email';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { alpha } from '@mui/material/styles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface ProfileProps { }

const Profile: React.FC<ProfileProps> = () => {
    const [cartiLoading, setCartiLoading] = useState(false);
    const [apiKeysLoading, setApiKeysLoading] = useState(false);
    const [sessionId, setSessionId] = useState(localStorage.getItem('cartinese_session_id'));
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deletingAccount, setDeletingAccount] = useState(false);
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [cancelReason, setCancelReason] = useState('');
    const [hasValidHistory, setHasValidHistory] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector((state: any) => state.user);
    const { getUser, logoutUser } = useUser();

    const { addNotificationError, addNotificationSuccess, addNotification } = useNotification();
    const [cancellingSubscription, setCancellingSubscription] = useState(false);


    useEffect(() => {
        document.title = 'Profile';
        // Fetch user data only once when component mounts
        getUser();

        // Check if there's a valid history to go back to
        const checkNavigationHistory = () => {
            // Check if we came from another page in our app (state is populated by React Router)
            if (location.key !== 'default') {
                setHasValidHistory(true);
            } else {
                // Fallback to browser history length check
                setHasValidHistory(window.history.length > 1);
            }
        };

        checkNavigationHistory();
    }, [location]);

    // Separate effect to handle navigation based on auth state
    useEffect(() => {
        if (user && !user.username) {
            navigate('/login');
        }
    }, [navigate, user]);

    // Function to handle back navigation
    const handleGoBack = () => {
        navigate(-1);
    };

    const handleActivateCartinesePremium = () => {
        setCartiLoading(true);
        if (!sessionId) {
            addNotificationError('Could not activate subscription. Please contact support.');
            return;
        }

        Client_.client_().activateCartiSubscription(sessionId).then(({ err, res }) => {
            setCartiLoading(false);
            if (err) {
                addNotificationError(err);
                if (err.toLowerCase().includes('canceled')) {
                    localStorage.removeItem('cartinese_session_id');
                }
            } else {
                getUser();
                localStorage.removeItem('cartinese_session_id');
            }
        });
    };

    const handleCreateApiKey = () => {
        setApiKeysLoading(true);
        Client_.client_().createApiKey().then(({ err, res }) => {
            setApiKeysLoading(false);
            if (err) {
                addNotificationError(err);
            } else {
                getUser();
            }
        });
    };

    const handleCopyApiKey = (apiKey: string) => {
        navigator.clipboard.writeText(apiKey);
        addNotificationSuccess('API key copied to clipboard');
    };


    const handleLogout = () => {
        logoutUser();
        navigate('/');
    };

    const handleCancelSubscription = () => {
        setCancelDialogOpen(true);
    };

    const handleConfirmCancel = () => {
        if (!cancelReason.trim()) {
            addNotificationError('Please provide a reason for cancellation');
            return;
        }

        setCancellingSubscription(true);
        Client_.client_().cancelSubscription(cancelReason).then(({ err, res }) => {
            setCancellingSubscription(false);
            if (err) {
                addNotificationError(err);
            } else {
                addNotificationSuccess('Subscription cancelled successfully. You\'ll have access until the end of your billing period.');
                getUser();
                setCancelDialogOpen(false);
                setCancelReason('');
            }
        });
    };

    const handleCancelCancellation = () => {
        setCancelDialogOpen(false);
        setCancelReason('');
    };

    const handleDeleteAccount = () => {
        setDeleteDialogOpen(true);
    };

    const handleConfirmDelete = () => {
        setDeletingAccount(true);
        Client_.client_().deleteUser().then(({ err, res }) => {
            setDeletingAccount(false);
            if (err) {
                addNotificationError(err);
                setDeleteDialogOpen(false);
            } else {
                addNotificationSuccess('Your account has been permanently deleted.');
                logoutUser();
                navigate('/');
            }
        });
    };

    const handleCancelDelete = () => {
        setDeleteDialogOpen(false);
    };

    return (
        <Container maxWidth="sm" sx={{
            mt: { xs: 2, sm: 4 },
            mb: { xs: 2, sm: 4 },
            px: { xs: 2, sm: 3 }
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 2,
                width: '100%'
            }}>
                {hasValidHistory && (
                    <Button
                        variant='outlined'
                        color='primary'
                        startIcon={<ArrowBackIcon />}
                        onClick={handleGoBack}
                        sx={{
                            borderRadius: 1.5,
                            textTransform: 'none'
                        }}
                    >
                        Back
                    </Button>
                )}
                <Button
                    variant='outlined'
                    color='primary'
                    startIcon={<HomeIcon />}
                    onClick={() => navigate('/')}
                    sx={{
                        borderRadius: 1.5,
                        textTransform: 'none',
                        ml: hasValidHistory ? 0 : 'auto'
                    }}
                >
                    Home
                </Button>
            </Box>
            <Paper elevation={3} sx={{
                p: { xs: 2, sm: 3 },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 1.5, sm: 2 },
                borderRadius: 2,
                background: (theme) => `linear-gradient(to bottom, ${alpha(theme.palette.primary.main, 0.05)}, transparent 20%)`
            }}>
                <Avatar sx={{
                    width: { xs: 80, sm: 100 },
                    height: { xs: 80, sm: 100 },
                    mb: { xs: 1, sm: 2 },
                    fontSize: { xs: '2rem', sm: '2.5rem' },
                    fontWeight: 'bold',
                    background: (theme) => `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                    border: (theme) => `4px solid ${alpha(theme.palette.primary.main, 0.2)}`,
                    boxShadow: 2
                }}>
                    {user.first_name[0]}
                </Avatar>
                <Typography variant="h4" component="h1" sx={{
                    fontSize: { xs: '1.75rem', sm: '2.125rem' },
                    fontWeight: 600,
                    mb: 0.5
                }}>
                    {user.first_name}
                </Typography>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                    <TextField
                        fullWidth
                        id='username-field'
                        label='Username'
                        disabled
                        variant='outlined'
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                bgcolor: (theme) => alpha(theme.palette.background.paper, 0.06),
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: (theme) => alpha(theme.palette.divider, 0.1)
                                }
                            },
                            '& .MuiInputLabel-root.Mui-disabled': {
                                color: (theme) => alpha(theme.palette.text.primary, 0.5)
                            },
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: (theme) => alpha(theme.palette.text.primary, 0.7)
                            }
                        }}
                        value={user.username}
                    />
                    <TextField
                        fullWidth
                        id='email-field'
                        label='Email'
                        disabled
                        variant='outlined'
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                bgcolor: (theme) => alpha(theme.palette.background.paper, 0.06),
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: (theme) => alpha(theme.palette.divider, 0.1)
                                }
                            },
                            '& .MuiInputLabel-root.Mui-disabled': {
                                color: (theme) => alpha(theme.palette.text.primary, 0.5)
                            },
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: (theme) => alpha(theme.palette.text.primary, 0.7)
                            }
                        }}
                        value={user.email}
                    />
                </Box>
                <Paper variant="outlined" sx={{
                    p: { xs: 2, sm: 2.5 },
                    width: '100%',
                    borderRadius: 1.5,
                    bgcolor: (theme) => alpha(theme.palette.background.paper, 0.06),
                    borderColor: (theme) => alpha(theme.palette.divider, 0.1)
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: 2
                    }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Box>
                                <Typography
                                    variant='subtitle1'
                                    sx={{
                                        fontWeight: 600,
                                        lineHeight: 1.2
                                    }}
                                >
                                    Playboi Carti Translator
                                </Typography>
                                {user?.carti_premium?.premium_status && (
                                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                                        {user?.carti_premium?.is_trial && (
                                            <Box
                                                component="span"
                                                sx={{
                                                    display: 'inline-block',
                                                    bgcolor: 'primary.main',
                                                    color: 'primary.contrastText',
                                                    fontSize: '0.7rem',
                                                    fontWeight: 'bold',
                                                    px: 1,
                                                    py: 0.2,
                                                    borderRadius: 1,
                                                    mr: 1
                                                }}
                                            >
                                                TRIAL
                                            </Box>
                                        )}
                                        <Typography
                                            variant='caption'
                                            color='text.secondary'
                                        >
                                            {user?.carti_premium?.subscription_type}
                                            {user?.carti_premium?.premium_expiration &&
                                                ` · ${user?.carti_premium?.is_trial ? 'Trial ends' : 'Expires'} ${new Date(user.carti_premium.premium_expiration).toLocaleDateString()}`
                                            }
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        gap: 1.5
                    }}>
                        {sessionId && !user?.carti_premium?.premium_status ? (
                            <Button
                                fullWidth
                                variant='contained'
                                color='primary'
                                onClick={handleActivateCartinesePremium}
                                disabled={cartiLoading}
                                startIcon={cartiLoading ? <CircularProgress size={20} /> : <LockOpenIcon />}
                                sx={{
                                    py: 1,
                                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.9),
                                    '&:hover': {
                                        bgcolor: 'primary.main'
                                    }
                                }}
                            >
                                {cartiLoading ? 'Activating...' : 'Activate'}
                            </Button>
                        ) : (
                            <Button
                                fullWidth
                                variant='contained'
                                color='primary'
                                onClick={() => navigate('/cartinese')}
                                startIcon={<LaunchIcon />}
                                sx={{
                                    py: 1,
                                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.9),
                                    '&:hover': {
                                        bgcolor: 'primary.main'
                                    }
                                }}
                            >
                                Start Translating 🧛
                            </Button>
                        )}

                    </Box>
                </Paper>
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: { xs: 1, sm: 1.5 }
                }}>
                    {/* Account Settings Accordion */}
                    <Paper variant="outlined" sx={{
                        width: '100%',
                        borderRadius: 1.5,
                        bgcolor: (theme) => alpha(theme.palette.background.paper, 0.06),
                        borderColor: (theme) => alpha(theme.palette.divider, 0.1)
                    }}>
                        <Accordion
                            elevation={0}
                            sx={{
                                bgcolor: 'transparent',
                                '&:before': { display: 'none' },
                                '& .MuiAccordionSummary-root': {
                                    minHeight: { xs: '40px', sm: '48px' },
                                    px: { xs: 0, sm: 1 },
                                    '&:hover': {
                                        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08)
                                    }
                                },
                                '& .MuiAccordionDetails-root': {
                                    pt: 0,
                                    px: { xs: 0, sm: 1 }
                                }
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls='account-actions-content'
                                id='account-actions-header'
                            >
                                <Typography variant='h6' sx={{ ml: 1 }}>Settings</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 1.5,
                                    px: 1
                                }}>
                                    <Button
                                        fullWidth
                                        variant='outlined'
                                        color='primary'
                                        onClick={() => window.location.href = 'mailto:saarimmm@gmail.com'}
                                        startIcon={<EmailIcon />}
                                        sx={{
                                            borderRadius: 1.5,
                                            textTransform: 'none',
                                            py: 1
                                        }}
                                    >
                                        Contact Support
                                    </Button>
                                    <Button
                                        fullWidth
                                        variant='outlined'
                                        color='primary'
                                        onClick={() => window.open('https://billing.stripe.com/p/login/3cs7uSehZdU95biaEE', '_blank')}
                                        startIcon={<ShoppingCartIcon />}
                                        sx={{
                                            borderRadius: 1.5,
                                            textTransform: 'none',
                                            py: 1
                                        }}
                                    >
                                        Manage Billing
                                    </Button>
                                    {false && user?.carti_premium?.premium_status && !user?.carti_premium?.cancelled_at && (
                                        <Button
                                            fullWidth
                                            variant='outlined'
                                            color='error'
                                            onClick={handleCancelSubscription}
                                            disabled={cancellingSubscription}
                                            startIcon={cancellingSubscription ?
                                                <CircularProgress size={20} /> :
                                                <CancelIcon />
                                            }
                                            sx={{
                                                py: 1,
                                                borderColor: (theme) => alpha(theme.palette.error.main, 0.5),
                                                '&:hover': {
                                                    borderColor: 'error.main',
                                                    bgcolor: (theme) => alpha(theme.palette.error.main, 0.04)
                                                }
                                            }}
                                        >
                                            {cancellingSubscription ? 'Cancelling...' : 'Cancel Subscription'}
                                        </Button>
                                    )}
                                    <Button
                                        fullWidth
                                        variant='outlined'
                                        color='primary'
                                        onClick={handleLogout}
                                        startIcon={<ExitToAppIcon />}
                                        sx={{
                                            borderRadius: 1.5,
                                            textTransform: 'none',
                                            py: 1
                                        }}
                                    >
                                        Logout
                                    </Button>
                                    {/* <Button
                                        fullWidth
                                        variant='outlined'
                                        color='error'
                                        onClick={handleDeleteAccount}
                                        startIcon={<DeleteForeverIcon />}
                                        sx={{
                                            borderRadius: 1.5,
                                            textTransform: 'none',
                                            py: 1,
                                            mt: 1,
                                            borderColor: (theme) => alpha(theme.palette.error.main, 0.5),
                                            '&:hover': {
                                                borderColor: 'error.main',
                                                bgcolor: (theme) => alpha(theme.palette.error.main, 0.04)
                                            }
                                        }}
                                    >
                                        Delete Account
                                    </Button> */}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Paper>

                    {/* API Keys Accordion */}
                    <Paper variant="outlined" sx={{
                        width: '100%',
                        borderRadius: 1.5,
                        bgcolor: (theme) => alpha(theme.palette.background.paper, 0.06),
                        borderColor: (theme) => alpha(theme.palette.divider, 0.1)
                    }}>
                        <Accordion
                            elevation={0}
                            sx={{
                                bgcolor: 'transparent',
                                '&:before': { display: 'none' },
                                '& .MuiAccordionSummary-root': {
                                    minHeight: { xs: '40px', sm: '48px' },
                                    px: { xs: 0, sm: 1 },
                                    '&:hover': {
                                        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08)
                                    }
                                },
                                '& .MuiAccordionDetails-root': {
                                    pt: 0,
                                    px: { xs: 0, sm: 1 }
                                }
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls='api-keys-content'
                                id='api-keys-header'
                            >
                                <Typography variant='h6' sx={{ ml: 1 }}>API Keys</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{ px: 1 }}>
                                {user?.api_keys && user?.api_keys?.length > 0 ? (
                                    <List>
                                        {user.api_keys.map((keyObj: any, index: number) => (
                                            <ListItem key={index} alignItems='flex-start'>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant='body1' component='span'>
                                                            {keyObj?.api_key}
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <>
                                                            <Typography variant='caption' color='text.secondary' component='div'>
                                                                Created {new Date(keyObj?.created_at).toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' })}
                                                            </Typography>
                                                        </>
                                                    }
                                                />
                                                <IconButton edge='end' aria-label='copy' onClick={() => handleCopyApiKey(keyObj?.api_key)}>
                                                    <ContentCopyIcon />
                                                </IconButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                ) : (
                                    <Typography variant='body2' color='text.secondary'>No API keys found</Typography>
                                )}
                                <Button
                                    fullWidth
                                    variant='outlined'
                                    color='primary'
                                    onClick={handleCreateApiKey}
                                    disabled={apiKeysLoading}
                                    startIcon={apiKeysLoading ? <CircularProgress size={20} /> : <VpnKeyIcon />}
                                    sx={{ mt: 2 }}
                                >
                                    {apiKeysLoading ? 'Creating...' : 'Create New API Key'}
                                </Button>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Paper>
                </Box>
            </Paper>

            {/* Delete Account Confirmation Dialog */}
            <Dialog
                open={deleteDialogOpen}
                onClose={handleCancelDelete}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">
                    {"Delete Your Account?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        This action will permanently delete your account and all associated data. This cannot be undone.
                        Are you sure you want to proceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConfirmDelete}
                        color="error"
                        variant="contained"
                        disabled={deletingAccount}
                        startIcon={deletingAccount ? <CircularProgress size={20} /> : <DeleteForeverIcon />}
                    >
                        {deletingAccount ? 'Deleting...' : 'Delete Permanently'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Cancel Subscription Dialog */}
            <Dialog
                open={cancelDialogOpen}
                onClose={handleCancelCancellation}
                aria-labelledby="cancel-dialog-title"
                aria-describedby="cancel-dialog-description"
            >
                <DialogTitle id="cancel-dialog-title">
                    {'Cancel Your Subscription?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="cancel-dialog-description" sx={{ mb: 2 }}>
                        You'll continue to have access until the end of your current billing period. Please let us know why you're cancelling:
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="cancel-reason"
                        label="Reason for cancellation"
                        type="text"
                        fullWidth
                        multiline
                        rows={3}
                        value={cancelReason}
                        onChange={(e) => setCancelReason(e.target.value)}
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelCancellation} color="primary">
                        Keep Subscription
                    </Button>
                    <Button
                        onClick={handleConfirmCancel}
                        color="error"
                        variant="contained"
                        disabled={cancellingSubscription || !cancelReason.trim()}
                        startIcon={cancellingSubscription ? <CircularProgress size={20} /> : <CancelIcon />}
                    >
                        {cancellingSubscription ? 'Cancelling...' : 'Cancel Subscription'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default Profile;