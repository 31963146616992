import Button from '@mui/material/Button';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Client_ from '../../api/Client';
import { useNotification } from '../../stores/actions/StoreActions';

interface SpotifyAuthProps { }


const SpotifyAuth: React.FC<SpotifyAuthProps> = () => {

    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { addNotificationError, addNotificationSuccess, addNotification } = useNotification()
    const dispatch = useDispatch();

    const [playlistUrl, setPlaylistUrl] = useState("");
    const [generatedPlaylistUrl, setGeneratedPlaylistUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [authLink, setAuthLink] = useState("");
    const [code, setCode] = useState("");

    useEffect(() => {
        document.title = 'Spotify Login';
    }, []);

    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
        let code = urlParams.get('code');
        let redirect = localStorage.getItem('spotify_auth_redirect');

        const handleRedirect = (path: any) => {
            if (path) {
                localStorage.removeItem('spotify_auth_redirect'); // Clear the stored redirect
                navigate(path);
            } else {
                navigate('/spotify');
            }
        };

        if (code) {
            setIsLoading(true);
            Client_.client_().setSpotifyToken(code).then(({ err, res }) => {
                setIsLoading(false);
                if (err || !res?.success) {
                    addNotificationError(err);
                } else {
                    handleRedirect(redirect);
                    addNotificationSuccess('Successfully logged in to Spotify');
                }
            });
        } else {
            Client_.client_().validateSpotifyAuth().then(({ err, res }) => {
                if (err || !res?.success) {
                    addNotificationError(err);
                    initSpotifyOAuth(); // Only initialize OAuth if validation fails
                } else {
                    if (res?.result?.success) {
                        addNotificationSuccess('Logged in to Spotify');
                        handleRedirect(redirect);
                    } else {
                        initSpotifyOAuth();
                    }
                }
            });
        }
    }, [navigate]);

    const initSpotifyOAuth = () => {
        setIsLoading(true);
        Client_.client_().initSpotifyOAuth().then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                let url = res?.result?.auth_url;
                setAuthLink(url);
            }
        });
    }

    const openSpotifyAuthWindow = (url: string) => {
        const newWindow = window.open(url, '_blank');
        if (newWindow) {
            // The window opened successfully, you can further manipulate it if needed.
        } else {
            // Handle cases where the window couldn't be opened (e.g., due to a popup blocker).
            addNotificationError("Unable to open the Spotify login page. Please check your popup blocker settings.");
        }
    }


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            padding: '20px',
            maxWidth: '1200px',
            margin: '0 auto',
            color: '#fff'
        }}>
            <h1 style={{
                fontSize: '2rem',
                fontWeight: '700',
                background: 'linear-gradient(to right, #1DB954, #1ed760)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
            }}>
                Spotify Authentication
            </h1>

            <p style={{
                color: '#b3b3b3',
                fontSize: '1rem',
                marginBottom: '24px',
                maxWidth: '600px'
            }}>
                To use Spotify related features, you must first login with Spotify.
            </p>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '90%',
                maxWidth: '800px',
                margin: '20px auto',
                padding: '15px',
                backgroundColor: '#1a1a1a',
                borderRadius: '8px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.3)',
                border: '1px solid #333'
            }}>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: '#1DB954',
                        '&:hover': {
                            backgroundColor: '#1ed760'
                        },
                        borderRadius: '20px',
                        padding: '10px 24px',
                        textTransform: 'none',
                        fontSize: '1rem'
                    }}
                    onClick={() => openSpotifyAuthWindow(authLink)}
                >
                    Login with Spotify
                </Button>
                <p style={{ color: '#b3b3b3', fontSize: '0.8rem', margin: '12px 0 0 0' }}>
                    You will be redirected to Spotify to authorize the application.
                </p>
            </div>
        </div>
    );
};

export default SpotifyAuth;