import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from './components/Link';
import { getProducts } from './components/Product/Product';
import ProductGrid from './components/ProductGrid/ProductGrid';
import FilterChips from './components/FilterChips/FilterChips';
import constants from './constants/Constants';
import './App.css';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Grow from '@mui/material/Grow';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CloseIcon from '@mui/icons-material/Close';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

function App() {
  const [activeTabs, setActiveTabs] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (searchOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchOpen]);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'dark',
          primary: {
            main: '#ffffff',
          },
          secondary: {
            main: '#e0e0e0',
          },
          background: {
            default: '#000000',
            paper: '#121212',
          },
        },
        typography: {
          fontFamily: 'Roboto, Arial, sans-serif',
          h2: {
            fontWeight: 700,
            letterSpacing: '-0.02em',
          },
          body1: {
            lineHeight: 1.8,
          },
        },
        components: {
          MuiCard: {
            styleOverrides: {
              root: {
                backgroundColor: '#121212',
                borderRadius: '12px',
                boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-6px)',
                  boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.3)',
                },
              },
            },
          },
          MuiChip: {
            styleOverrides: {
              root: {
                backgroundColor: '#212121',
                color: '#e0e0e0',
                border: '1px solid #424242',
                borderRadius: '20px',
                margin: '4px',
                transition: 'background-color 0.3s ease, color 0.3s ease',
                '&:hover': {
                  backgroundColor: '#303030',
                },
                '&.Mui-selected': {
                  backgroundColor: '#ffffff',
                  color: '#000000',
                },
              },
            },
          },
        },
      }),
    [],
  );

  const products = getProducts();
  const filteredProducts = products.filter(product =>
    (activeTabs.length === 0 || activeTabs.includes(product.category)) &&
    (searchQuery === '' ||
      product.primary.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.secondary.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.primary.toLowerCase().split(' ').some(word =>
        word.startsWith(searchQuery.toLowerCase())
      ) ||
      product.secondary.toLowerCase().split(' ').some(word =>
        word.startsWith(searchQuery.toLowerCase())
      )
    )
  );

  const handleSearchClose = () => {
    setSearchOpen(false);
  };

  const clearAllFilters = () => {
    setSearchQuery('');
    setActiveTabs([]);
    setSearchOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: '100vh',
          background: 'linear-gradient(135deg, #000000 0%, #121212 100%)',
          backgroundImage: `radial-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px)`,
          backgroundSize: '30px 30px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Container maxWidth='lg' sx={{ textAlign: 'center', pt: 6, pb: 3 }}>
          <Box
            sx={{
              mb: 3,
              animation: 'fadeIn 1s ease-in-out',
              '@keyframes fadeIn': {
                '0%': { opacity: 0, transform: 'translateY(-20px)' },
                '100%': { opacity: 1, transform: 'translateY(0)' }
              }
            }}
          >
            <Typography variant='h2' component='h1' gutterBottom sx={{
              color: '#ffffff',
              mb: 2,
              background: 'linear-gradient(90deg, #4A90E2 0%, #9B59B6 100%)',
              backgroundClip: 'text',
              textFillColor: 'transparent',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontWeight: 700
            }}>
              Hi there!
            </Typography>
            <Typography variant='h4' gutterBottom sx={{ color: '#cccccc', mb: 2 }}>
              I'm Saarim, and I love building amazing things.
            </Typography>
            <Typography variant='body1' sx={{ color: '#ffffff', mb: 1, maxWidth: '700px', mx: 'auto' }}>
              Discover more about me on my{' '}
              <Link to='https://saarim.me' sx={{
                position: 'relative',
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  width: '100%',
                  height: '2px',
                  bottom: -2,
                  left: 0,
                  backgroundColor: '#4A90E2',
                  transform: 'scaleX(0)',
                  transformOrigin: 'bottom right',
                  transition: 'transform 0.3s ease-out'
                },
                '&:hover:after': {
                  transform: 'scaleX(1)',
                  transformOrigin: 'bottom left'
                }
              }}>
                homepage
              </Link>.
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexWrap: 'wrap',
              mb: 2,
              p: { xs: 1, sm: 1.5 },
              borderRadius: '12px',
              background: 'rgba(18, 18, 18, 0.5)',
              backdropFilter: 'blur(8px)',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
              maxWidth: '100%',
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              position: 'relative'
            }}>
              <FilterChips
                activeTabs={activeTabs}
                setActiveTabs={setActiveTabs}
                tabs={constants.TABS}
                tabLabels={constants.TAB_LABELS}
                variant="default"
                sx={{
                  display: 'inline-flex',
                  flexWrap: { xs: 'nowrap', sm: 'wrap' },
                  justifyContent: 'center',
                  '& .MuiStack-root': {
                    justifyContent: 'center',
                    flexWrap: { xs: 'nowrap', sm: 'wrap' }
                  }
                }}
              />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  mt: 2
                }}
              >
                <TextField
                  placeholder="Search projects..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  variant="outlined"
                  size="small"
                  inputRef={searchInputRef}
                  sx={{
                    width: { xs: '85%', sm: '400px' },
                    maxWidth: '500px',
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: 'rgba(18, 18, 18, 0.9)',
                      borderRadius: '24px',
                      '& fieldset': {
                        borderColor: 'rgba(255, 255, 255, 0.2)',
                      },
                      '&:hover fieldset': {
                        borderColor: 'rgba(255, 255, 255, 0.4)',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#4A90E2',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: '#ffffff',
                      padding: { xs: '8px 14px', sm: '8.5px 14px' },
                    },
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.25)',
                    transition: 'all 0.3s ease',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                      </InputAdornment>
                    ),
                    endAdornment: searchQuery ? (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setSearchQuery('')}
                          edge="end"
                          size="small"
                          sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
                          aria-label="Clear search"
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={{ minHeight: '60vh', mt: 0 }}>
            {filteredProducts.length > 0 ? (
              <ProductGrid products={filteredProducts} />
            ) : (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '40vh',
                opacity: 0.7
              }}>
                <Typography variant="h5" sx={{ color: '#ffffff', mb: 2 }}>
                  No projects match your filter
                </Typography>
                <Typography variant="body1" sx={{ color: '#cccccc' }}>
                  Try selecting different categories or clear your filters
                </Typography>
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;