import FmdGoodIcon from '@mui/icons-material/FmdGood';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import LensIcon from '@mui/icons-material/Lens';
import { List, ListItemText } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemButton from '@mui/material/ListItemButton';
import TextField from '@mui/material/TextField';
import bbox from '@turf/bbox';
import React, { useEffect, useRef, useState } from 'react';
import type { HeatmapLayer, MapRef } from 'react-map-gl';
import Map, { Layer, Marker, Popup, Source } from 'react-map-gl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Client_ from '../../api/Client';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNotification } from '../../stores/actions/StoreActions';
import InfoIcon from '@mui/icons-material/Info';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import Tooltip from '@mui/material/Tooltip';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

/* eslint-disable import/no-webpack-loader-syntax */
import mapboxgl from 'mapbox-gl';
// @ts-ignore
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

type CrimeData = {
    'Incident ID': number;
    'Latitude': number;
    'Longitude': number;
    'Incident Description': string;
    'Incident Category': string;
    'Incident Date': string;
    'Incident Time': string;
};

interface ICrimeMapProps {

}

const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiYWF5c3VzIiwiYSI6ImNsaDQ3aWd3OTFnd24zZHBxNDM3YnV2ZGIifQ.octQiW3i6npkVHwT_acvbA'
const MAPBOX_SESSION_TOKEN = '026a67aa-768a-4bc1-887d-510d1d200a26'

// Initial San Francisco coordinates
const INITIAL_SF_COORDS = {
    longitude: -122.4474,
    latitude: 37.7529,
    zoom: 12
};

const Legend = () => {
    const gradientStyle = {
        height: '10px',
        width: '100%',
        background:
            'linear-gradient(to right, #440154, #3e4989, #21918c, #5ec962, #fde725)',
        borderRadius: '4px',
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <div style={gradientStyle} />
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                color: 'white' // Make text visible on dark background
            }}>
                <Typography variant="caption" sx={{ color: 'inherit' }}>Least</Typography>
                <Typography variant="caption" sx={{ color: 'inherit' }}>Middle</Typography>
                <Typography variant="caption" sx={{ color: 'inherit' }}>Most</Typography>
            </div>
        </div>
    );
};

interface ControlPanelProps {
    isMobile: boolean;
    setDrawerOpen: (open: boolean) => void;
    lookbackDays: number | string;
    setLookbackDays: (days: number | string) => void;
    descriptionSubString: string;
    setDescriptionSubString: (description: string) => void;
    searchQuery: string;
    handleSearchQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    searchSuggestions: Array<{
        mapbox_id: string;
        name: string;
        full_address: string;
    }>;
    resultSelected: boolean;
    setSearchResult: (result: any) => void;
    handleGetLocation: () => void;
    getCrimeData: (isManualRequest: boolean) => void;
    isLoading: boolean;
    crimeProbability: number | null;
    clearPin: () => void;
    hasPin: boolean;
    resetMapView: () => void;
    showIndividualCrimes: boolean;
    setShowIndividualCrimes: (show: boolean) => void;
}

// Move ControlPanel outside and memoize it
const ControlPanel = React.memo(({
    isMobile,
    setDrawerOpen,
    lookbackDays,
    setLookbackDays,
    descriptionSubString,
    setDescriptionSubString,
    searchQuery,
    handleSearchQueryChange,
    searchSuggestions,
    resultSelected,
    setSearchResult,
    handleGetLocation,
    getCrimeData,
    isLoading,
    crimeProbability,
    clearPin,
    hasPin,
    resetMapView,
    showIndividualCrimes,
    setShowIndividualCrimes
}: ControlPanelProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    // Focus input when drawer opens on mobile
    useEffect(() => {
        if (isMobile && inputRef.current) {
            const timer = setTimeout(() => {
                inputRef.current?.focus();
            }, 100);
            return () => clearTimeout(timer);
        }
    }, [isMobile]);

    return (
        <Box
            sx={{ p: 2, width: isMobile ? '100%' : '350px' }}
            onClick={(e) => e.stopPropagation()}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <h2 style={{ margin: 0 }}>SF Crime Map</h2>
                {isMobile && (
                    <IconButton onClick={() => setDrawerOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                )}
            </Box>

            <TextField
                fullWidth
                size='small'
                label='Lookback Days'
                value={lookbackDays}
                onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || /^\d+$/.test(value)) {
                        setLookbackDays(value);
                    }
                }}
                inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    autoComplete: 'off'
                }}
                sx={{ mb: 2 }}
            />

            <TextField
                fullWidth
                size='small'
                label='Crime Type'
                value={descriptionSubString}
                onChange={(e) => setDescriptionSubString(e.target.value)}
                inputProps={{
                    autoComplete: 'off'
                }}
                sx={{ mb: 2 }}
            />

            <Box sx={{ position: 'relative', mb: 2 }}>
                <TextField
                    fullWidth
                    size='small'
                    label='Search Location'
                    value={searchQuery}
                    onChange={handleSearchQueryChange}
                    autoComplete='off'
                    inputRef={inputRef}
                    inputProps={{
                        autoComplete: 'off'
                    }}
                />
                {searchSuggestions?.length > 0 && !resultSelected && (
                    <Paper sx={{
                        position: 'absolute',
                        zIndex: 1,
                        width: '100%',
                        maxHeight: '200px',
                        overflow: 'auto',
                        mt: 1
                    }}>
                        <List>
                            {searchSuggestions.map((result: { mapbox_id: string; name: string; full_address: string }) => (
                                <ListItemButton
                                    key={result.mapbox_id}
                                    onClick={() => {
                                        setSearchResult(result);
                                        if (isMobile) setDrawerOpen(false);
                                    }}
                                >
                                    <ListItemText
                                        primary={result.name}
                                        secondary={result.full_address}
                                        primaryTypographyProps={{ fontSize: '14px' }}
                                        secondaryTypographyProps={{ fontSize: '12px' }}
                                    />
                                </ListItemButton>
                            ))}
                        </List>
                    </Paper>
                )}
            </Box>

            <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                <Button
                    fullWidth
                    variant='outlined'
                    onClick={handleGetLocation}
                    startIcon={<GpsFixedIcon />}
                    disabled={isLoading}
                >
                    Find Me
                </Button>
                <Button
                    fullWidth
                    variant='contained'
                    onClick={() => getCrimeData(true)}
                    disabled={isLoading}
                >
                    {isLoading ? <CircularProgress size={24} /> : 'Search'}
                </Button>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 1, mb: 2 }}>
                <Button
                    fullWidth
                    variant='outlined'
                    onClick={resetMapView}
                    startIcon={<ZoomOutMapIcon />}
                    disabled={isLoading}
                    sx={{ minWidth: '120px' }}
                >
                    Reset View
                </Button>
                {hasPin && (
                    <Button
                        fullWidth
                        variant='outlined'
                        color='error'
                        onClick={clearPin}
                        startIcon={<LocationOffIcon />}
                        sx={{ minWidth: '120px' }}
                    >
                        Clear Pin
                    </Button>
                )}
            </Box>

            <FormControlLabel
                control={
                    <Switch
                        checked={showIndividualCrimes}
                        onChange={(e) => setShowIndividualCrimes(e.target.checked)}
                        color="primary"
                    />
                }
                label={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        <Typography variant="body2">
                            Show Individual Crimes
                        </Typography>
                        {showIndividualCrimes ?
                            <VisibilityIcon fontSize="small" /> :
                            <VisibilityOffIcon fontSize="small" />
                        }
                    </Box>
                }
                sx={{ mb: 2 }}
            />

            {crimeProbability != null ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 2 }}>
                    <Paper sx={{
                        p: 2,
                        bgcolor: 'error.light',
                        color: 'error.contrastText',
                        borderRadius: 2
                    }}>
                        <Typography variant='h6'>
                            Crime Probability: {crimeProbability}%
                        </Typography>
                    </Paper>
                    <Typography variant='caption' sx={{ display: 'block', mt: 0.5, color: 'text.secondary', fontSize: '0.7rem' }}>
                        Based on nearby incidents, their proximity, and recency. It's an estimate only and <strong>NOT</strong> a reliable prediction of future events.
                    </Typography>
                </Box>
            ) : (
                !isMobile && (
                    <Typography variant='caption' sx={{ display: 'block', color: 'text.secondary', mb: 2 }}>
                        Search location or drop pin to see crime likelihood
                    </Typography>
                )
            )}

            <Box sx={{ mb: 2 }}>
                <Typography variant='subtitle2' sx={{ color: 'white', mb: 1 }}>
                    Crime Legend
                </Typography>
                <Legend />
            </Box>

            <Typography variant='caption' sx={{ display: 'block', color: 'text.secondary' }}>
                Tip: Click anywhere on the map to drop a pin
            </Typography>
        </Box>
    );
});

const CrimeMap: React.FC<ICrimeMapProps> = () => {
    const mapRef = useRef<MapRef>(null);
    const initialRequestMade = useRef(false);
    const [crimeData, setCrimeData] = useState<CrimeData[]>([]);
    const [selectedCrime, setSelectedCrime] = useState<CrimeData | null>(null);
    const [lookbackDays, setLookbackDays] = useState<any>(30);
    const [descriptionSubString, setDescriptionSubString] = useState<string>('Theft, From Locked Vehicle');
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [searchSuggestions, setSearchSuggestions] = useState<any[]>([]);
    const [searchResult, setSearchResult] = useState<any>(null);
    const [destination, setDestination] = useState<any>(null);
    const [resultSelected, setResultSelected] = useState(false);
    const [coordinates, setCoordinates] = useState<any>(null);
    const [heatmapData, setHeatmapData] = useState<any>(null);
    const [crimeProbability, setSetCrimeProbability] = useState<any>(null);
    const [initialLoading, setInitialLoading] = useState(true);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [showIndividualCrimes, setShowIndividualCrimes] = useState(false);
    const [mapZoom, setMapZoom] = useState<number>(12);
    const [headerHeight, setHeaderHeight] = useState(66); // Default header height

    const MAX_ZOOM_LEVEL = 16;
    const MAX_LOCATION_ZOOM = 15;
    const MARKER_VISIBILITY_ZOOM = 13; // Zoom level at which individual markers become more visible

    // Enhanced heatmap layer with better transitions
    const heatmapLayer: HeatmapLayer = {
        id: 'heatmap',
        maxzoom: MAX_ZOOM_LEVEL,
        type: 'heatmap',
        paint: {
            // Use a constant weight for each point to avoid individual circles
            'heatmap-weight': 1,
            // Increase the heatmap intensity for better visibility
            'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, MAX_ZOOM_LEVEL, 2],
            // Color ramp for heatmap with higher opacity values
            'heatmap-color': [
                'interpolate',
                ['linear'],
                ['heatmap-density'],
                0,
                'rgba(0, 0, 0, 0)',
                0.2,
                'rgba(68, 1, 84, 0.8)',  // #440154 with opacity
                0.4,
                'rgba(62, 73, 137, 0.8)', // #3e4989 with opacity
                0.6,
                'rgba(33, 145, 140, 0.8)', // #21918c with opacity
                0.8,
                'rgba(94, 201, 98, 0.8)',  // #5ec962 with opacity
                1,
                'rgba(253, 231, 37, 0.8)'  // #fde725 with opacity
            ],
            // Increase radius for better overlap between points
            'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 15, MAX_ZOOM_LEVEL, 35],
            // Keep heatmap visible even at high zoom levels
            'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, MAX_ZOOM_LEVEL, 0.7]
        },
        source: 'crime-heatmap',
    };

    let navigate = useNavigate();
    const { addNotificationError, addNotification } = useNotification()
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Function to reset map view to initial San Francisco coordinates
    const resetMapView = () => {
        if (mapRef.current) {
            mapRef.current.flyTo({
                center: [INITIAL_SF_COORDS.longitude, INITIAL_SF_COORDS.latitude],
                zoom: isMobile ? 11 : INITIAL_SF_COORDS.zoom,
                duration: 1000
            });
            addNotification('Map view reset to San Francisco');
        }
    };

    const getCrimeData = (isManualRequest = false) => {
        // Only prevent duplicate calls during automatic initialization
        // Allow manual requests from button clicks
        if (initialLoading && initialRequestMade.current && !isManualRequest) {
            return;
        }

        initialRequestMade.current = true;
        setIsLoading(true);
        Client_.client_().getCrimeData(parseInt(lookbackDays), descriptionSubString, coordinates?.latitude, coordinates?.longitude).then(({ err, res }) => {
            setIsLoading(false);
            setInitialLoading(false);
            if (err || !res?.success) {
                addNotificationError(err);
                return;
            } else {
                setCrimeData(res?.result?.crime_data);
                setHeatmapData(res?.result?.crime_heatmap);
                setSetCrimeProbability(res?.result?.crime_probability);
            }
        });
    };

    useEffect(() => {
        if (searchQuery !== "") {
            const fetchSuggestions = async () => {
                const response = await fetch(
                    `https://api.mapbox.com/search/searchbox/v1/suggest?q=${searchQuery}&language=en&access_token=${MAPBOX_ACCESS_TOKEN}&session_token=${MAPBOX_SESSION_TOKEN}`
                );
                const data = await response.json();
                setSearchSuggestions(data?.suggestions);
            };
            fetchSuggestions();
        } else {
            // Clear suggestions when search query is empty
            setSearchSuggestions([]);
        }
    }, [searchQuery]);

    const handleGetLocation = () => {
        setIsLoading(true);
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                setCoordinates({ latitude, longitude });

                const [minLng, minLat, maxLng, maxLat] = bbox({
                    type: 'Point',
                    coordinates: [longitude, latitude]
                });

                if (mapRef.current) {
                    mapRef.current.fitBounds(
                        [
                            [minLng, minLat],
                            [maxLng, maxLat]
                        ],
                        { padding: 40, duration: 1000, maxZoom: MAX_LOCATION_ZOOM }
                    );
                }
            },
            (error) => {
                setIsLoading(false);
                addNotificationError('Could not get your location. Please check your location permissions.');
            }
        );
    };

    useEffect(() => {
        if (searchResult) {
            setSearchQuery(searchResult.full_address)
            const fetchSearchResult = async () => {
                const response = await fetch(
                    `https://api.mapbox.com/search/searchbox/v1/retrieve/${searchResult.mapbox_id}?access_token=${MAPBOX_ACCESS_TOKEN}&session_token=${MAPBOX_SESSION_TOKEN}`
                );
                const data = await response.json();
                const feature = data?.features[0];
                // setDestination(feature?.properties);
                setCoordinates(feature?.properties?.coordinates);
                if (feature && mapRef.current) {
                    const [minLng, minLat, maxLng, maxLat] = bbox(feature);
                    mapRef.current.fitBounds(
                        [
                            [minLng, minLat],
                            [maxLng, maxLat]
                        ],
                        { padding: 40, duration: 1000, maxZoom: MAX_LOCATION_ZOOM }
                    );
                }
            };
            fetchSearchResult();
            setResultSelected(true);
        }
    }, [searchResult]);

    useEffect(() => {
        document.title = 'SF Crime';
        // Call without the manual flag for initial load
        getCrimeData(false);
    }, []);

    const handleSearchQueryChange = (event: any) => {
        setSearchQuery(event.target.value);
        setResultSelected(false);
        setSetCrimeProbability(null);
    };

    const clearPin = () => {
        setCoordinates(null);
        setSetCrimeProbability(null);
        setSearchQuery('');
        setSearchResult(null);
        setResultSelected(false);
        addNotification('Pin cleared. Click on the map to place a new pin.');
    };

    useEffect(() => {
        if (coordinates) {
            getCrimeData(true);
        }
    }, [coordinates]);

    const handleMapClick = (event: any) => {
        // Only set coordinates if we have a valid click with longitude and latitude
        if (event.lngLat && !isLoading) {
            const { lng, lat } = event.lngLat;

            // Clear any existing search results
            setSearchResult(null);
            setSearchQuery('');
            setResultSelected(false);

            // Set coordinates
            setCoordinates({ latitude: lat, longitude: lng });
        }
    };

    // Handle map zoom changes
    const handleZoomChange = () => {
        if (mapRef.current) {
            const zoom = mapRef.current.getZoom();
            setMapZoom(zoom);
        }
    };

    // Effect to measure and update header height
    useEffect(() => {
        const updateHeaderHeight = () => {
            const headerElement = document.querySelector('header') || document.querySelector('.MuiAppBar-root');
            if (headerElement) {
                const height = headerElement.getBoundingClientRect().height;
                setHeaderHeight(height);
            }
        };

        // Initial measurement
        updateHeaderHeight();

        // Set up resize listener
        window.addEventListener('resize', updateHeaderHeight);

        // Clean up
        return () => window.removeEventListener('resize', updateHeaderHeight);
    }, []);

    return (
        <Box sx={{
            height: `calc(100vh - ${headerHeight}px)`,
            width: '100vw',
            position: 'relative',
            overflow: 'hidden' // Prevent any scrolling within the component
        }}>
            {isMobile ? (
                <>
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: 10,
                            left: 10,
                            zIndex: 1,
                            bgcolor: 'background.paper',
                            '&:hover': { bgcolor: 'background.paper' },
                            padding: '8px 16px',
                            boxShadow: 2,
                            borderRadius: '20px',
                            display: 'flex',
                            gap: '4px',
                            alignItems: 'center'
                        }}
                        onClick={() => setDrawerOpen(true)}
                        aria-label='Open crime map filters'
                    >
                        <FilterAltIcon />
                        <Typography variant='button' sx={{ fontSize: '14px' }}>
                            Filters
                        </Typography>
                    </IconButton>
                    <Drawer
                        anchor='left'
                        open={drawerOpen}
                        onClose={() => setDrawerOpen(false)}
                        keepMounted={false}
                        disableRestoreFocus
                        SlideProps={{
                            timeout: 200
                        }}
                    >
                        <ControlPanel
                            isMobile={isMobile}
                            setDrawerOpen={setDrawerOpen}
                            lookbackDays={lookbackDays}
                            setLookbackDays={setLookbackDays}
                            descriptionSubString={descriptionSubString}
                            setDescriptionSubString={setDescriptionSubString}
                            searchQuery={searchQuery}
                            handleSearchQueryChange={handleSearchQueryChange}
                            searchSuggestions={searchSuggestions}
                            resultSelected={resultSelected}
                            setSearchResult={setSearchResult}
                            handleGetLocation={handleGetLocation}
                            getCrimeData={getCrimeData}
                            isLoading={isLoading}
                            crimeProbability={crimeProbability}
                            clearPin={clearPin}
                            hasPin={!!coordinates}
                            resetMapView={resetMapView}
                            showIndividualCrimes={showIndividualCrimes}
                            setShowIndividualCrimes={setShowIndividualCrimes}
                        />
                    </Drawer>
                </>
            ) : (
                <Paper
                    elevation={3}
                    sx={{
                        position: 'absolute',
                        left: 20,
                        top: 20,
                        zIndex: 1,
                        borderRadius: 2,
                        overflow: 'hidden'
                    }}
                >
                    <ControlPanel
                        isMobile={isMobile}
                        setDrawerOpen={setDrawerOpen}
                        lookbackDays={lookbackDays}
                        setLookbackDays={setLookbackDays}
                        descriptionSubString={descriptionSubString}
                        setDescriptionSubString={setDescriptionSubString}
                        searchQuery={searchQuery}
                        handleSearchQueryChange={handleSearchQueryChange}
                        searchSuggestions={searchSuggestions}
                        resultSelected={resultSelected}
                        setSearchResult={setSearchResult}
                        handleGetLocation={handleGetLocation}
                        getCrimeData={getCrimeData}
                        isLoading={isLoading}
                        crimeProbability={crimeProbability}
                        clearPin={clearPin}
                        hasPin={!!coordinates}
                        resetMapView={resetMapView}
                        showIndividualCrimes={showIndividualCrimes}
                        setShowIndividualCrimes={setShowIndividualCrimes}
                    />
                </Paper>
            )}

            <Map
                initialViewState={{
                    longitude: INITIAL_SF_COORDS.longitude,
                    latitude: INITIAL_SF_COORDS.latitude,
                    zoom: isMobile ? 11 : INITIAL_SF_COORDS.zoom
                }}
                mapStyle="mapbox://styles/mapbox/streets-v12"
                style={{ width: '100%', height: '100%' }}
                mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
                ref={mapRef}
                onClick={handleMapClick}
                cursor={isLoading ? 'wait' : 'pointer'}
                onZoom={handleZoomChange}
            >
                {showIndividualCrimes && crimeData.map(crime => (
                    <Marker
                        key={crime['Incident ID']}
                        latitude={crime['Latitude']}
                        longitude={crime['Longitude']}
                        onClick={(e) => {
                            e.originalEvent.stopPropagation();
                            setSelectedCrime(crime)
                        }}
                        style={{ cursor: 'pointer' }}
                    >
                        <LocationOnIcon
                            sx={{
                                color: 'red',
                                fontSize: mapZoom > MARKER_VISIBILITY_ZOOM ? 16 : 12,
                                transition: 'all 0.2s ease-in-out',
                                '&:hover': {
                                    color: 'rgba(255, 0, 0, 0.8)',
                                    transform: 'scale(1.2)'
                                }
                            }}
                        />
                    </Marker>
                ))}
                {heatmapData && (
                    <Source type="geojson" data={heatmapData}>
                        <Layer {...heatmapLayer} />
                    </Source>
                )}
                {destination && (
                    <Marker
                        key={destination.mapbox_id}
                        latitude={destination.coordinates.latitude}
                        longitude={destination.coordinates.longitude}
                    // anchor='bottom'
                    >
                        <FmdGoodIcon style={{ color: '#1976d2', fontSize: 30 }} />
                    </Marker>
                )}

                {coordinates && (
                    <Marker
                        key={'currentLocation'}
                        latitude={coordinates.latitude}
                        longitude={coordinates.longitude}
                    // anchor='bottom'
                    >
                        <Tooltip title="Click 'Clear Pin' to remove">
                            <FmdGoodIcon style={{ color: '#1976d2', fontSize: 30 }} />
                        </Tooltip>
                    </Marker>
                )}
                {selectedCrime && (
                    <Popup
                        latitude={selectedCrime['Latitude']}
                        longitude={selectedCrime['Longitude']}
                        // anchor='top'
                        onClose={() => setSelectedCrime(null)}
                        style={{ color: 'black' }}
                    >
                        <b>{selectedCrime['Incident Category']}</b> <br />
                        {selectedCrime['Incident Description']} <br />
                        <span style={{ color: 'gray', fontSize: 10 }}>{selectedCrime['Incident Date']} {selectedCrime['Incident Time']} </span>
                    </Popup>
                )}
            </Map>
            {initialLoading && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 2,
                        textAlign: 'center',
                        bgcolor: 'rgba(0, 0, 0, 0.7)',
                        p: 3,
                        borderRadius: 2,
                        maxWidth: '80%'
                    }}
                >
                    <CircularProgress sx={{ mb: 2 }} />
                    <Typography variant="body1" sx={{ color: 'white' }}>
                        Loading crime data for San Francisco...
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'white', mt: 1, opacity: 0.8 }}>
                        Initial load may take a few minutes. Subsequent requests will be faster.
                    </Typography>
                </Box>
            )}

            {/* Mobile Crime Probability and Legend Container */}
            {isMobile && (
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 16,
                        left: 16,
                        zIndex: 2,
                        bgcolor: 'rgba(0, 0, 0, 0.7)',
                        color: 'white',
                        p: 2,
                        borderRadius: 2,
                        boxShadow: 3,
                        width: 240,
                        maxHeight: '40vh',
                        overflowY: 'auto'
                    }}
                >
                    {/* Crime Probability Section */}
                    <Box sx={{ mb: 2 }}>
                        {isLoading ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <CircularProgress size={16} sx={{ color: 'white' }} />
                                <Typography variant='caption'>
                                    Loading...
                                </Typography>
                            </Box>
                        ) : crimeProbability != null ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <LensIcon sx={{ color: 'error.light', fontSize: 16, flexShrink: 0 }} />
                                <Typography variant='caption'>
                                    Crime Probability: <strong>{crimeProbability}%</strong>
                                </Typography>
                            </Box>
                        ) : (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <InfoIcon sx={{ color: 'info.light', fontSize: 16, flexShrink: 0 }} />
                                <Typography variant='caption'>
                                    Drop a pin anywhere
                                </Typography>
                            </Box>
                        )}
                    </Box>

                    {/* Heat Map Legend Section */}
                    <Typography variant='caption' sx={{ color: 'white', mb: 1, display: 'block' }}>
                        Crime Legend
                    </Typography>
                    <Box sx={{ pb: 1 }}>
                        <Legend />
                    </Box>
                </Box>
            )}

            {/* Mobile Floating Action Buttons */}
            {isMobile && (
                <Box
                sx={{
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                    zIndex: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1.5
                }}
                >
                    {coordinates && (
                        <IconButton
                            aria-label='Clear pin'
                            onClick={clearPin}
                            disabled={isLoading}
                            sx={{
                                bgcolor: 'rgba(211, 47, 47, 0.9)',
                                color: 'white',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                '&:hover': { bgcolor: 'rgba(211, 47, 47, 1)' },
                                transition: 'all 0.2s ease',
                                width: 48,
                                height: 48
                            }}
                        >
                            <LocationOffIcon />
                        </IconButton>
                    )}
                    <IconButton
                        aria-label='Find my location'
                        onClick={handleGetLocation}
                        disabled={isLoading}
                        sx={{
                            bgcolor: 'rgba(25, 118, 210, 0.9)',
                            color: 'white',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            '&:hover': { bgcolor: 'rgba(25, 118, 210, 1)' },
                            '&.Mui-disabled': { bgcolor: 'rgba(0, 0, 0, 0.3)' },
                            transition: 'all 0.2s ease',
                            width: 48,
                            height: 48
                        }}
                    >
                        <GpsFixedIcon />
                    </IconButton>

                    <IconButton
                        aria-label='Toggle individual crimes'
                        onClick={() => setShowIndividualCrimes(!showIndividualCrimes)}
                        sx={{
                            bgcolor: showIndividualCrimes ? 'rgba(46, 125, 50, 0.9)' : 'rgba(156, 39, 176, 0.9)',
                            color: 'white',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            '&:hover': {
                                bgcolor: showIndividualCrimes ? 'rgba(46, 125, 50, 1)' : 'rgba(156, 39, 176, 1)'
                            },
                            transition: 'all 0.2s ease',
                            width: 48,
                            height: 48
                        }}
                    >
                        {showIndividualCrimes ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>

                    <IconButton
                        aria-label='Reset map view'
                        onClick={resetMapView}
                        disabled={isLoading}
                        sx={{
                            bgcolor: 'rgba(0, 151, 167, 0.9)',
                            color: 'white',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            '&:hover': { bgcolor: 'rgba(0, 151, 167, 1)' },
                            transition: 'all 0.2s ease',
                            width: 48,
                            height: 48
                        }}
                    >
                        <ZoomOutMapIcon />
                    </IconButton>
                </Box>
            )}
        </Box>
    );
};

export default CrimeMap;
