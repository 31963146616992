import { default as DeleteIcon } from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import 'chart.js/auto';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Client_ from '../../api/Client';
import constants from '../../constants/Constants';
import { useNotification, useUser } from '../../stores/actions/StoreActions';
import Link from '../Link';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

interface SpotifyProps { }

const GREEN = '#00cc66';
const RED = '#990000';

const SpotifyDuplicates: React.FC<SpotifyProps> = () => {
    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { addNotificationError, addNotificationSuccess, addNotification } = useNotification()
    const dispatch = useDispatch();

    const { forgotPassword } = useUser();

    const [playlistUrl, setPlaylistUrl] = useState('');
    const [duplicateTracks, setDuplicateTracks] = useState([]);
    const [playlistId, setPlaylistId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [needsSpotifyLogin, setNeedsSpotifyLogin] = useState(false);

    useEffect(() => {
        document.title = 'Remove Duplicates';
        const checkSpotifyAuth = async () => {
            const { err, res } = await Client_.client_().validateSpotifyAuth();
            if (err || !res?.success) {
                addNotificationError(err);
            } else {
                if (res?.result?.success) {
                    setNeedsSpotifyLogin(false);
                } else {
                    localStorage.setItem('spotify_auth_redirect', '/find-duplicates');
                    addNotificationError('Please login to Spotify first');
                    setNeedsSpotifyLogin(true);
                    navigate('/spotify-auth');
                }
            }
        };
        checkSpotifyAuth();
        const intervalId = setInterval(checkSpotifyAuth, 30000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const findDuplicates = (playlistUrl: string) => {
        setIsLoading(true);
        setDuplicateTracks([]);
        Client_.client_().findDuplicates(playlistUrl).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setDuplicateTracks(res?.result?.tracks);
                setPlaylistId(res?.result?.playlist_id);
            }
        });
    };

    const removeDuplicates = (playlistId: string, duplicates: any) => {
        setIsLoading(true);
        Client_.client_().removeDuplicates(playlistId, duplicates).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                addNotificationSuccess('Duplicates removed successfully');
                setDuplicateTracks([]);
            }
        });
    }

    const handleDeleteRow = (itemId: any) => {
        setDuplicateTracks((prevItems: any) => prevItems.filter((prevItem: any) => prevItem.id !== itemId));
    };

    const setExampleData = () => {
        setPlaylistUrl(constants.exampleDuplicateSpotifyUrl);
        findDuplicates(constants.exampleDuplicateSpotifyUrl);
    }

    const columns: GridColDef[] = [
        {
            field: 'originalTrack',
            headerName: 'Original Track',
            width: 600,
            renderCell: (params) => (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography variant="body2" sx={{ color: '#ff4444', fontWeight: '500' }}>{params.row.track.name}</Typography>
                    <Typography variant="caption" sx={{ color: '#b3b3b3' }}>{params.row.track.album.name}</Typography>
                    <Typography variant="caption" sx={{ color: '#b3b3b3' }}>Position: {params.row.position}</Typography>
                </div>
            ),
        },
        {
            field: 'replacementTrack',
            headerName: 'Replacement Track',
            width: 600,
            renderCell: (params) => (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography variant="body2" sx={{ color: '#1DB954', fontWeight: '500' }}>{params.row.replacement.track.name}</Typography>
                    <Typography variant="caption" sx={{ color: '#b3b3b3' }}>{params.row.replacement.track.album.name}</Typography>
                    <Typography variant="caption" sx={{ color: '#b3b3b3' }}>Position: {params.row.replacement.position}</Typography>
                </div>
            ),
        },
        {
            field: 'reason',
            headerName: 'Reason',
            width: 200,
            renderCell: (params) => (
                <Typography variant="body2" sx={{ color: '#fff' }}>{params.row.reason}</Typography>
            ),
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            renderCell: (params) => (
                <Tooltip title="Exclude from deletion">
                    <IconButton onClick={() => handleDeleteRow(params.row.id)} size="small">
                        <DeleteIcon sx={{ color: '#ff4444' }} />
                    </IconButton>
                </Tooltip>
            ),
        },
    ];

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            padding: '20px',
            maxWidth: '1200px',
            margin: '0 auto',
            color: '#fff'
        }}>
            <h1 style={{
                fontSize: '2rem',
                fontWeight: '700',
                marginBottom: '24px',
                background: 'linear-gradient(to right, #1DB954, #1ed760)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
            }}>
                Remove Duplicate Songs
            </h1>

            {needsSpotifyLogin && (
                <p style={{
                    color: '#ff4444',
                    fontWeight: '500',
                    fontSize: '1rem',
                    padding: '16px',
                    backgroundColor: 'rgba(255, 68, 68, 0.1)',
                    borderRadius: '8px',
                    width: '100%',
                    maxWidth: '800px',
                    marginBottom: '20px'
                }}>
                    Please login to Spotify first, click <Link to='/spotify-auth' target='_blank' rel='noreferrer' style={{ color: '#1DB954' }}>here</Link> if not redirected automatically.
                </p>
            )}

            <p style={{
                color: '#b3b3b3',
                fontSize: '1rem',
                marginBottom: '12px',
                maxWidth: '600px'
            }}>
                Enter the URL of the Spotify playlist you want to find and remove duplicate entries for
            </p>
            <p style={{
                color: '#b3b3b3',
                fontSize: '0.9rem',
                marginBottom: '24px',
                maxWidth: '600px'
            }}>
                Stuck? Right click your playlist {'>'} Share {'>'} Copy link to playlist
            </p>

            <TextField
                sx={{
                    width: '100%',
                    maxWidth: '800px',
                    mb: 3,
                    '& .MuiOutlinedInput-root': {
                        color: '#fff',
                        backgroundColor: '#282828',
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1DB954',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1DB954',
                        }
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#404040'
                    },
                    '& .MuiInputLabel-root': {
                        color: '#b3b3b3',
                        '&.Mui-focused': {
                            color: '#1DB954'
                        }
                    }
                }}
                label="Playlist URL"
                variant="outlined"
                value={playlistUrl}
                onChange={(event) => setPlaylistUrl(event.target.value)}
            />

            <div style={{
                marginBottom: '24px',
                display: 'flex',
                flexDirection: 'row',
                gap: '12px',
                flexWrap: 'wrap',
                justifyContent: 'center',
                width: '100%',
                maxWidth: '800px'
            }}>
                {isLoading ? (
                    <CircularProgress sx={{ color: '#1DB954' }} />
                ) : (
                    <>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#1DB954',
                                '&:hover': {
                                    backgroundColor: '#1ed760'
                                },
                                borderRadius: '20px',
                                padding: '10px 24px',
                                textTransform: 'none',
                                fontSize: '1rem'
                            }}
                            onClick={() => findDuplicates(playlistUrl)}
                        >
                            Find Duplicates
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                color: '#fff',
                                borderColor: '#fff',
                                '&:hover': {
                                    borderColor: '#1DB954',
                                    color: '#1DB954'
                                },
                                borderRadius: '20px',
                                padding: '10px 24px',
                                textTransform: 'none',
                                fontSize: '1rem'
                            }}
                            onClick={setExampleData}
                        >
                            Use Example
                        </Button>
                    </>
                )}
            </div>

            {duplicateTracks?.length > 0 && (
                <>
                    <div style={{
                        width: '90%',
                        maxWidth: '1200px',
                        margin: '20px auto',
                        backgroundColor: '#1a1a1a',
                        borderRadius: '8px',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.3)',
                        border: '1px solid #333'
                    }}>
                        <DataGrid
                            rows={duplicateTracks}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[5, 10, 25]}
                            autoHeight
                            getRowHeight={() => 'auto'}
                            getEstimatedRowHeight={() => 100}
                            sx={{
                                border: 'none',
                                backgroundColor: 'transparent',
                                '& .MuiDataGrid-cell': {
                                    whiteSpace: 'normal',
                                    lineHeight: 'normal',
                                    padding: '16px',
                                    borderBottom: '1px solid #333'
                                },
                                '& .MuiDataGrid-columnHeaders': {
                                    backgroundColor: '#282828',
                                    borderBottom: '1px solid #333',
                                    color: '#fff'
                                },
                                '& .MuiDataGrid-footerContainer': {
                                    backgroundColor: '#282828',
                                    borderTop: '1px solid #333'
                                },
                                '& .MuiTablePagination-root': {
                                    color: '#b3b3b3'
                                },
                                '& .MuiDataGrid-row:hover': {
                                    backgroundColor: '#282828'
                                },
                                '& .MuiDataGrid-virtualScroller': {
                                    backgroundColor: 'transparent'
                                }
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default SpotifyDuplicates;
