import FileCopyIcon from '@mui/icons-material/FileCopy';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Client_ from '../../api/Client';
import { useNotification } from '../../stores/actions/StoreActions';
import Divider from '@mui/material/Divider';
import StarIcon from '@mui/icons-material/Star';
import { Box, Container, Typography, Skeleton } from '@mui/material';
import Switch from '@mui/material/Switch';
import Slider from '@mui/material/Slider';
import ReactGA from 'react-ga4';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Popover from '@mui/material/Popover';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { motion } from 'framer-motion';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SpeedIcon from '@mui/icons-material/Speed';
import SecurityIcon from '@mui/icons-material/Security';
import { useUser } from '../../stores/actions/StoreActions';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CartiProduct from './CartiProduct';
import BrushIcon from '@mui/icons-material/Brush';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Paper from '@mui/material/Paper';

interface CartineseProps { }

const MAX_CARTI_INPUT_LENGTH = 50;
const MAX_REQUESTS_PER_PERIOD_GUEST = 3;
const MAX_REQUESTS_PER_PERIOD_USER = 10;
const REQUEST_PERIOD_HOURS = 24;
const SAMPLE_TEXT = "Why should I keep juggin' all these broke boys?"
const SKIP_REQUEST_CHECK_FOR_SAMPLE = true;
const OFFER_DURATION = 60 * 60 * 24 * 2; // 2 days for the trial offer

const Cartinese: React.FC<CartineseProps> = () => {
    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { addNotificationError, addNotificationSuccess, addNotification } = useNotification()
    const { getUser } = useUser();
    const [searchParams] = useSearchParams();
    const isPremium = user?.carti_premium?.premium_status;
    const isTrialSubscription = user?.carti_premium?.is_trial;
    const premiumExpiration = user?.carti_premium?.premium_expiration ? new Date(user?.carti_premium?.premium_expiration) : null;
    const legacyPremium = searchParams.get('earlybih') === 'true';
    const [defaultConfig, setDefaultConfig] = useState<{ [key: string]: any } | null>(null);
    const [config, setConfig] = useState<{ [key: string]: any } | null>(null);
    const [openModal, setOpenModal] = useState(false);
    const [input, setInput] = useState('');
    const [cartinese, setCartinese] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [openPopoverId, setOpenPopoverId] = useState<string | null>(null);
    const translatedTextRef = useRef<HTMLDivElement>(null);
    const [isTranslating, setIsTranslating] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [timeLeft, setTimeLeft] = useState(0);
    const [showTrialPromo, setShowTrialPromo] = useState(false);

    useEffect(() => {
        document.title = 'Playboi Carti Translator';
        if (legacyPremium && !isPremium) {
            addNotificationError('Please log in to regain access to premium features. Contact support if you run into any issues.')
            localStorage.setItem('redirect_after_login', '/cartinese');
            navigate('/login');
        };
        if (!defaultConfig) {
            getCartifyConfig();
        }
        if (user?.username) {
            getUser();
        }

        // Setup trial promotion timer - now only initializes the timer but doesn't show the promo yet
        if (!isPremium && user?.username) {
            const storedEndTime = localStorage.getItem('trialOfferEndTime');
            const currentTime = Math.floor(Date.now() / 1000);

            if (!storedEndTime) {
                const endTime = currentTime + OFFER_DURATION;
                localStorage.setItem('trialOfferEndTime', endTime.toString());
                setTimeLeft(OFFER_DURATION);
            } else {
                const remainingTime = parseInt(storedEndTime) - currentTime;
                if (remainingTime > 0) {
                    setTimeLeft(remainingTime);
                }
            }

            // Start the timer regardless, but don't show promo yet
            const timer = setInterval(() => {
                setTimeLeft((prevTime) => {
                    if (prevTime <= 1) {
                        clearInterval(timer);
                        return 0;
                    }
                    return prevTime - 1;
                    return -1;
                });
            }, 1000);


            return () => clearInterval(timer);
        }
    }, []);

    const [requestLimitReached, setRequestLimitReached] = useState(false);
    const [resetTime, setResetTime] = useState<Date | null>(null);

    const canMakeRequest = () => {
        return true;
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
        setAnchorEl(event.currentTarget);
        setOpenPopoverId(id);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setOpenPopoverId(null);
    };

    const LiveTimer = ({ resetTime }: { resetTime: Date | null }) => {
        const [timeLeft, setTimeLeft] = useState(resetTime ? Math.floor((resetTime.getTime() - new Date().getTime()) / 1000) : 0);

        useEffect(() => {
            if (resetTime) {
                const timer = setInterval(() => {
                    setTimeLeft((prevTime) => prevTime > 0 ? prevTime - 1 : 0);
                }, 1000);

                return () => clearInterval(timer);
            }
        }, [resetTime]);

        const formatTime = (seconds: number) => {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const remainingSeconds = seconds % 60;
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
        };

        return (
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}>
                Next free translation in: <span style={{ fontFamily: 'monospace', fontSize: '1.2em' }}>{formatTime(timeLeft)}</span>
            </Typography>
        );
    };

    const cartify = (overrideInput?: string) => {
        // Skip request check for sample text
        let text = overrideInput || input;
        const isSampleText = text === SAMPLE_TEXT && SKIP_REQUEST_CHECK_FOR_SAMPLE;
        if (!isSampleText && !canMakeRequest()) return;

        if (text.length === 0) {
            addNotificationError('Please enter some text to translate');
            return;
        }

        setIsLoading(true);
        setIsTranslating(true);
        setCartinese('');

        // Don't clear cartinese immediately to prevent UI jumps
        const method = isPremium ? 'cartifyPremium' : 'cartify';
        Client_.client_()[method](text, config).then(({ err, res }) => {
            if (err || !res?.success) {
                // Check for rate limit error message
                const waitTimeMatch = err?.match(/Wait (\d+) hours and (\d+) minutes/);
                if (waitTimeMatch) {
                    const hours = parseInt(waitTimeMatch[1]);
                    const minutes = parseInt(waitTimeMatch[2]);
                    const resetTime = new Date();
                    resetTime.setHours(resetTime.getHours() + hours);
                    resetTime.setMinutes(resetTime.getMinutes() + minutes);

                    setResetTime(resetTime);
                    setRequestLimitReached(true);
                    addNotificationError(err);

                    if (!isPremium && user?.username) {
                        setShowTrialPromo(true);
                    }
                } else if (err?.toLowerCase().includes('log in')) {
                    addNotification('Please log in to continue translating');
                    navigate('/login', {
                        state: {
                            returnTo: '/cartinese',
                            message: 'Log in to continue translating',
                            forceRedirect: true
                        }
                    });
                } else {
                    addNotificationError(err);
                }
                setIsLoading(false);
                setIsTranslating(false);
                return;
            } else {
                // First update loading state, then update content in next tick
                setIsLoading(false);
                setTimeout(() => {
                    setCartinese(res?.result?.cartinese);
                    setIsTranslating(false);
                }, 50);
            }
        });
    };

    const getCartifyConfig = () => {
        Client_.client_().getCartifyConfig().then(({ err, res }) => {
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setDefaultConfig(res?.result?.config);
            }
        });
    }

    const handleCopyText = () => {
        navigator.clipboard.writeText(cartinese);
        addNotificationSuccess('Copied to clipboard');
    };

    const handlePremiumClick = () => {
        addNotification('Join Vamp Nation to use this feature!');
        ReactGA.event('view_item', {
            items: [{
                id: 'cartinese_premium',
                name: 'Cartinese Premium',
                category: 'Premium',
                quantity: 1,
                price: 1.00
            }]
        });
        navigate('/cartinese/purchase');
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newInput = isPremium ? event.target.value : event.target.value.slice(0, MAX_CARTI_INPUT_LENGTH);
        setInput(newInput);
    };

    // Check for mobile viewport
    useEffect(() => {
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth < 600);
        };

        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);

        return () => window.removeEventListener('resize', checkIfMobile);
    }, []);

    // Separate timeout for state changes to prevent observer loops
    useEffect(() => {
        if (!isTranslating && cartinese && !isMobile) {
            const timer = setTimeout(() => {
                if (translatedTextRef.current) {
                    translatedTextRef.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    });
                }
            }, 300);
            return () => clearTimeout(timer);
        }
    }, [isTranslating, cartinese, isMobile]);

    const handleSampleTextClick = () => {
        if (isLoading) return;

        setInput(SAMPLE_TEXT);

        // Increased delay and separate state updates
        setTimeout(() => {
            cartify(SAMPLE_TEXT);
        }, 200);
    };

    const handleStartTrial = () => {
        // Track whether this is a trial or regular purchase based on timeLeft
        const isTrial = timeLeft > 0;

        setIsLoading(true);
        // Pass isTrial flag instead of hardcoded true, so we only create trial when timeLeft > 0
        Client_.client_().createCartineseCheckoutSession('SUBSCRIPTION', isTrial).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err || 'Failed to create checkout session');
                return;
            }
            window.location.href = res.result.url;
        });
    };

    const formatTime = (time: number) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const closeTrialPromo = () => {
        setShowTrialPromo(false);
    };

    // Promotion timer component for live updates
    const LivePromoTimer = () => {
        const [currentTimeLeft, setCurrentTimeLeft] = useState(timeLeft);

        useEffect(() => {
            const timer = setInterval(() => {
                setCurrentTimeLeft(prevTime => {
                    if (prevTime <= 1) {
                        clearInterval(timer);
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);

            return () => clearInterval(timer);
        }, []);

        return (
            <Typography variant="subtitle1" sx={{
                fontFamily: 'monospace',
                fontWeight: 'bold',
                color: 'white',
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                px: 1.5,
                py: 0.5,
                borderRadius: 1
            }}>
                {formatTime(currentTimeLeft)}
            </Typography>
        );
    };

    // Handle the "Get Unlimited Translations!" button click
    const handleGetUnlimitedClick = () => {
        // Show the promotion modal when user clicks the button
        if (!isPremium && user?.username) {
            setShowTrialPromo(true);
        } else {
            // Fallback to regular premium purchase if no user
            handlePremiumClick();
        }
    };

    if (!user?.username && !user.isLoading) {
        return <CartiProduct />;
    }

    return (
        <Container maxWidth="sm">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 3 }}>
                <Typography variant="h4" gutterBottom sx={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontFamily: '"Orbitron", sans-serif',
                    letterSpacing: '1px',
                    textTransform: 'uppercase',
                    background: 'linear-gradient(45deg, #ff0066, #6600ff)',
                    backgroundClip: 'text',
                    WebkitBackgroundClip: 'text',
                    color: 'transparent',
                    textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                    transform: 'skew(-5deg)',
                    mb: 2
                }}>
                    Playboi Carti Translator
                </Typography>

                {/* Trial Status Banner */}
                {/* {isPremium && isTrialSubscription && premiumExpiration && (
                    <Box sx={{
                        width: '100%',
                        mb: 4,
                        p: 3,
                        borderRadius: 4,
                        backgroundColor: 'primary.main',
                        color: 'primary.contrastText',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    }}>
                        <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold', textAlign: 'center' }}>
                            🔥 Free Trial Active 🔥
                        </Typography>
                        <Typography variant="body1" sx={{ textAlign: 'center' }}>
                            Your trial ends on {premiumExpiration.toLocaleDateString()}. Enjoy unlimited translations!
                        </Typography>
                    </Box>
                )} */}

                {false && requestLimitReached && (
                    <Box sx={{
                        width: '100%',
                        mb: 4,
                        p: 4,
                        borderRadius: 4,
                        backgroundColor: 'error.main',
                        color: 'error.contrastText',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                        position: 'relative',
                        overflow: 'hidden',
                        transition: 'all 0.3s ease-in-out',
                        '&:hover': {
                            transform: 'translateY(-5px)',
                            boxShadow: '0 15px 30px rgba(0,0,0,0.3)',
                        },
                    }}>
                        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold', textAlign: 'center', textTransform: 'uppercase', letterSpacing: 1 }}>
                            🔥 UNLOCK UNLIMITED ACCESS! 🔥
                        </Typography>

                        {resetTime && <LiveTimer resetTime={resetTime} />}

                        <Typography variant="body1" sx={{ mb: 3, textAlign: 'center', fontWeight: 700, fontSize: '1.2rem' }}>
                            sTaRt yA 7-dAy FR33 TRiAL n0w ! *+:) 🦋 🧛🏿‍♂️ ++
                        </Typography>

                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mb: 3, width: '100%' }}>
                            {[
                                // '⚡ PREMIUM ENGINE ! ++',
                                // '💎 CUSTOM SETTINGS ! ^🦋',
                                '🦇 NO LIMITS ! WLR *+',
                                '✨ 7 DAYS FREE ! *+:)',
                                '⏰ CANCEL ANYTIME ! ++**',
                            ].map((feature, index) => (
                                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    <CheckCircleIcon sx={{ mr: 1, color: 'success.light' }} />
                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                        {feature}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                        <Button
                            variant="contained"
                            onClick={() => navigate('/cartinese/purchase?trial=true')}
                            startIcon={<StarIcon />}
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.1em',
                                py: 1.5,
                                px: 3,
                                mt: 2,
                                backgroundColor: 'white',
                                color: 'error.main',
                                '&:hover': {
                                    backgroundColor: '#f0f0f0',
                                    color: 'error.dark',
                                    transform: 'scale(1.02)',
                                },
                                transition: 'all 0.2s ease-in-out',
                                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                borderRadius: '30px',
                                width: 'auto',
                                minWidth: '200px',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            Start 7-Day Free Trial 🔓
                        </Button>
                        <Typography variant="caption" sx={{
                            mt: 2,
                            textAlign: 'center',
                            fontStyle: 'italic',
                            color: 'rgba(255,255,255,0.9)'
                        }}>
                            Just dothatshit! • Only $1.99/month after
                        </Typography>
                        <Box sx={{
                            position: 'absolute',
                            top: -20,
                            right: -20,
                            width: 80,
                            height: 80,
                            backgroundColor: 'error.dark',
                            transform: 'rotate(45deg)',
                        }} />
                    </Box>
                )}
                <Box sx={{ width: '100%', position: 'relative' }}>
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        variant="outlined"
                        value={input}
                        multiline
                        rows={4}
                        onChange={handleInputChange}
                        placeholder={`** !++ Enter the++**💕 text* ! +:)💔 you. .$ wantxo !😦 translated++ ++ 🖤#love *`}
                        sx={{
                            mb: 1,
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '12px',
                                backgroundColor: 'rgba(255, 255, 255, 0.03)',
                                transition: 'all 0.2s ease',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                },
                                '&.Mui-focused': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                    boxShadow: '0 0 0 2px rgba(255, 255, 255, 0.1)',
                                },
                                '& fieldset': {
                                    borderColor: 'rgba(255, 255, 255, 0.1)',
                                    transition: 'all 0.2s ease',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'rgba(255, 255, 255, 0.2)',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'primary.main',
                                    borderWidth: '1px',
                                },
                            },
                            '& .MuiInputBase-input': {
                                padding: '16px',
                                fontSize: '1rem',
                                lineHeight: 1.5,
                            },
                        }}
                        inputProps={{
                            // maxLength: isPremium ? undefined : MAX_CARTI_INPUT_LENGTH,
                            style: {
                                color: 'white',
                            }
                        }}
                        helperText={
                            !isPremium && false && (
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            color: input.length >= MAX_CARTI_INPUT_LENGTH * 0.8 ? 'error.main' : 'text.secondary',
                                            fontStyle: 'italic',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <span style={{ marginRight: '4px' }}>{MAX_CARTI_INPUT_LENGTH - input.length}</span> characters🦋 remaining🖤 * ok  !+
                                    </Typography>
                                </Box>
                            )
                        }
                    />
                    <Box sx={{ width: '100%', mb: 3, mt: 1 }}>
                        <Button
                            onClick={() => setOpenModal(true)}
                            variant="outlined"
                            fullWidth
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                textTransform: 'none',
                                borderRadius: '8px',
                                py: 1.5,
                                color: 'white',
                                borderColor: 'rgba(156, 39, 176, 0.5)',
                                '&:hover': {
                                    borderColor: '#9c27b0',
                                    backgroundColor: 'rgba(156, 39, 176, 0.05)',
                                    transform: 'translateY(-1px)',
                                    boxShadow: '0 0 8px rgba(156, 39, 176, 0.4)',
                                },
                                '&:active': {
                                    transform: 'translateY(1px)',
                                },
                                transition: 'all 0.3s ease',
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <BrushIcon sx={{ mr: 1, color: '#9c27b0' }} />
                                <Typography variant='body2' sx={{ color: 'white' }}>Customize Translation Style</Typography>
                            </Box>
                            <ExpandMoreIcon sx={{ color: '#9c27b0' }} />
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                    <Box sx={{ width: '100%', mb: 2 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2, width: '100%' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => cartify()}
                                fullWidth
                                disabled={isLoading}
                                sx={{
                                    borderRadius: '28px',
                                    textTransform: 'none',
                                    py: 1.5,
                                    fontSize: '1.1rem',
                                    fontWeight: 600,
                                    boxShadow: '0 4px 10px rgba(0,0,0,0.3)',
                                    background: 'linear-gradient(45deg, #9c27b0 30%, #673ab7 90%)',
                                    position: 'relative',
                                    overflow: 'hidden',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        boxShadow: '0 6px 15px rgba(0,0,0,0.4), 0 0 20px rgba(156, 39, 176, 0.4)',
                                        transform: 'translateY(-2px)',
                                    },
                                    '&:active': {
                                        boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
                                        transform: 'translateY(1px)',
                                    },
                                    '&::before': {
                                        content: '""',
                                        position: 'absolute',
                                        top: 0,
                                        left: '-100%',
                                        width: '100%',
                                        height: '100%',
                                        background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent)',
                                        transition: 'all 0.7s ease',
                                        animation: 'pulse 2s infinite',
                                        '@keyframes pulse': {
                                            '0%': { opacity: 0.6 },
                                            '50%': { opacity: 1 },
                                            '100%': { opacity: 0.6 }
                                        }
                                    },
                                    '&:hover::before': {
                                        left: '100%',
                                    },
                                    '&.Mui-disabled': {
                                        background: 'linear-gradient(45deg, rgba(156, 39, 176, 0.5) 30%, rgba(103, 58, 183, 0.5) 90%)',
                                        color: 'rgba(255, 255, 255, 0.7)',
                                    }
                                }}
                            >
                                {isLoading ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                                        <span style={{ color: 'white' }}>tRaNsLaTiNg...</span>
                                    </Box>
                                ) : (
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <AutoAwesomeIcon sx={{ mr: 1, color: 'white' }} />
                                        <span style={{ color: 'white' }}>tRaNsLatE * ! +:)</span>
                                    </Box>
                                )}
                            </Button>
                            {!isPremium && (
                                <Button
                                    variant="text"
                                    size="small"
                                    onClick={handleGetUnlimitedClick}
                                    sx={{
                                        mt: 1,
                                        fontSize: '0.75rem',
                                        textTransform: 'none',
                                        color: 'white',
                                        '&:hover': {
                                            background: 'rgba(255, 255, 255, 0.1)',
                                        },
                                    }}
                                >
                                    Get Unlimited Translations! 🦋 *+ 🧛🏿‍♂️
                                </Button>
                            )}
                        </Box>
                    </Box>

                </Box>
                <Divider sx={{ width: '100%', mb: 2 }} />
                {/* Result area with fixed height container */}
                <Box
                    sx={{
                        width: '100%',
                        mb: 2,
                        height: '250px', // Fixed height instead of minHeight
                        position: 'relative',
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    ref={translatedTextRef}
                >
                    {isTranslating ? (
                        <Box sx={{
                            width: '100%',
                            height: '100%',
                            p: 2,
                            border: '1px solid',
                            borderColor: 'rgba(255, 255, 255, 0.1)',
                            borderRadius: '12px',
                            backgroundColor: 'rgba(255, 255, 255, 0.03)',
                            display: 'flex',
                            flexDirection: 'column',
                            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
                        }}>
                            {/* Simplified skeleton without animations */}
                            <Skeleton
                                variant="text"
                                width="30%"
                                height={28}
                                sx={{
                                    mb: 2,
                                    borderRadius: '4px',
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)'
                                }}
                            />

                            <Box sx={{ p: 1, flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                                {[90, 75, 85, 65].map((width, index) => (
                                    <Skeleton
                                        key={index}
                                        variant="text"
                                        width={`${width}%`}
                                        height={24}
                                        sx={{
                                            mb: 1,
                                            borderRadius: '4px',
                                            backgroundColor: 'rgba(255, 255, 255, 0.1)'
                                        }}
                                    />
                                ))}
                            </Box>

                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={40}
                                sx={{
                                    borderRadius: '8px',
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)'
                                }}
                            />
                        </Box>
                    ) : cartinese ? (
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                p: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                borderRadius: '12px',
                                backgroundColor: 'transparent',
                                opacity: 1, // No animation, just static display
                            }}
                        >
                            <Box sx={{
                                flex: 1,
                                mb: 2,
                                overflow: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                            }}>
                                <TextField
                                    fullWidth
                                    multiline
                                    minRows={3}
                                    placeholder="Empty"
                                    value={cartinese}
                                    onChange={(event) => setCartinese(event.target.value)}
                                    InputProps={{
                                        readOnly: true,
                                        style: { color: 'white' },
                                        sx: {
                                            fontFamily: 'monospace',
                                            fontSize: '1rem',
                                            lineHeight: 1.6,
                                            backgroundColor: 'rgba(255, 255, 255, 0.03)',
                                            borderRadius: '12px',
                                            border: '1px solid rgba(255, 255, 255, 0.1)',
                                            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
                                            padding: '16px',
                                            height: '100%',
                                        }
                                    }}
                                    sx={{
                                        backgroundColor: 'inherit',
                                        flex: 1,
                                        '& .MuiInputBase-root': {
                                            height: '100%',
                                            overflow: 'auto',
                                            borderRadius: '12px',
                                            '& fieldset': {
                                                borderColor: 'transparent',
                                            },
                                        }
                                    }}
                                />
                            </Box>

                            <Button
                                fullWidth
                                variant="outlined"
                                startIcon={<FileCopyIcon />}
                                onClick={handleCopyText}
                                sx={{
                                    borderRadius: '8px',
                                    py: 1.2,
                                    textTransform: 'none',
                                    color: 'white',
                                    borderColor: 'rgba(255, 255, 255, 0.5)',
                                    fontWeight: 500,
                                    flexShrink: 0,
                                    position: 'sticky',
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    zIndex: 1,
                                    '&:hover': {
                                        borderColor: 'white',
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    }
                                }}
                            >
                                Copy to Clipboard
                            </Button>
                        </Box>
                    ) : <Box sx={{ height: '100%', width: '100%' }} />}
                </Box>
            </Box>

            {/* Trial Promotion Slide-Up Modal - Only shown when request limit is reached */}
            <Slide direction="up" in={showTrialPromo && !isPremium} mountOnEnter unmountOnExit>
                <Paper elevation={8} sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1000,
                    p: { xs: 2, sm: 3 },
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 16,
                    boxShadow: '0 -4px 20px rgba(0, 0, 0, 0.3)',
                    background: 'linear-gradient(45deg, #9c27b0 30%, #673ab7 90%)',
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    maxWidth: '600px',
                    mx: 'auto',
                }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <Typography variant="h6" sx={{
                            fontWeight: 'bold',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <StarIcon sx={{ mr: 1 }} /> {timeLeft > 0 ? 'Special Offer' : 'Upgrade Now'}
                        </Typography>
                        <IconButton
                            onClick={closeTrialPromo}
                            size="small"
                            sx={{
                                color: 'rgba(255, 255, 255, 0.7)',
                                '&:hover': {
                                    color: 'white',
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)'
                                }
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <Box sx={{ my: 1 }}>
                        <Typography variant="body1" sx={{
                            color: 'white',
                            textAlign: 'center',
                            mb: 1,
                            fontWeight: 'medium'
                        }}>
                            {timeLeft > 0
                                ? 'Why wait? Get unlimited translations now!'
                                : 'Hit your limit? Unlock unlimited translations!'}
                        </Typography>

                        <Typography variant="body2" sx={{
                            color: 'rgba(255, 255, 255, 0.9)',
                            textAlign: 'center',
                            mb: 2
                        }}>
                            {timeLeft > 0
                                ? '7-day FREE trial • No limits • Cancel anytime'
                                : '$1.99/month • No limits • Cancel anytime'}
                        </Typography>

                        {timeLeft > 0 && (
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mb: 2
                            }}>
                                <Typography variant="subtitle1" sx={{
                                    fontWeight: 'bold',
                                    color: 'white',
                                    mr: 1
                                }}>
                                    Offer expires in:
                                </Typography>
                                <LivePromoTimer />
                            </Box>
                        )}
                    </Box>

                    <Button
                        variant="contained"
                        fullWidth
                        onClick={handleStartTrial}
                        disabled={isLoading}
                        sx={{
                            py: 1.5,
                            mb: 1,
                            borderRadius: 4,
                            fontWeight: 'bold',
                            fontSize: '1rem',
                            color: '#9c27b0',
                            backgroundColor: 'white',
                            textTransform: 'none',
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
                            },
                            '&.Mui-disabled': {
                                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                color: 'rgba(156, 39, 176, 0.7)',
                            }
                        }}
                    >
                        {isLoading ? (
                            <CircularProgress size={24} sx={{ color: '#9c27b0' }} />
                        ) : (
                            <span>{timeLeft > 0 ? 'Start FREE trial now! 🔓' : 'Unlock Premium Now! 🧛‍♂️'}</span>
                        )}
                    </Button>

                    <Typography variant="caption" sx={{
                        color: 'rgba(255, 255, 255, 0.7)',
                        textAlign: 'center',
                        fontStyle: 'italic'
                    }}>
                        {timeLeft > 0
                            ? 'Only $1.99/month after trial • No charge during trial period'
                            : 'Unlimited translations • dothatshit!'}
                    </Typography>
                </Paper>
            </Slide>

            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                closeAfterTransition
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openModal}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '90%', sm: 400 },  // Responsive width
                        bgcolor: 'background.paper',
                        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
                        maxHeight: '90vh',  // Increased max height
                        borderRadius: '16px',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden', // Add this to prevent content overflow
                    }}>
                        <Box sx={{
                            p: { xs: 2.5, sm: 3 },
                            overflowY: 'auto',
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            background: 'linear-gradient(to bottom, rgba(156, 39, 176, 0.05), rgba(103, 58, 183, 0.02))',
                            maxHeight: 'calc(90vh - 80px)', // Reserve space for button area
                        }}>
                            <Box sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
                                <BrushIcon sx={{ mr: 1.5, color: '#9c27b0' }} />
                                <Typography variant="h6" sx={{
                                    fontWeight: 'bold',
                                    background: 'linear-gradient(45deg, #9c27b0, #673ab7)',
                                    backgroundClip: 'text',
                                    WebkitBackgroundClip: 'text',
                                    color: 'transparent',
                                }}>
                                    Translation Style
                                </Typography>
                            </Box>

                            {defaultConfig && defaultConfig.map((option: any) => (
                                <Box
                                    key={option.name}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                        backgroundColor: 'rgba(156, 39, 176, 0.05)',
                                        borderRadius: '12px',
                                        padding: { xs: 1.5, sm: 2 },
                                        mb: 2,
                                        transition: 'all 0.2s ease',
                                        '&:hover': {
                                            backgroundColor: 'rgba(156, 39, 176, 0.08)',
                                        },
                                        border: '1px solid rgba(156, 39, 176, 0.15)',
                                    }}
                                >
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        mb: 1
                                    }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography variant='body1' sx={{ fontWeight: 500 }}>
                                                {option.display_name}
                                            </Typography>
                                            <IconButton
                                                size="small"
                                                aria-label="info"
                                                onClick={(event) => handlePopoverOpen(event, option.name)}
                                                sx={{
                                                    ml: 0.5,
                                                    color: 'text.secondary',
                                                    '&:hover': { color: '#9c27b0' }
                                                }}
                                            >
                                                <InfoOutlinedIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                        {typeof option.default === 'boolean' && (
                                            <Switch
                                                checked={config?.[option.name] ?? option.default}
                                                onChange={(event) => {
                                                    if (true || isPremium) {
                                                        setConfig(prevConfig => ({
                                                            ...prevConfig,
                                                            [option.name]: event.target.checked
                                                        }));
                                                    } else {
                                                        handlePremiumClick();
                                                    }
                                                }}
                                                color='primary'
                                            />
                                        )}
                                    </Box>

                                    {typeof option.default !== 'boolean' && (
                                        <Box sx={{ px: 1 }}>
                                            <Slider
                                                value={config?.[option.name] ?? option.default}
                                                onChange={(_, newValue) => {
                                                    if (true || isPremium) {
                                                        setConfig(prevConfig => ({
                                                            ...prevConfig,
                                                            [option.name]: newValue as number
                                                        }));
                                                    } else {
                                                        handlePremiumClick();
                                                    }
                                                }}
                                                min={0}
                                                max={100}
                                                step={null}
                                                marks={[
                                                    { value: 0, label: 'Off' },
                                                    { value: 33, label: 'Rare' },
                                                    { value: 66, label: 'Often' },
                                                    { value: 100, label: 'Max' }
                                                ]}
                                                valueLabelDisplay='off'
                                                sx={{
                                                    width: '100%',
                                                    color: '#9c27b0',
                                                    '& .MuiSlider-markLabel': {
                                                        color: 'text.secondary',
                                                    },
                                                    '& .MuiSlider-mark': {
                                                        backgroundColor: 'rgba(156, 39, 176, 0.3)',
                                                    },
                                                }}
                                            />
                                        </Box>
                                    )}

                                    {typeof option.default === 'boolean' && (
                                        <Typography variant="caption" sx={{ display: 'block', mt: 0.5, color: 'text.secondary' }}>
                                            {option.help}
                                        </Typography>
                                    )}

                                    <Popover
                                        id={option.name}
                                        open={openPopoverId === option.name}
                                        anchorEl={anchorEl}
                                        onClose={handlePopoverClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        sx={{
                                            '& .MuiPopover-paper': {
                                                borderRadius: '8px',
                                                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.25)',
                                                border: '1px solid rgba(255, 255, 255, 0.1)',
                                            }
                                        }}
                                    >
                                        <Typography sx={{ p: 2, maxWidth: 250 }}>{option.help}</Typography>
                                    </Popover>
                                </Box>
                            ))}
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            p: { xs: 2.5, sm: 3 },
                            pt: 2,
                            borderTop: '1px solid rgba(156, 39, 176, 0.2)',
                            backgroundColor: 'rgba(156, 39, 176, 0.03)',
                            position: 'sticky',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            zIndex: 1,
                            borderBottomLeftRadius: '16px',
                            borderBottomRightRadius: '16px',
                            boxShadow: '0 -4px 12px rgba(0, 0, 0, 0.05)',
                            minHeight: '70px', // Ensure minimum height for button area
                            flexShrink: 0, // Prevent this box from shrinking
                        }}>
                            <Button
                                onClick={() => {
                                    setConfig({});
                                    setOpenModal(false);
                                }}
                                variant="outlined"
                                sx={{
                                    textTransform: 'none',
                                    borderRadius: '8px',
                                    borderColor: 'rgba(156, 39, 176, 0.5)',
                                    color: '#9c27b0',
                                    '&:hover': {
                                        borderColor: '#9c27b0',
                                        backgroundColor: 'rgba(156, 39, 176, 0.05)',
                                        transform: 'translateY(-2px)',
                                    },
                                    '&:active': {
                                        transform: 'translateY(1px)',
                                    }
                                }}
                                startIcon={<RestartAltIcon />}
                            >
                                Reset
                            </Button>
                            <Button
                                onClick={() => setOpenModal(false)}
                                variant="contained"
                                sx={{
                                    textTransform: 'none',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                                    background: 'linear-gradient(45deg, #9c27b0 30%, #673ab7 90%)',
                                    '&:hover': {
                                        boxShadow: '0 6px 16px rgba(0, 0, 0, 0.2)',
                                        transform: 'translateY(-2px)',
                                    },
                                    '&:active': {
                                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                                        transform: 'translateY(1px)',
                                    }
                                }}
                            >
                                Apply Changes
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Container>
    );
};

export default Cartinese;