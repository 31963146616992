import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import constants from '../../constants/Constants';


const TermsAndConditions: React.FC = () => {
    return (
        <Container maxWidth="md">
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Terms and Conditions of Use
                </Typography>
                <Typography variant="body1" paragraph>
                    Last updated: September 24, 2024
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    1. Introduction
                </Typography>
                <Typography variant="body1" paragraph>
                    Welcome to saarim.app ("we," "our," or "us"). These Terms and Conditions of Use ("Terms") govern your access to and use of our website, including any content, functionality, and services offered on or through saarim.app (the "Website").
                </Typography>
                <Typography variant="body1" paragraph>
                    By accessing or using the Website, you agree to be bound by these Terms. If you do not agree to these Terms, you must not access or use the Website.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    2. Services
                </Typography>
                <Typography variant="body1" paragraph>
                    saarim.app provides software tools that users can access and use freely. Some features of these tools may require payment to unlock. We reserve the right to modify, suspend, or discontinue any part of our services at any time without notice.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    3. User Accounts
                </Typography>
                <Typography variant="body1" paragraph>
                    To access certain features of the Website, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
                </Typography>
                <Typography variant="body1" paragraph>
                    You are responsible for safeguarding the password that you use to access the Website and for any activities or actions under your password. You agree not to disclose your password to any third party.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    4. Privacy Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    Your use of the Website is also governed by our Privacy Policy, which is incorporated into these Terms by reference. Please review our Privacy Policy to understand our practices regarding the collection and use of your personal information.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    5. Intellectual Property Rights
                </Typography>
                <Typography variant="body1" paragraph>
                    The Website and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by saarim.app, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    6. User-Generated Content
                </Typography>
                <Typography variant="body1" paragraph>
                    The Website may allow users to post, submit, publish, display, or transmit content. By providing any user-generated content on the Website, you grant us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns the right to use, reproduce, modify, perform, display, distribute, and otherwise disclose to third parties any such material.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    7. Prohibited Uses
                </Typography>
                <Typography variant="body1" paragraph>
                    You may use the Website only for lawful purposes and in accordance with these Terms. You agree not to use the Website in any way that violates any applicable federal, state, local, or international law or regulation.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    8. AI-Generated Content Disclaimer
                </Typography>
                <Typography variant="body1" paragraph>
                    Certain parts of our products may involve AI-generated content. While we strive for accuracy, such content may not always be completely accurate or reliable. Users are advised to use this information with discretion and not to rely on it as the sole basis for making decisions.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    9. Third-Party Links
                </Typography>
                <Typography variant="body1" paragraph>
                    The Website may contain links to third-party websites or resources. We provide these links only as a convenience and are not responsible for the content, products, or services on or available from those websites or resources.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    10. Termination
                </Typography>
                <Typography variant="body1" paragraph>
                    We reserve the right to terminate or suspend your account and bar access to the Website immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    11. Disclaimer of Warranties
                </Typography>
                <Typography variant="body1" paragraph>
                    The Website is provided on an "AS IS" and "AS AVAILABLE" basis, without any warranties of any kind, either express or implied. Neither saarim.app nor any person associated with saarim.app makes any warranty or representation with respect to the completeness, security, reliability, quality, accuracy, or availability of the Website.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    12. Limitation of Liability
                </Typography>
                <Typography variant="body1" paragraph>
                    In no event will saarim.app, its affiliates, or their licensors, service providers, employees, agents, officers, or directors be liable for damages of any kind, under any legal theory, arising out of or in connection with your use, or inability to use, the Website, any websites linked to it, any content on the Website or such other websites, including any direct, indirect, special, incidental, consequential, or punitive damages.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    13. Governing Law
                </Typography>
                <Typography variant="body1" paragraph>
                    These Terms and your use of the Website will be governed by and construed in accordance with the laws of the State of California, without giving effect to any choice or conflict of law provision or rule.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    14. Changes to the Terms
                </Typography>
                <Typography variant="body1" paragraph>
                    We may revise and update these Terms from time to time in our sole discretion. All changes are effective immediately when we post them on this page. Your continued use of the Website following the posting of revised Terms means that you accept and agree to the changes.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    15. Contact Us
                </Typography>
                <Typography variant="body1" paragraph>
                    For any questions about these Terms or to report any issues with the Website, please contact our support team at {constants.supportEmail}.
                </Typography>

                <Typography variant="body1" paragraph>
                    By using this Website, you acknowledge that you have read and understood these Terms and agree to be bound by them.
                </Typography>
            </Box>
        </Container>
    );
};

export default TermsAndConditions;