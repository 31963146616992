import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams } from "react-router-dom";
import Client_ from '../../api/Client';
import { useNotification } from '../../stores/actions/StoreActions';
import ReactMarkdown from 'react-markdown';
interface ChatMessage {
    role: string;
    text: string;
    timestamp: string;
}

const starterQuestions = [
    'What is your most impressive accomplishment?',
    'What makes you different from other engineers?',
    'Can you tell me a bit about yourself?',
    'Can you summarize your career for me?',
    'Why are you uniquely qualified to join my company?',
]

const SaarimChat: React.FC = () => {
    const [message, setMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([{}]);
    const [isLoading, setIsLoading] = useState(false);
    const { addNotificationError, addNotificationSuccess } = useNotification();
    const [searchParams, setSearchParams] = useSearchParams();
    const messagesEndRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.title = `Saarim Chat`;
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [chatHistory, isLoading]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const sendChatMessage = async (query: any) => {
        setIsLoading(true);

        if (query.trim() === '') {
            setIsLoading(false);
            addNotificationError('Please enter a message');
            return;
        }

        const chatHistoryCopy = [...chatHistory];
        setMessage('');

        chatHistory.push({
            role: 'user',
            text: query,
            timestamp: new Date().toISOString(),
        })

        if (chatHistory.length === 2 && Object.keys(chatHistory[0]).length === 0) {
            chatHistory.shift();
        };

        Client_.client_().askSaarim(query, chatHistoryCopy).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setChatHistory(res?.result?.chat_history)
            }
        });
    };

    const handleButtonClick = (text: any) => {
        setMessage(text);
        sendChatMessage(text);
    };

    // Helper function to determine if a message is the first in a sequence from the same sender
    const isFirstInSequence = (index: number, role: string) => {
        if (index === 0) return true;
        const prevMessage = chatHistory[index - 1] as ChatMessage;
        return prevMessage.role !== role;
    };

    // Helper function to determine if a message is the last in a sequence from the same sender
    const isLastInSequence = (index: number, role: string) => {
        if (index === chatHistory.length - 1) return true;
        const nextMessage = chatHistory[index + 1] as ChatMessage;
        return nextMessage.role !== role;
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            backgroundColor: '#000000',
            color: 'white',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'
        }}>
            <Typography
                variant="h5"
                sx={{
                    textAlign: 'center',
                    padding: '15px 0',
                    color: 'white',
                    fontWeight: 'bold',
                    borderBottom: '1px solid #1C1C1E',
                    backgroundColor: '#1C1C1E',
                    position: 'sticky',
                    top: 0,
                    zIndex: 10
                }}
            >
                SaarimGPT
            </Typography>

            <div style={{
                flex: 1,
                overflowY: 'auto',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                backgroundImage: 'linear-gradient(to bottom, #0c0c0c, #121212)',
                backgroundAttachment: 'fixed'
            }}>
                {chatHistory && Object.keys(chatHistory[0]).length === 0 && (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '12px',
                        marginTop: '40px',
                        marginBottom: '20px'
                    }}>
                        <Typography variant="h6" style={{
                            marginBottom: '15px',
                            color: 'white',
                            fontWeight: 500,
                            textAlign: 'center'
                        }}>
                            Start the conversation with a question:
                        </Typography>
                        {starterQuestions.map((question, index) => (
                            <Button
                                onClick={() => handleButtonClick(question)}
                                variant="outlined"
                                key={index}
                                style={{
                                    width: '100%',
                                    maxWidth: '500px',
                                    padding: '10px 15px',
                                    borderRadius: '18px',
                                    textTransform: 'none',
                                    color: '#ffffff',
                                    borderColor: '#2C2C2E',
                                    backgroundColor: '#1C1C1E',
                                    transition: 'all 0.2s ease',
                                    marginBottom: '5px',
                                    textAlign: 'left',
                                    justifyContent: 'flex-start'
                                }}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: '#2C2C2E',
                                        borderColor: '#3C3C3E',
                                    }
                                }}
                            >
                                {question}
                            </Button>
                        ))}
                    </div>
                )}

                {chatHistory && Object.keys(chatHistory[0]).length > 0 && chatHistory.map((chatMessage: any, index: number) => {
                    const isUser = chatMessage.role === 'user';
                    const isFirst = isFirstInSequence(index, chatMessage.role);
                    const isLast = isLastInSequence(index, chatMessage.role);

                    return (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                justifyContent: isUser ? 'flex-end' : 'flex-start',
                                mb: isLast ? 2 : 0.5,
                                mt: isFirst ? 1 : 0
                            }}
                        >
                            <Box
                                sx={{
                                    maxWidth: '75%',
                                    backgroundColor: isUser ? '#007AFF' : '#1C1C1E',
                                    color: 'white',
                                    borderRadius: isUser
                                        ? isFirst && isLast ? '18px'
                                        : isFirst ? '18px 18px 5px 18px'
                                        : isLast ? '5px 18px 18px 5px'
                                        : '5px 18px 5px 18px'
                                        : isFirst && isLast ? '18px'
                                        : isFirst ? '18px 18px 18px 5px'
                                        : isLast ? '5px 18px 18px 18px'
                                        : '5px 18px 18px 5px',
                                    padding: '10px 14px',
                                    boxShadow: '0px 1px 2px rgba(0,0,0,0.1)',
                                    wordBreak: 'break-word'
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        margin: 0,
                                        fontSize: '15px',
                                        lineHeight: 1.4,
                                        '& p': { margin: '8px 0' },
                                        '& p:first-of-type': { marginTop: 0 },
                                        '& p:last-of-type': { marginBottom: 0 },
                                        '& code': {
                                            backgroundColor: isUser ? 'rgba(0,0,0,0.2)' : 'rgba(255,255,255,0.1)',
                                            padding: '2px 4px',
                                            borderRadius: '4px',
                                            fontFamily: 'monospace'
                                        },
                                        '& pre': {
                                            backgroundColor: isUser ? 'rgba(0,0,0,0.2)' : 'rgba(255,255,255,0.1)',
                                            padding: '8px',
                                            borderRadius: '8px',
                                            overflowX: 'auto'
                                        }
                                    }}
                                >
                                    <ReactMarkdown>{chatMessage.text}</ReactMarkdown>
                                </Typography>
                            </Box>
                        </Box>
                    );
                })}
                {isLoading && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            mb: 2,
                            mt: 1
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: '#1C1C1E',
                                color: 'white',
                                borderRadius: '18px',
                                padding: '12px 16px',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '6px'
                            }}
                        >
                            <div className="typing-indicator">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Box>
                    </Box>
                )}
                <div ref={messagesEndRef} />
            </div>

            <div style={{
                position: 'sticky',
                bottom: 0,
                left: 0,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '15px 20px',
                backgroundColor: '#1C1C1E',
                boxShadow: '0px -1px 5px rgba(0,0,0,0.2)',
                borderTop: '1px solid #2C2C2E'
            }}>
                <Tooltip title="Clear chat">
                    <IconButton
                        onClick={() => setChatHistory([{}])}
                        style={{
                            marginRight: '10px',
                            color: 'white',
                            backgroundColor: 'rgba(255,255,255,0.05)',
                            padding: '8px'
                        }}
                        sx={{
                            '&:hover': {
                                backgroundColor: 'rgba(255,255,255,0.1)',
                            }
                        }}
                    >
                        <ClearIcon fontSize="small" />
                    </IconButton>
                </Tooltip>

                <TextField
                    style={{
                        width: '100%',
                        marginRight: '10px',
                        backgroundColor: '#2C2C2E',
                        borderRadius: '20px',
                    }}
                    InputProps={{
                        style: {
                            color: 'white',
                            borderRadius: '20px',
                            padding: '10px 15px',
                            fontSize: '15px'
                        }
                    }}
                    id="outlined-basic"
                    placeholder="Message..."
                    variant="outlined"
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter' && !event.shiftKey) {
                            event.preventDefault();
                            sendChatMessage(message);
                        }
                    }}
                    multiline
                    maxRows={4}
                />

                <IconButton
                    style={{
                        backgroundColor: message.trim() ? '#007AFF' : '#2C2C2E',
                        borderRadius: '50%',
                        padding: '12px',
                        transition: 'all 0.2s ease'
                    }}
                    onClick={() => sendChatMessage(message)}
                    disabled={!message.trim() || isLoading}
                >
                    {isLoading ? (
                        <CircularProgress size={20} style={{ color: 'white' }} />
                    ) : (
                        <SendIcon style={{ color: 'white', fontSize: '20px' }} />
                    )}
                </IconButton>
            </div>
            <style>
                {`
                @keyframes pulse {
                    0% {
                        opacity: 1;
                    }
                    50% {
                        opacity: 0.5;
                    }
                    100% {
                        opacity: 1;
                    }
                }

                .typing-indicator {
                    display: flex;
                    align-items: center;
                }

                .typing-indicator span {
                    height: 8px;
                    width: 8px;
                    margin: 0 2px;
                    background-color: #606060;
                    border-radius: 50%;
                    display: inline-block;
                    animation: typing 1.4s infinite ease-in-out both;
                }

                .typing-indicator span:nth-child(1) {
                    animation-delay: 0s;
                }

                .typing-indicator span:nth-child(2) {
                    animation-delay: 0.2s;
                }

                .typing-indicator span:nth-child(3) {
                    animation-delay: 0.4s;
                }

                @keyframes typing {
                    0% {
                        transform: scale(1);
                        opacity: 0.7;
                    }
                    50% {
                        transform: scale(1.3);
                        opacity: 1;
                    }
                    100% {
                        transform: scale(1);
                        opacity: 0.7;
                    }
                }

                /* Custom scrollbar for the chat area */
                div::-webkit-scrollbar {
                    width: 8px;
                }

                div::-webkit-scrollbar-track {
                    background: transparent;
                }

                div::-webkit-scrollbar-thumb {
                    background-color: rgba(255, 255, 255, 0.2);
                    border-radius: 20px;
                }

                div::-webkit-scrollbar-thumb:hover {
                    background-color: rgba(255, 255, 255, 0.3);
                }
                `}
            </style>
        </div>
    );
};

export default SaarimChat;
